import React, { useState, useRef, useEffect, useReducer  } from 'react';

import { db, auth } from '../config/Firebase'
import { getDocs, updateDoc, collection, doc, query, where, getDoc } from 'firebase/firestore'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import '../css/Calendar.css';
import '../css/TrainerCalendar.css';
import { daysOfWeekHebrew, monthsHebrew } from '../Constext/MonthNDays';
import { Loading } from './Loading'

import arrowDown from '../imgs/arrowDown.png';
import GoogleCalendar from '../imgs/GoogleCalendar.png'
import { Requests, ApprovedRequests, DailySessions, MonthlyView } from './TrainerCalanderViews';
import Tooltip from './Tooltip';
import ToggleSwitch from './ToggleSwitch';


function reducer(state, action) {
  switch (action.type) {
    case 'SWIPE_START':
      return { ...state, initialTouchX: action.payload };
    case 'SWIPE_MOVE':
      const diffX = state.initialTouchX - action.payload;
      let direction = null;
      if (Math.abs(diffX) > 50) { // 50 is your threshold
        direction = diffX > 0 ? 'left' : 'right';
      }
      return { ...state, swipeDirection: direction };
    case 'SWIPE_END':
      return initialState; // reset to initial state on end
    default:
      throw new Error();
  }
}

const initialState = {
  initialTouchX: 0,
  swipeDirection: null,
};

export const TrainerCalendarUpdates = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(0);
  const calendarRef = useRef(null);
  const [displayFixedHours, setDisplayFixedHours] = useState(false);
  const [displaySpecialChanges, setDisplaySpecialChanges] = useState(true);
  const [selectedButton, setSelectedButton] = useState('calendar'); // Initially set to 'fixedHours'
  const [loading, setLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const isSavedTimeout = useRef(null);

  //goog calendar
  const [isActive, setIsActive] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const user = auth?.currentUser;

  //requests
const [upcomingSessions, setUpcomingSessions] = useState([]);
const [pendingSessions, setPendingSessions] = useState([]);
const [declinedSessions, setDeclinedSessions] = useState([]);
const [canceledSessions, setCanceledSessions] = useState([]);
const [changedSessions, setChangedSessions] = useState([]);
const [fetchTrigger, setFetchTrigger] = useState(false);
//calander
const [selectedSessions, setSelectedSessions] = useState([]);
  // Add state variables
const [weekData, setWeekData] = useState(true);
const [monthData, setMonthData] = useState(null);

// Update the button click handlers
const toggleWeekView = () => {
  setMonthData(false)
  setWeekData(true)
};

const refetchData = () => {
  setFetchTrigger(prevState => !prevState);
};

useEffect(() => {
  const fetchTrainerToken = async () => {
    try {
      const auth = getAuth();
      const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      let trainerDocId;

      querySnapshot.forEach((doc) => {
        trainerDocId = doc.id;
      });

      const settingsRef = collection(db, 'Trainers', trainerDocId, 'settings');
      const docRef = doc(settingsRef, 'trainerSettings');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setAccessToken(data.accessToken);
        setIsActive(true); // Set isActive to true if the token was fetched successfully
      }
    } catch (error) {
      console.error('Error fetching trainer token:', error);
      setIsActive(false); // Set isActive to false if there was an error
    }
  };

  fetchTrainerToken();
}, []);

useEffect(() => {
  handleSelectDate(selectedDate);
}, [fetchTrigger, selectedDate]);

const toggleMonthView = () => {
  setWeekData(false)
  setMonthData(true)
};

  const [state, dispatch] = useReducer(reducer, initialState);

  const [parentDivs, setParentDivs] = useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday:[],
    Thursday:[],
    Friday:[],
    Saturday:[],
  });

  useEffect(() => {
    const fetchSessionsByTrainer = async () => {
      const q = query(
        collection(db, 'sessions'),
        where('trainer.id', '==', auth.currentUser.uid)
      );
  
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const trainerSessions = querySnapshot.docs.map(doc => doc.data());
        const upcomingSessions = trainerSessions.filter(session => session.sessionStatus === 'Upcoming' || session.sessionStatus === 'Done'); // Include both 'Upcoming' and 'Done' statuses
        const pendingSessions = trainerSessions.filter(session => session.sessionStatus === 'Pending');
        const declinedSessions = trainerSessions.filter(session => session.sessionStatus === 'Declined');
        const canceledSessions = trainerSessions.filter(session => session.sessionStatus === 'Canceled');
        const changedSessions = trainerSessions.filter(session => session.sessionStatus === 'Changed');

        setUpcomingSessions(upcomingSessions);
        setPendingSessions(pendingSessions);
        setDeclinedSessions(declinedSessions);
        setCanceledSessions(canceledSessions);
        setChangedSessions(changedSessions);
      } else {
        // Reset to default state if no data
        setUpcomingSessions([]);
        setPendingSessions([]);
        setDeclinedSessions([]);
        setCanceledSessions([]);
        setChangedSessions([]);
      }
  
      setLoading(false); // Moved outside the condition
    };
  
    fetchSessionsByTrainer();
  }, [auth.currentUser.uid, fetchTrigger]);
  
  const handleChangeMonth = (event) => {
    const month = event.target.value;
    const newDate = new Date(selectedDate.getFullYear(), month, 1);
    setSelectedDate(newDate);
  };

  const handleSwipeStart = (event) => {
    dispatch({ type: 'SWIPE_START', payload: event.touches[0].clientX });
  };

  const handleSwipeMove = (event) => {
    dispatch({ type: 'SWIPE_MOVE', payload: event.touches[0].clientX });
  };

  const handleSwipeEnd = () => {
    if (state.swipeDirection === 'right') {
      const newDate = new Date(selectedDate);
      newDate.setDate(selectedDate.getDate() + 7);
      setSelectedDate(newDate);
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.add('swipe-right'); // Add class to the week-dates div
      }
    } else if (state.swipeDirection === 'left') {
      const newDate = new Date(selectedDate);
      newDate.setDate(selectedDate.getDate() - 7);
      setSelectedDate(newDate);
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.add('swipe-left'); // Add cladatehass to the week-dates div
      }
    }

    // Clear the added class after the transition duration
    setTimeout(() => {
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.remove('swipe-right', 'swipe-left');
      }
    }, 300);
    dispatch({ type: 'SWIPE_END' });
  };

  const handleSelectDate = async (date) => {
    setSelectedDate(date);
    
    // Get sessions for selected date
    const dateISO = date.toISOString().split('T')[0];  // Extract the YYYY-MM-DD part of the date
    const q = query(
      collection(db, 'sessions'),
      where('trainer.id', '==', auth.currentUser.uid),
      where('sessionDate', '==', dateISO),  // Assuming sessions have a 'date' field in YYYY-MM-DD format
      where('sessionStatus', 'in', ['Upcoming', 'Pending', 'Done'])
    );
  
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const selectedSessions = querySnapshot.docs.map(doc => doc.data());
      setSelectedSessions(selectedSessions);
    }else{
      setSelectedSessions([]);
    }
  };
  
  const renderWeekDates = () => {
    const startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - selectedDate.getDay());

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      const isSelected = date.toDateString() === selectedDate.toDateString();

      dates.push(
        <div
          key={i}
          className={`date ${isSelected ? 'selected' : ''}`}
          onClick={() => handleSelectDate(date)}
        >
          <div className="day-name">{daysOfWeekHebrew[date.getDay()]}</div>
          <div className="day-date">{date.getDate()}</div>
        </div>
      );
    }

    return dates;
  };
  
  const toggleFixedHours = () => {
    setDisplayFixedHours(true);
    setDisplaySpecialChanges(false);
    setSelectedButton('requests')
  };

  const toggleSpecialChanges = () => {
    setDisplayFixedHours(false);
    setDisplaySpecialChanges(true);
    setSelectedButton('calendar')
  };
  
  if (loading) {
    // You can replace this with a loading spinner or some other placeholder
    return <Loading/>
  }

//google calander
const handleSyncWithGoogleChange = () => {
  const newIsActiveValue = !isActive;
  setIsActive(newIsActiveValue);

  if (newIsActiveValue) {
    // If not currently syncing, request permission and start sync
    requestCalendarPermission().then(() => {
      syncSessionsWithGoogleCalendar(accessToken);
    }).catch(error => {
      console.error("An error occurred:", error);
    });
  } else {
  }
};

  const requestCalendarPermission = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/calendar');
      provider.setCustomParameters({ prompt: 'select_account' });
      const result = await signInWithPopup(auth, provider);
  
      // Extract the OAuth access token
      const oauthCredential = GoogleAuthProvider.credentialFromResult(result);
      const accessToken = oauthCredential?.accessToken;
  
      if (!accessToken) {
        console.error('No access token found');
        return;
      }
      
         // Get the trainer document ID
    const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    let trainerDocId;

    querySnapshot.forEach((doc) => {
      trainerDocId = doc.id;
    });

    // Save the access token in the trainerSettings
    const settingsRef = collection(db, 'Trainers', trainerDocId, 'settings');
    const docRef = doc(settingsRef, 'trainerSettings');

    await updateDoc(docRef, { accessToken });

    console.log('Token saved.');

    setAccessToken(accessToken);
    await fetchCalendarData(accessToken); // This is the function to fetch calendar data
    await syncSessionsWithGoogleCalendar(accessToken);

  } catch (error) {
    console.error('Error requesting calendar permission:', error);
  }
};
 
  const fetchCalendarData = async (token) => {
    try {
      const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
    } catch (error) {
      console.error('Error fetching calendar data:', error);
    }
  };
  
  const updateSessionWithGoogleEventId = async (sessionId, googleCalendarEventId) => {
    try {
      const sessionRef = doc(db, 'sessions', sessionId);
      await updateDoc(sessionRef, { googleCalendarEventId });
    } catch (error) {
      console.error('Error updating session with Google Event ID:', error);
    }
  };

  const combineDateWithTime = (date, time) => {
    const [hours, minutes] = time.split(':');
    const combinedDate = new Date(date);
    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);
    combinedDate.setSeconds(0);
    return combinedDate;
  };
  const isWithinRange = (date) => {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    const fifteenDaysAhead = new Date();
    fifteenDaysAhead.setDate(fifteenDaysAhead.getDate() + 15);
  
    return date >= twoDaysAgo && date <= fifteenDaysAhead;
  };
  
  const addEventToGoogleCalendar = async (token, session) => {
  const startDate = combineDateWithTime(session.sessionDate, session.startTime);
  const endDate = combineDateWithTime(session.sessionDate, session.endTime);
  
    const event = {
      summary: `אימון עם ${session.trainee.fName} ${session.trainee.lName}`,
      description: 'אימון שנקבע בעזרת Fitit 💪', // You can add more details or customize this
      start: {
        dateTime: startDate.toISOString(),
        timeZone: 'UTC', // Adjust according to your requirement
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: 'UTC', // Adjust according to your requirement
      },
    };
  
    try {
    const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    });

    if (!response.ok) {
      throw new Error('Failed to add event to Google Calendar');
    }

    const data = await response.json();
    await updateSessionWithGoogleEventId(session.id, data.id); // Update Firestore document with Google Event ID
    return data.id;
  } catch (error) {
    console.error('Error adding event to Google Calendar:', error);
  }
};
  
  const updateEventInGoogleCalendar = async (token, eventId, session) => {
    const startDate = combineDateWithTime(session.sessionDate, session.startTime);
    const endDate = combineDateWithTime(session.sessionDate, session.endTime);
  
    const event = {
      summary: `אימון עם ${session.trainee.fName} ${session.trainee.lName}`,
      description: 'אימון שנקבע בעזרת Fitit 💪', // You can add more details or customize this
      start: {
        dateTime: startDate.toISOString(),
        timeZone: 'UTC', // Adjust according to your requirement
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: 'UTC', // Adjust according to your requirement
      },
    };

    try {
      const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update event in Google Calendar');
      }
  
      const data = await response.json();
  
    } catch (error) {
      console.error('Error updating event in Google Calendar:', error);
    }
  };

  const deleteEventFromGoogleCalendar = async (token, eventId) => {
    try {
      const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 410) {
        // Event not found in Google Calendar. It might have already been deleted.
        console.log(`Event with ID ${eventId} not found in Google Calendar. It might have already been deleted.`);
        // Handle the case where the event was not found (e.g., remove it from local state if applicable)
        // For example, if you are using React state, you can remove it from the state like this:
        // setEvents(events.filter((event) => event.googleCalendarEventId !== eventId));
      } else if (!response.ok) {
        // Other error occurred while trying to delete the event
        const data = await response.json();
        console.error('Failed to delete event from Google Calendar:', data);
        throw new Error('Failed to delete event from Google Calendar');
      } else {
        // Event deleted successfully
        console.log(`Event with ID ${eventId} deleted from Google Calendar`);
        // Handle the successful deletion (e.g., remove it from local state if applicable)
        // For example, if you are using React state, you can remove it from the state like this:
        // setEvents(events.filter((event) => event.googleCalendarEventId !== eventId));
      }
    } catch (error) {
      console.error('Error deleting event from Google Calendar:', error);
    }
  };
  
  const syncSessionsWithGoogleCalendar = async (token) => {
    const allSessions = [
      ...upcomingSessions,
      ...pendingSessions,
      ...declinedSessions,
      ...canceledSessions,
      ...changedSessions,
    ];
  
    for (const session of allSessions) {
      const sessionDate = new Date(session.sessionDate);
  
      // Skip the session if it's not within the desired range
      if (!isWithinRange(sessionDate)) continue;
  
      if (session.googleCalendarEventId) {
        if (upcomingSessions.includes(session)) {
          await updateEventInGoogleCalendar(token, session.googleCalendarEventId, session);
        } else {
          await deleteEventFromGoogleCalendar(token, session.googleCalendarEventId);
          // Remove Google Event ID from the session, as it's no longer needed
          session.googleCalendarEventId = null;
        }
      } else if (upcomingSessions.includes(session)) {
        const eventId = await addEventToGoogleCalendar(token, session);
        session.googleCalendarEventId = eventId; // Save the event ID with the session information
      }
    }
  };
  
  const toggleSyncWithGoogle = () =>{
    syncSessionsWithGoogleCalendar(accessToken);
  }

  return (
    <div
      className="calendar p_web"
      style={{ paddingTop: '5px', height: 'fit-content',
      marginBottom: '20px'}}
      lang="he"
      dir="rtl"
      ref={calendarRef}
      onTouchStart={handleSwipeStart}
      onTouchMove={handleSwipeMove}
      onTouchEnd={handleSwipeEnd}
    >
            
      <div className='btns_top'>
        <button
          onClick={toggleSpecialChanges}
          className={selectedButton === 'calendar' ? 'btn_top selected' : 'btn_top'}
        >
          לוח שנה
        </button>
      <button onClick={toggleFixedHours} className={selectedButton === 'requests' ? 'btn_top selected' : 'btn_top'}>
          בקשות לאימונים
        </button>
      </div>
      
      
      {
  displayFixedHours && (
    <Tooltip 
      message="כאן מופיעות כל הבקשות שקיבלתם, אפשר לראות גם בקשות שבוטלו וניתן גם לערוך בקשות קיימות" 
      />
  )
}

{
  displaySpecialChanges && (
    <Tooltip 
      message=" בלחיצה על עדכון בגוגל - כל האימונים שאושרו יועברו ללוח השנה שלכם בגוגל,
       יש לבצע את העדכון בכל פעם שרוצים לעדכן את לוח השנה" 
      />
  )
}

        {displayFixedHours && (
            <div style={{ display: 'contents'}}>
              <h3>בקשות ממתינות</h3>
              <Requests 
                 requests={pendingSessions}
                 refetchData={refetchData} 
              />
              <h3 style={{marginBottom:'0'}}>בקשות שקיבלתי</h3>
              <ApprovedRequests
               requests={upcomingSessions}
               declined={declinedSessions}
               canceled={canceledSessions}
               changed={changedSessions}
               refetchData={refetchData}/>
            </div>
        )}

        {displaySpecialChanges && (

            <div>
        
                <div className="header">
                  <div className="select-wrapper">
                    <select value={selectedDate.getMonth()} onChange={handleChangeMonth}>
                      {Array.from({ length: 12 }, (_, month) => (
                        <option key={month} value={month}>
                          {monthsHebrew[month]}
                        </option>
                      ))}
                    </select>
                    <img src={arrowDown} alt="arrow" />
                  </div>
                </div>
                    <div className='view_btns'>
                    <button 
                      onClick={toggleWeekView} 
                      className={`week_month_view ${weekData ? 'active-view' : ''}`}
                    >
                      שבוע
                    </button>

                    <button 
                      onClick={toggleMonthView} 
                      className={`week_month_view ${monthData ? 'active-view' : ''}`}
                    >
                      חודש
                    </button>

                    {isActive && 
                      <button onClick={toggleSyncWithGoogle} className='week_month_view google_calendar'>
                      עדכון בגוגל
                      </button>}

                      <div className='goog_switch_container' style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius:'5px' }}>
                        <img src={GoogleCalendar} alt='google calendar' />
                        <ToggleSwitch checked={isActive} onToggleChange={handleSyncWithGoogleChange} isActive={isActive}/>
                      </div>

                    </div>

                {weekData &&
                  <div style={{ display: 'contents'}}>
                    <div className="week-dates" ref={calendarRef}>
                      {renderWeekDates()}
                    </div>
                    {selectedDate && (
                            <div>
                                {selectedSessions.length === 0 ? (
                                    <div style={{marginTop:'50px'}}>
                                       לא קבעת אימונים היום 📅
                                    </div>
                                ) : (
                                    <DailySessions
                                    selectedSessions={selectedSessions}
                                    switchToRequests={toggleFixedHours}
                                    refetchData={refetchData} 
                                    />
                                )}
                            </div>
                        )}
              </div>}

             {monthData &&
              <div>
                <MonthlyView/>
              </div>
               }
            </div>
        )}
</div>
  );
};
