import { useState, useContext } from 'react';
import { auth, googleProvider } from '../config/Firebase'
import {createUserWithEmailAndPassword, 
      signInWithEmailAndPassword, 
      signInWithPopup, 
     } from 'firebase/auth'
import { NavLink, useNavigate } from 'react-router-dom';
import {AuthContext} from '../Constext/AuthContext'

import '../css/SignIn.css';
import googlogo from '../imgs/Googlelogo.png'

export const SignInTrainee = () => {
  const[email, setEmail] = useState('')
  const[password, setPassword] = useState('')
  const[error, setError] = useState(false)

  const Navigate = useNavigate()

  const { dispatch, setUserRole } = useContext(AuthContext); // Add setUserRole from AuthContext

const queryParams = new URLSearchParams(window.location.search);
const redirectUrl = queryParams.get('redirect');

  const signUp = async () => {
    try{
  const userCredentials =await createUserWithEmailAndPassword(auth, email, password)
  console.log('signed up!')
  setError(false)
  Navigate('/')
  const user = userCredentials.user;
  dispatch({type:'LOGIN',  payload:user})
    }catch(err){
      console.error(err);
    }
};
  

//   const signIn = async () => {
//     try {
//       const userCredentials = await signInWithEmailAndPassword(auth, email, password);
//       console.log('signed in!');
//       setError(false);
//       const user = userCredentials.user;
//       dispatch({type:'LOGIN',  payload:user});
//       setUserRole('Trainee'); // Set the user role to 'Trainee'
//       if (redirectUrl) {
//         // Navigate the user back to the URL they were on before being redirected to sign-in
//         window.location.href = decodeURIComponent(redirectUrl);
//       } else {
//         // Navigate the user to a default page (e.g., the home page) if no redirect URL is provided
//         Navigate('/');
//       }
//     } catch(err) {
//       console.error(err);
//       setError(true);
//     }
// };

const signInWithGoogle = async () => {
  try {
    const userCredentials = await signInWithPopup(auth, googleProvider);
    console.log('signed in!');
    setError(false);
    const user = userCredentials.user;
    dispatch({type:'LOGIN',  payload:user});
    setUserRole('Trainee'); // Set the user role to 'Trainee'

    const queryParams = new URLSearchParams(window.location.search);
    const redirectUrl = queryParams.get('redirect');
    if (redirectUrl) {
      // Navigate the user back to the URL they were on before being redirected to sign-in
      window.location.href = decodeURIComponent(redirectUrl);
    } else {
      // Navigate the user to the profile wizard if no redirect URL is provided
      Navigate('/profileWizardTrainee');
    }
  } catch(err) {
    console.error(err);
    setError(true);
  }
};

// const signInWithFacebook = async () => {
//   try {
//     const userCredentials = await signInWithPopup(auth, faceProvider);
//     console.log('signed in with facebook!');
//     setError(false);
//     const user = userCredentials.user;
//     dispatch({ type: 'LOGIN', payload: user });
//     Navigate('/');
//   } catch (err) {
//     console.error(err);
//     setError(true);
//   }
// };

  // const logOut = async () => {
  //   try{
  // await signOut(auth)
  // console.log('signed Out!')
  //   }catch(err){
  //     console.error(err);
  //   }
  // };

  return (

    <div className='signinbox'>
      <h2 dir='rtl' lang='he'>כניסת מתאמנים ל-Fitit</h2>
      {/* <input placeholder="אימייל" onChange={(e) => setEmail(e.target.value)} lang='he'/>
      <input placeholder="סיסמא" type='password' onChange={(e) => setPassword(e.target.value)} lang='he'/>
      <button onClick={signIn} lang='he' className='btn1'>כניסה</button> */}

      <button onClick={signInWithGoogle}  className='googsign' lang='he'><img src= {googlogo}/> כניסה באמצעות גוגל</button>

      {/* <button onClick={signInWithFacebook}  className='facesign' lang='he'><img src= {facelogo}/> כניסה באמצעות פייסבוק</button> */}
      {/* <button onClick={logOut} lang='he'>התנתק</button> */}

      {/* <button onClick={signUp} lang='he' className='signup'>הרשמה</button> */}
        {error && <span lang='he'>אימייל או סיסמא שגויים</span>}

        <span lang='he' style={{ position: 'absolute', bottom: '10%' }}>
        <NavLink to='/signin' style={{ color: '#647dee', fontWeight: '600', textDecoration:'none'}}>
          לכניסת מאמנים
        </NavLink>
        </span>

    </div>
  )
}