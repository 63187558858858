import React from "react";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: 'black',
  },
  heading: {
    fontSize: "3rem",
    marginBottom: "1rem",
  },
  message: {
    fontSize: "1.5rem",
    marginBottom: "1rem",
  },
}


export const NotFound = () => {
  return (
    <div style={styles.container} lang='he' className="notfound">
      <h1 style={styles.heading}>404 - אופס</h1>
      <p style={styles.message}> 
      😥 הדף שחיפשתם לא נמצא  
      </p>

      <p style={styles.message}> 
        הוא בטח באמצע אימון🏋️ אל תחכו לו  
      </p>

      <p style={styles.message}>
       <a href="/"> מכאן אפשר לעבור לדף הבית</a>
      </p>
    </div>
  );
};
