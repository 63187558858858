import {db, auth, storage} from '../config/Firebase'
import {useEffect, useState } from 'react'
import {getDocs, collection, doc, addDoc, deleteDoc} from 'firebase/firestore'
import {ref, uploadBytes} from 'firebase/storage'

// import {getDoc} from 'firebase/firestore'

export const Trainers = () => {
    
    const [trainers, setTrainers] = useState([]);

//new trainers state
        const [newTrainersfName, setNewTrainersfname] = useState('')
        const [newTrainerslName, setNewTrainerslname] = useState('')
        const [newTrainersDescription, setNewTrainersDescription] = useState('')
        const [newTrainersPrice, setNewTrainersPrice] = useState(0)
        const [newTrainersInstagram, setNewTrainersInstagram] = useState('')
        // const [newTrainersfAddress, setNewTrainersAddress] = useState('')

        //files
        const [fileUpload, setFileUpload] = useState(null);
        
    const onSubmitTrainer = async() => {
        try{
        await addDoc(trainersCollectionRef, {fName:newTrainersfName, 
            lName:newTrainerslName, Description:newTrainersDescription, 
            Price:newTrainersPrice, Instagram:newTrainersInstagram,
            userId: auth?.currentUser?.uid,
        });
        getTrainersList();
        }catch(err){
        console.error(err)
        }
    };






// get trainers
    const trainersCollectionRef = collection(db, 'Trainers')

    const getTrainersList = async () => {
        //read data
        try{
            const data = await getDocs(trainersCollectionRef);
            const filteredData = data.docs.map((doc) => ({
                ...doc.data(), 
                id: doc.id,
            }));
            setTrainers(filteredData)
            // console.log({filteredData});
        }catch(err){
                console.error(err);
            }
    };

    const deleteTrainer = async (id) => {
        const trainerDoc = doc(db, 'Trainers', id)
        await deleteDoc(trainerDoc);
        getTrainersList();
    };

 //set trainerlist
    useEffect(() => {
        getTrainersList();

    }, []);

   //upload fiels
   const uploadFile = async () =>{
    if (!fileUpload) return;
    const fileFolderRef = ref(storage, `TrainerFiles/${fileUpload.name}`);
    try{
    await uploadBytes(fileFolderRef, fileUpload);
    }catch(err){
    console.error(err)
    }
   };


    return (
        <>
        <div>
            <br/>
            <input placeholder='Trainer fname...' type='text' onChange={(e) => setNewTrainersfname(e.target.value)}/>
            <input placeholder='Trainer lname...' type='text' onChange={(e) => setNewTrainerslname(e.target.value)}/>
            <input placeholder='Trainer description...' type='text' onChange={(e) => setNewTrainersDescription(e.target.value)}/>
            <input placeholder='Trainer instagram...' type='text' onChange={(e) => setNewTrainersInstagram(e.target.value)}/>
            <input placeholder='Trainer price...' type='number'onChange={(e) => setNewTrainersPrice(Number(e.target.value))}/>
            {/* <input placeholder='Trainer address...'/> */}
            <button type='submit' onClick={onSubmitTrainer}>submit trainer</button>
        </div>  


        <div>
            {trainers.map((trainer) =>(
                <div key={trainer.id}>
                    <h1>{trainer.fName}</h1>
                    <h1>{trainer.lName}</h1>
                    <p>{trainer.Description}</p>
                    <h2>{trainer.Instagram}</h2>
                    <h2>{trainer.Price}</h2>
                <button onClick={() => deleteTrainer(trainer.id)}>Delete Trainer</button>
                <br/>
                </div>
            ))}
        </div>

        <div>
            <input type='file' onChange={(e) => setFileUpload(e.target.files[0])}/>
            <button onClick={uploadFile}>upload file</button>
        </div>
       </>
    )
}