import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/SessionConfrirmation.css'

import { Confetti } from './Confetti';

export const SessionConfirmation = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const { sessionData } = location.state;
  
  // Parse the date and format it
  const parsedDate = new Date(sessionData.sessionDate);
  const formattedDate = new Intl.DateTimeFormat('he-IL', { 
    weekday: 'long', 
    day: 'numeric',
    month: 'numeric',
    year: '2-digit'
  }).format(parsedDate);
  
  console.log(sessionData)

  const goToProfile = () => {
    Navigate('/TraineeProfile', { state: { displayComponent: 'luzIshi' } });
  };

  return (
<div style={{display:'flex'}}>
    <div className="session-confirmation" lang='he'>
        <Confetti/>
      {sessionData.sessionStatus == 'Pending' && (sessionData.approveOrNot == true || sessionData.approveOrNot == 'כן') &&
        <>
          <h3>תודה {sessionData.trainee.fName}!</h3>
          <h2> הבקשה נשלחה ל{sessionData.trainer.fName} 💪</h2>
          <h3>{formattedDate}</h3>
            <div className='time'>
              <p> {sessionData.endTime}</p>
              <p>עד</p>
              <p> {sessionData.startTime}</p>
            </div>
          <p>בגלל שזאת הפעם הראשונה שלכם<br/>
          איתנו, נחכה שהבקשה <span>תאושר</span></p>

          <p>מהפעם הבא, הכל אוטומטי</p>
        </>}

        {sessionData.sessionStatus == 'Pending' && (sessionData.approveOrNot == false || sessionData.approveOrNot == 'לא')  &&
        <>
          <h3>תודה {sessionData.trainee.fName}!</h3>
          <h2> הבקשה נשלחה ל{sessionData.trainer.fName} 💪</h2>
          <h3>{formattedDate}</h3>
            <div className='time'>
              <p> {sessionData.endTime}</p>
              <p>עד</p>
              <p> {sessionData.startTime}</p>
            </div>
          <p>עכשיו נחכה שהבקשה <span>תאושר</span></p>

        </>}
        
         {sessionData.sessionStatus == 'Upcoming' &&
        <>
          <h3>תודה {sessionData.trainee.fName}!</h3>
          <h2>  קבענו לכם אימון עם {sessionData.trainer.fName} 💪</h2>
          <h3>{formattedDate}</h3>
          <div className='time'>
              <p> {sessionData.endTime}</p>
              <p>עד</p>
              <p> {sessionData.startTime}</p>
            </div>
          <p><span>כל הכבוד, נתראה שם!</span></p>
        </>}
    </div>
    
      <h3 onClick={goToProfile} className='view_my_sessions' lang='he'>
      &#60; לצפייה באימונים שלי 
    </h3>


    </div>
  );
};
