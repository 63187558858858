import React from "react";
import "../css/ToggleSwitch.css";

const ToggleSwitch = ({ isActive, onToggleChange }) => {
  const handleToggle = () => {
    onToggleChange(); // Calling the parent's handler
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={isActive} onChange={handleToggle} />
      <span
        className="slider"
        style={{
          // all: "initial",
          position: "absolute",
          cursor: "pointer",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          transition: "0.4s",
          width: 'unset',
          border: 'unset',
          borderRadius: '15px'
        }}
      />
    </label>
  );
};

export default ToggleSwitch;
