import React, { useState, useEffect } from "react";
import {db, auth, storage} from '../config/Firebase'
import { addDoc, collection, query, where, getDocs, updateDoc, doc, Timestamp } from "firebase/firestore";
import { Loading } from "./Loading";
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'
//file size
import Resizer from "react-image-file-resizer";
//img
import d_profile from '../imgs/Fitit_profile.png'
export const TraineeProfile = () => {
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [phone, setPhone] = useState("");
    const [existingTrainee, setExistingTrainee] = useState(null);
    const [loading, setLoading] = useState(true)
    const [traineeImgUrl, setTraineeImgUrl] = useState(null)
    const [fileUpload, setFileUpload] = useState(null);

    const traineesCollectionRef = collection(db, "Trainees");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            const fetchTrainee = async () => {
                if (user) {
                    const q = query(collection(db, 'Trainees'), where('userId', '==', user.uid));
                    const querySnapshot = await getDocs(q);
            
                    if (!querySnapshot.empty) {
                        const traineeData = querySnapshot.docs[0].data();
                        setFName(traineeData.fName);
                        setLName(traineeData.lName);
                        setPhone(traineeData.Phone);
                        setExistingTrainee(traineeData);
    
                        // Get and download the trainee's image if it exists
                        if (traineeData.ImageUrl) {
                            const storageRef = ref(storage, traineeData.ImageUrl);
                            try {
                                const imageUrl = await getDownloadURL(storageRef);
                                setTraineeImgUrl(imageUrl);
                            } catch (err) {
                                console.error(err);
                            }
                        } else {
                            setTraineeImgUrl(null);
                        }
                    } else {
                        // If no data found, initialize the state with empty values
                        setFName("");
                        setLName("");
                        setPhone("");
                        setExistingTrainee(null);
                        setTraineeImgUrl(null);
                    }
                }
                setLoading(false);
            };
            
            fetchTrainee();
        });
        // Cleanup on unmount
        return () => {
          unsubscribe();
        };
    }, []);
    
    

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log(existingTrainee);
        if (existingTrainee) {
          try {
            // Upload the image and get the URL
            await uploadFile();
      
            // Query for the document with the matching userId
            const traineeRef = collection(db, "Trainees");
            const q = query(traineeRef, where("userId", "==", existingTrainee.userId));
            const querySnapshot = await getDocs(q);
      
            // Check if a matching document is found
            if (!querySnapshot.empty) {
              const docRef = doc(db, "Trainees", querySnapshot.docs[0].id); // Get the document reference
      
              await updateDoc(docRef, {
                fName: fName,
                lName: lName,
                phone: phone,
                // Update ImageUrl if a new image was uploaded
                ImageUrl: fileUpload ? `TraineeFiles/${auth.currentUser.uid}` : null,
              });
      
              console.log("Trainee updated.");
            }
          } catch (err) {
            console.error(err);
          }
          return;
        }
      
        try {
          await addDoc(traineesCollectionRef, {
            fName: fName,
            lName: lName,
            phone: phone,
            userId: auth?.currentUser?.uid,
            userMail: auth?.currentUser?.email,
            role: "Trainee",
            createdAt: Timestamp.now(),
            // Update ImageUrl if a new image was uploaded
            ImageUrl: fileUpload ? `TraineeFiles/${auth.currentUser.uid}` : null,
          });
          console.log("Trainee added.");
        } catch (err) {
          console.error(err);
        }
      };
      
    

    // Upload files
const uploadFile = async () => {
    if (!fileUpload) return;
    try {
      // Resize the image before uploading
      const resizedImage = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          fileUpload,
          800, // Specify the desired width
          600, // Specify the desired height
          'JPEG', // Output format (e.g., JPEG, PNG)
          100, // Quality (0 to 100)
          0, // Rotation (0, 90, 180, or 270)
          (resizedFile) => {
            resolve(resizedFile);
          },
          'blob' // Output type ('blob' or 'base64')
        );
      });
  
      // Upload the resized file to Firebase Storage
      const fileFolderRef = ref(storage, `TraineeFiles/${auth.currentUser.uid}`);
      await uploadBytes(fileFolderRef, resizedImage);
    } catch (err) {
      console.error(err);
    }
  };
  
  const updateImg = (pImg) =>{
    if (!pImg) return;
    try {
      const file = pImg
      const imageUrl = URL.createObjectURL(file);
      setTraineeImgUrl(imageUrl);
    } catch(err){
      console.error(err);
    }
  }

  return loading ? (
    <Loading/>
) : (
    <div className="mid inputs">
        <form onSubmit={onSubmit}>
            <input
                type="text"
                value={fName}
                onChange={(e) => setFName(e.target.value)}
                placeholder="First Name"
                required
            />
            <input
                type="text"
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                placeholder="Last Name"
                required
            />
            <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                required
            />
            <div className="img_t">
                <div className='upload_file' style={{width: '50%'}}>
                    <label htmlFor='fileupload'>לחצו כאן כדי לעלות תמונה</label>
                    <input type='file' id='fileUpload' onChange={(e) => { setFileUpload(e.target.files[0]); updateImg(e.target.files[0]);}} className='hiddenbtn'/>
                </div>

                <div className="circle">
                    {loading === false && traineeImgUrl != null ? (
                        <img
                            src={traineeImgUrl}
                            alt={`${existingTrainee?.fName} ${existingTrainee?.lName}`}
                        />
                    ) : (
                        <img
                            src={d_profile}
                            alt="Default Profile"
                        />
                    )}
                </div>
            </div> 
            <button type="submit"
            className="update_trainee_btn"
            lang="he">עדכון פרופיל</button>
        </form>
    </div>
);
};
