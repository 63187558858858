import React from 'react';
import { Helmet } from 'react-helmet';

export const Article = ({ articleId, articles }) => {
  const article = articles.find((article) => article.id === articleId);

  if (!article) {
    return (
      <div className='arct'>
        <h1>בחר מאמר מהרישמה</h1>
      </div>
    );  
  }

  return (
    <div className='arct'>

      <Helmet>
        <title>{article.title}</title>
        <meta name='description'
        content={article.content[0]}/>
      </Helmet>

      <h2>{article.title}</h2>
      <h3>נכתב על ידי {article.author}</h3>
      {article.content && article.content.length > 0 && (
        article.content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))
      )}
    </div>
  );
};
