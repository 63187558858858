import { useEffect } from 'react';
import { db, auth } from '../config/Firebase';
import { deleteDoc, doc, query, collection, where, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { deleteUser, signOut } from 'firebase/auth';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { WhatsappShareButton } from 'react-share';
import { AuthContext } from '../Constext/AuthContext'

import '../css/LoggedProfileDelete.css';
import WhatsappIcon from '../imgs/WhatsappIcon.png'
import Calendar from '../imgs/calendar.png'
import fitit from '../imgs/FititF.png'

export const LoggedProfileDelete = () => {
  const Navigate = useNavigate();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [trainerData, setTrainerData] = useState(null)
  const { userRole } = useContext(AuthContext)

  useEffect(() => {
    if (userRole === 'Trainer') {
      getTrainerUsername(); // Fetch trainer data when the component mounts if userRole is 'Trainer'
    }
  }, [userRole]);

  const getTrainerUsername = async () => {
    const userId = auth.currentUser.uid;
    const q = query(collection(db, 'Trainers'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const trainerData = querySnapshot.docs[0].data();
      console.log("Fetched trainerData:"); // Debugging output
      setTrainerData(trainerData);
    }
    return null;
  };

  const deleteUser = async () => {
    const userId = auth.currentUser.uid;

    // Query the "Trainers" collection to find the document with matching user ID
    const q = query(collection(db, 'Trainers'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    // If a matching document is found, delete it
    if (!querySnapshot.empty) {
      const trainerDoc = doc(db, 'Trainers', querySnapshot.docs[0].id);
      const trainerData = querySnapshot.docs[0].data();

      // Add a count to the DeletedTrainers collection with the created-at date, email, and user ID
      await addDoc(collection(db, 'DeletedTrainers'), {
        createdAt: Timestamp.now(),
        email: trainerData.userMail,
        userId: trainerData.userId
      });
      // Delete the trainer document
      await deleteDoc(trainerDoc);

      // Delete the user from Firebase Authentication
      // await deleteUser(auth.currentUser);
    }

    logOut();
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      localStorage.setItem('user', JSON.stringify(null)); // Update the currentUser value in local storage
      Navigate('/');
      window.location.reload(); // Refresh the page
      console.log('signed Out!');
    } catch (err) {
      console.error(err);
    }
  };

  const showConfirmationDialog = () => {
    setIsConfirmationVisible(true);
  };

  const hideConfirmationDialog = () => {
    setIsConfirmationVisible(false);
  };

  const handleDelete = () => {
    deleteUser();
    hideConfirmationDialog();
  };

  let shareUrl; // Declare shareUrl outside of the conditional blocks
  let shareUrlSced;
  let shareUrlPersonal;

  if (userRole === 'Trainee') { // Use triple equals for strict comparison
    shareUrl = 'https://fitit.app/ איזה מגניב! אפשר למצוא פה מאמני כושר ולקבוע אימונים בקלות'; // Set the value for 'Trainee' role
  } else if (userRole === 'Trainer' && trainerData) { // Fix the syntax error here by using else if and triple equals
    shareUrl = 'https://fitit.app/ אפשר למצוא אותי פה ולקבוע אימונים בקלות!'; // Set the value for 'trainer' role
    shareUrlPersonal = `https://fitit.app/${trainerData.websiteUrl}`;
    shareUrlSced = `https://fitit.app/${trainerData.websiteUrl}/schedule`;
  }


  return (
    <div className='del_div'>

    <div className="share-buttons" style={{flexDirection:'column', direction:'rtl'}}>

    {trainerData &&
    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        <h2 lang='he'> שלחו לינק לאתר האישי</h2>
      <WhatsappShareButton url={shareUrlPersonal}>
        <img src={WhatsappIcon}/>
      </WhatsappShareButton>
      </div>}

      <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop:'20px'}}>
          <h2 lang='he'> ספרו עלינו  לחברים</h2>
        <WhatsappShareButton url={shareUrl}>
          <img src={fitit} style={{border:'1px solid #F2F2ED', borderRadius:'5px'}}/>
        </WhatsappShareButton>
      </div>

        {trainerData &&
            <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop:'20px', marginBottom:'100px'}}>
                <h2 lang='he'>שלחו לינק לקביעת אימונים</h2>
                <WhatsappShareButton url={shareUrlSced}>
                  <img src={Calendar}/>
                </WhatsappShareButton>
            </div>
        }

      </div>

      <button onClick={logOut} className='logut' lang='he'>להתנתקות לחצו כאן</button>

      <button onClick={showConfirmationDialog} className='del' lang='he'>
        מחיקת פרופיל
      </button>
      {isConfirmationVisible && (
        <div className='confirmation-dialog'>
                <h1 lang='he' className='del_h1'>
                בלחיצה על כפתור המחיקה הפרופיל ימחק ולא נוכל לשחזר אותו!
              </h1>
          <h2 className='del_h2' lang='he'>האם אתה בטוח שברצונך למחוק את הפרופיל?</h2>
          <button onClick={handleDelete} className='del2'>אישור מחיקה</button>
          <button onClick={hideConfirmationDialog} className='cancel'>ביטול</button>
        </div>
      )}
    </div>
  );
};
