import { useState, useEffect } from 'react';
//google maps
import { GoogleMap, Marker, CircleF } from '@react-google-maps/api';
//logos
import location from '../imgs/location.png';
import location_selected from '../imgs/location-pin.png';
//css
import '../css/Map.css';

//profile defult img
import d_profile from '../imgs/Fitit_profile.png'
//loading
import { Loading } from './Loading'


export function Map({ trainers,
    selectedTrainerId, selectedTrainerPosition,
    handleClick, profileClick, isLoaded, filters }) {

  const [center, setCenter] = useState({ lat: 32.109333, lng: 34.855499 });
  const [selectedTrainer, setSelectedTrainer] = useState(null);

  useEffect(() => {
    if (filters.geo && filters.geo.length === 2 && filters.geo[0].value !== null && filters.geo[1].value !== null) {
     setCenter({ lat: filters.geo[0].value, lng: filters.geo[1].value });
   } else if (selectedTrainerPosition) {
     setCenter(selectedTrainerPosition);
   }
   }, [selectedTrainerPosition, filters.geo]);

  useEffect(() => {
    if (selectedTrainerId) {
      const trainer = trainers.find(trainer => trainer.id === selectedTrainerId);
      setSelectedTrainer(trainer);
    } else {
      setSelectedTrainer(null);
    }
  }, [trainers, selectedTrainerId]);

  useEffect(() => {
    if (isLoaded && document) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = 'https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i10!2i611!3i415!4i256!2m3…';
      link.crossOrigin = 'anonymous';
      document.head.appendChild(link);
    }
  }, [isLoaded]);
  
  if (!isLoaded) {
    return <Loading/>
  }

  // Customize map options
  const mapOptions = {
    disableDefaultUI: true, // Remove default UI components
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }], // Hide points of interest
      },
    ],
  };

  return (
    <GoogleMap
      zoom={selectedTrainerPosition ? 15 : 10}
      center={center}
      options={mapOptions} // Apply custom map options
      mapContainerClassName='map-container'
    >
      {trainers.map((trainer) => {
        if (trainer.Geo && trainer.Geo.Lat && trainer.Geo.Lng) {
          const markerOptions = {
            key: trainer.id,
            position: {
              lat: Number(trainer.Geo.Lat),
              lng: Number(trainer.Geo.Lng),
            },
            icon: trainer.id === selectedTrainerId ? {
              url: location_selected,
              scaledSize: new window.google.maps.Size(30, 30),
            } : {
              url: location,
              scaledSize: new window.google.maps.Size(30, 30),
            },
            title: trainer.fName + ' ' + trainer.lName,
          };
          return <Marker {...markerOptions} onClick={() => handleClick(trainer.id, markerOptions.position)}/>;
        }
        return null;
      })}

      {selectedTrainer && (
        <div className="trainer_box" onClick={() => profileClick(selectedTrainer.id)}>
          <div className="img_t">
            <div className="circle">
              {selectedTrainer.imageUrl && (
                <img
                  src={selectedTrainer.imageUrl}
                  alt={`${selectedTrainer.fName} ${selectedTrainer.lName}`}
                />
              )}
              {!selectedTrainer.imageUrl && <img
                    src={d_profile}
                    alt={`${selectedTrainer.fName} ${selectedTrainer.lName}`}
                  />}
            </div>
          </div>
          <h1>
            {selectedTrainer.fName} {selectedTrainer.lName}
          </h1>
          <ul>
            {/* <li>{selectedTrainer.Instagram}</li>
            <li>{selectedTrainer.Price}</li> */}
            <li>{selectedTrainer.Address}</li>
          </ul>
        </div>
      )}
    <CircleF options={{ strokeColor: '#647dee',
    fillColor: '#647dee',
    strokeOpacity: 0.2,
    strokeWeight: 2,
    fillOpacity: 0.2,
    center: 
      filters.geo && filters.geo !== undefined 
      && filters.geo !== null 
      && filters.geo[0].value !== null 
      && filters.geo[1].value !== null ?
      { lat: filters.geo[0].value, lng: filters.geo[1].value } : null,
    radius: filters.distance ? filters.distance * 1000 : 1000,
    visible: true, 
    zIndex:100,
    key: 1,}} />
    </GoogleMap>
  );
}
