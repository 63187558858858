// const AuthReducer = (state, action) => {
//     switch (action.type){
//         case 'LOGIN':{
//             return{
//                 currentUser: action.payload,
//             }
//         }
//         case 'LOGOUT':{
//             return{
//                 currentUser: null
//             };
//         }
//         default:
//             return state;
//     }
// };

// export default AuthReducer;


const AuthReducer = (state, action) => {
    switch (action.type) {
      case 'LOGIN': {
        return {
          ...state,
          currentUser: action.payload,
        };
      }
      case 'LOGOUT': {
        return {
          ...state,
          currentUser: null,
        };
      }
      case 'SET_USER_ROLE': {
        return {
          ...state,
          userRole: action.payload,
        };
      }
      default:
        return state;
    }
  };  
  
  export default AuthReducer;
  