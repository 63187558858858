import { useState, useEffect  } from 'react';
import '../css/Calendar.css';
import '../css/TrainerCalendar.css';
import { doc, setDoc, query, collection, where, getDocs } from "firebase/firestore";
import {ref, getDownloadURL} from 'firebase/storage'
import { db, storage } from '../config/Firebase'; // adjust the path if necessary

import d_profile from '../imgs/Fitit_profile.png'


export const Requests = ({ requests, refetchData }) => {
  const handleApprove = async (request) => {
    console.log("Approved");
    try {
      await setDoc(doc(db, 'sessions', request.id), { sessionStatus: 'Upcoming' }, { merge: true });
      refetchData();  // Fetch the updated data
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
    
  const handleDecline = async (request) => {
    console.log("Declined");
    // update Firestore here
    try {
      await setDoc(doc(db, 'sessions', request.id), { sessionStatus: 'Declined' }, { merge: true });
      refetchData();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
   
  const [showExtraDetails, setShowExtraDetails] = useState(null);
  const [traineeDetails, setTraineeDetails] = useState({});

  const handleMoreDetails = async (request) => {
    console.log("More Details");
    if (request.trainee) {
        try {
            const q = query(collection(db, 'Trainees'), where("userId", "==", request.trainee.id));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) { 
                console.log(`No trainee found with id ${request.trainee.id}`);
            } else {
                querySnapshot.forEach((doc) => {
                    setTraineeDetails(prev => ({ ...prev, [request.id]: doc.data() }));
                });
            }
        } catch (error) {
            console.error("Error fetching document: ", error);
        }
    } else {
        console.log('request.trainee is undefined for request id:', request.id);
    }
    setShowExtraDetails(request.id);  // Save the ID of the request being shown
};

return (
  <>
    {requests.length === 0 ? (
      <div className='no-requests'>
        <h3>אין בקשות חדשות 🏆</h3>   
        <p>בקשות חדשות ממתאמנים יופיע כאן</p>
      </div>
    ) : (
      requests.map((request, index) => {
        const parsedDate = new Date(request.sessionDate);
        const formattedDate = new Intl.DateTimeFormat('he-IL', { 
          weekday: 'long', 
          day: 'numeric',
          month: 'numeric',
          year: '2-digit'
        }).format(parsedDate);

        return (
          <div key={index} className='request'> 
            <h2>{request.trainee.fName} {request.trainee.lName}</h2>
              <h3>{formattedDate}</h3>
            <div className='hours'>
              <h3>{request.startTime}</h3>
              <h3>עד</h3>
              <h3>{request.endTime}</h3>
            </div>

            {showExtraDetails !== request.id &&
              <span onClick={() => handleMoreDetails(request)} className='more'>
                פרטים נוספים
              </span>
            }

            {showExtraDetails === request.id && traineeDetails[request.id] &&
              <div className='hours'>
                <h3>טלפון </h3>
                <h3><a href={`tel:${traineeDetails[request.id].Phone}`}>{traineeDetails[request.id].Phone}</a></h3>
              </div>
            }

            <div className='btns'>
              <button onClick={() => handleApprove(request)} className='approve'>אישור</button>
              <button onClick={() => handleDecline(request)} className='decline'>דחייה</button>
            </div>
          </div>
        )
      })
    )}
  </>
);
}


export const ApprovedRequests = ({ requests, declined, canceled, changed, refetchData }) => {
  const [filter, setFilter] = useState('all');

  // Ensure requests and declinedSessions are both arrays
  const allRequests = [...(requests || []), ...(declined || []), ...(canceled || []), ...(changed || [])];

  const filteredRequests = allRequests.filter(request => {
    if (filter === 'all') return true;
    if (filter === 'upcoming' && (request.sessionStatus === 'Upcoming' || request.sessionStatus === 'Done')) return true;
    if (filter === 'declined' && (request.sessionStatus === 'Declined' || request.sessionStatus === 'Canceled' || request.sessionStatus === 'Changed')) return true;
    return false;
  });
  

  filteredRequests.sort((a, b) => {
    const dateA = new Date(`${a.sessionDate}T${a.startTime}`);
    const dateB = new Date(`${b.sessionDate}T${b.startTime}`);
    return dateB - dateA; // For ascending order
  });
  
  const handleDecline = async (request) => {
    const userConfirmation = window.confirm("האם לבטל את האימון?");
    if (!userConfirmation) return; // If the user clicked "Cancel", exit the function without updating the database
  
    console.log("Declined");
    // update Firestore here
    try {
      await setDoc(doc(db, 'sessions', request.id), { sessionStatus: 'Declined' }, { merge: true });
      refetchData();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <>
      <div className='filter-buttons'>
        <button className={filter === 'all' ? 'selected' : ''} onClick={() => setFilter('all')}>הכל</button>
        <button className={filter === 'upcoming' ? 'selected' : ''} onClick={() => setFilter('upcoming')}>אושרו</button>
        <button className={filter === 'declined' ? 'selected' : ''} onClick={() => setFilter('declined')}>נדחו/בוטלו</button>
      </div>
      {filteredRequests.map((request, index) => {
        const parsedDate = new Date(request.sessionDate);
        const formattedDate = new Intl.DateTimeFormat('he-IL', { 
          weekday: 'long', 
          day: 'numeric',
          month: 'numeric',
          year: '2-digit'
        }).format(parsedDate);
  
        return (
          <div key={index} className='request approved'> 
            <h2>{request.trainee.fName} {request.trainee.lName}</h2>
              <h3>{formattedDate}</h3>
            <div className='hours'>
              <h3>{request.startTime}</h3>
              <h3>עד</h3>
              <h3>{request.endTime}</h3>
            </div>
            <div className='hours'>
              {(request.sessionStatus === 'Upcoming') && <span className='approved'>מאושר</span>}
              {(request.sessionStatus === 'Upcoming') && <span className='x_session' onClick={() => handleDecline(request)}>ביטול האימון</span>}
            </div>
            {(request.sessionStatus === 'Declined') && <span className='declined'>נדחה</span>}
            {(request.sessionStatus === 'Canceled') && <span className='declined'>בוטל</span>}
            {(request.sessionStatus === 'Changed') && <span className='changed'>שונה</span>}  
            {(request.sessionStatus === 'Done') && <span className='done'>הסתיים</span>}           
          </div>
        )
      })}
    </>
  );
  }
  

export const DailySessions = ({ selectedSessions, switchToRequests, refetchData }) => {

  const [traineeImgUrl, setTraineeImgUrl] = useState({})

  const getCurrentClass = (sessionDate, startTime, endTime) => {
      const now = new Date();
      const startDateTime = new Date(`${sessionDate}T${startTime}`);
      const endDateTime = new Date(`${sessionDate}T${endTime}`);
      if (now >= startDateTime && now <= endDateTime) {
          return 'now';
      } else if (now > endDateTime) {
          return 'past';
      } else {
          return '';
      }
  }

  const sortedSessions = [...selectedSessions].sort((a, b) => {
      const dateA = new Date(`${a.sessionDate}T${a.startTime}`);
      const dateB = new Date(`${b.sessionDate}T${b.startTime}`);
      return dateA - dateB;
  });

  const handleDecline = async (request) => {
    const userConfirmation = window.confirm("האם לבטל את האימון?");
    if (!userConfirmation) return; // If the user clicked "Cancel", exit the function without updating the database
  
    console.log("Declined");
    // update Firestore here
    try {
      await setDoc(doc(db, 'sessions', request.id), { sessionStatus: 'Declined' }, { merge: true });
      refetchData();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const [showExtraDetails, setShowExtraDetails] = useState(null);
  const [traineeDetails, setTraineeDetails] = useState({});

  const handleMoreDetails = async (request) => {
    if (showExtraDetails === request.id) {
      // If extra details are already being shown for this request, hide them
      setShowExtraDetails(null);
      return;
    }
    
    if (request.trainee) {
      try {
        const q = query(collection(db, 'Trainees'), where("userId", "==", request.trainee.id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const traineeData = doc.data();
  
            // Logic to set trainee details
            setTraineeDetails(prev => ({ ...prev, [request.id]: traineeData }));
          });
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
      setShowExtraDetails(request.id); // Save the ID of the request being shown
    }
  };
  
  // Function to fetch trainee details and images
  const fetchTraineeDetailsAndImages = async () => {
    selectedSessions.forEach(async (session) => {
      if (session.trainee) {
        try {
          const q = query(collection(db, 'Trainees'), where("userId", "==", session.trainee.id));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.empty) { 
            console.log(`No trainee found with id ${session.trainee.id}`);
          } else {
            querySnapshot.forEach(async (doc) => {
              const traineeData = doc.data();
              setTraineeDetails(traineeData)
              
              if (traineeData.ImageUrl) {
                const storageRef = ref(storage, traineeData.ImageUrl);
                try {
                  const imageUrl = await getDownloadURL(storageRef);
                  setTraineeImgUrl(prev => ({ ...prev, [session.id]: imageUrl }));
                } catch (err) {
                  console.error(err);
                }
              } else {
                setTraineeImgUrl(prev => ({ ...prev, [session.id]: traineeData.ImageUrl }));
              }
            });
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
        }
      } else {
        console.log('session.trainee is undefined for session id:', session.id);
      }
    });
  };

  useEffect(() => {
    fetchTraineeDetailsAndImages();
  }, [selectedSessions]);

  return (
      <div>
          {sortedSessions.map((session, index) => {
              const sessionClass = getCurrentClass(session.sessionDate, session.startTime, session.endTime);
              return (
                  <div className={`daily ${sessionClass}`} key={session.id}>
                      <div>
                          <h3>{session.trainee.fName} {session.trainee.lName}</h3>

                          <div className="circle">
                            {traineeImgUrl[session.id] ? (
                              <img
                                src={traineeImgUrl[session.id]}
                                alt={`${session.trainee.fName} ${session.trainee.lName}`} // Updated this line
                              />
                            ) : (
                              <img src={d_profile} alt="Fitit Profile" />
                            )}
                          </div>

                          <div className='hours'>
                              <h3>{session.startTime}</h3>
                              <h3>עד</h3>
                              <h3>{session.endTime}</h3>
                          </div>

                          <div className='hours' style={{ flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                          {showExtraDetails !== session.trainee.Phone &&
                              <span onClick={() => handleMoreDetails(session)} className='more' style={{flexBasis: '100%'}}>
                                פרטים נוספים
                              </span>
                            }

                              <div style={{flexBasis: '100%', marginBottom:'10px'}}>
                              {showExtraDetails === session.id && traineeDetails[session.id] &&
                                <div className='hours'>
                                  <h3 style={{width:'fit-content'}}>טלפון </h3>
                                  <h3><a href={`tel:${traineeDetails[session.id].Phone}`}>{traineeDetails[session.id].Phone}</a></h3>
                                </div>
                              }
                              </div>
                          </div>

                          {(session.sessionStatus == 'Pending') && 
                              <span 
                                className='to_approve'
                                onClick={switchToRequests}
                              >
                                ממתין לאישור
                              </span>
                            }
                         
                          {(session.sessionStatus == 'Upcoming') && <span className='approved'>מאושר</span>}
                          {(session.sessionStatus === 'Upcoming') && <span className='x_session'
                           onClick={() => handleDecline(session)}>ביטול האימון</span>
}

                          {(session.sessionStatus == 'Declined') && <span className='declined'>נדחה</span>}
                          {(session.sessionStatus === 'Done') && <span className='done'>הסתיים</span>}  
                      </div>
                  </div>
              )
          })}
      </div>
  );
};





// export const MonthlyView = ({ date, scheduleData, trainerData, setStartTime, setEndTime }) => {
export const MonthlyView = () => {

  return (
    <div>
בקרוב
    </div>
  );
};