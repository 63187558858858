import React from "react";
import { useState, useEffect } from "react";

//fitness types list
import { fitnessTypeOptions } from '../Constext/filters'

// google maps
import { Autocomplete } from '@react-google-maps/api';

//geohash
import { geohashForLocation } from 'geofire-common';

//imgs
import user from '../imgs/user.png'
import users from '../imgs/users.png'
import { Loading } from "./Loading";

export function Filter({ handleMapChange,  onFilterChange, isLoaded, setSelectedTrainerId }){
  
    const[showFilter, setShowFilter] = useState(false)
    const [mapActive, setMapActive] = useState(false);
    const [listActive, setListActive] = useState(true);
    const [showMobileExplain, setShowMobileExplain] = useState();

    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedDistance, setSelectedDistance] = useState(1);
    const [selectedFitnessType, setSelectedFitnessType] = useState("");
    const [selectedTrainingTypePersonal, setSelectedTrainingTypePersonal] = useState(false);
    const [selectedTrainingTypeGroup, setSelectedTrainingTypeGroup] = useState(false);
    const [selectedGeo, setSelectedGeo] = useState([{ title: 'Lat', value: null }, { title: 'Lng', value: null }])
    const [selectedGeoHash, setSelectedGeoHash ] = useState('')
    const [clearFilters, setClearFilters] = useState (true) 


    useEffect(() => {
      const showExplain = localStorage.getItem("showMobileExplain");
      if (showExplain === null || showExplain === "true") {
        setShowMobileExplain(true);
      } else {
        setShowMobileExplain(false);
      }
    }, []);

    const changeView = (e) => {
    e.preventDefault();
      setMapActive(!mapActive);
      setListActive(!listActive);
      handleMapChange(!mapActive);
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      const filters = {
        location: selectedLocation,
        distance: selectedDistance,
        FitnessType: selectedFitnessType,
        trainingTypePersonal: selectedTrainingTypePersonal,
        trainingTypeGroup: selectedTrainingTypeGroup,
        geo: selectedGeo,
        hash: selectedGeoHash,
      };
      onFilterChange(filters);
      setClearFilters(false)
      setShowFilter(!showFilter)
    };

    const handlePlaceChanged = (place) => {
      if (place && place.geometry && place.geometry.location) {
        const { lat, lng } = place.geometry.location;
        const hash = geohashForLocation([lat(), lng()]);
        setSelectedLocation(place.formatted_address)
        setSelectedGeo([
          {title: 'Lat', value:lat()},
          {title: 'Lng', value:lng()}
        ])
        setSelectedGeoHash(hash)
      ;}
    };

      if (!isLoaded) {
        return <Loading/>
      }

        const handleDistanceChange = (event) => {
          setSelectedDistance(event.target.value);
        };

        const handleReset = (event) => {
          event.preventDefault();
          event.target.form.reset();
        };

          const closeMobileExplain = () => {
            setShowMobileExplain(false);
            localStorage.setItem("showMobileExplain", "false");
          };

return(
   
<>
{showMobileExplain && 
<div className="mobile_explain">
  <h4>לחצו על החץ ⌃ הסגול על מנת לפתוח את פילטר החיפוש וחפשו את המאמן המתאים לכם ביותר!</h4>
  <span className="close-button" onClick={closeMobileExplain}>x</span>
</div>}

    <div className='map_or_list'>
    <button className={mapActive? 'map_btn active': 'map_btn'} onClick={changeView}>מפה</button>
    <div className='divider'/>
    <button className={listActive? 'list_btn active': 'list_btn'} onClick={changeView}>רשימה</button>
    </div>

    <form className={showFilter? 'filter':"filter closed_filter"} onSubmit={handleSubmit}>
      
      <label className='search-item'>מיקום</label>
  
                  <Autocomplete
                      className='AUTOCOMPLETE'
                        options={{
                          types: ['address'],
                          componentRestrictions: { country: 'IL' },
                          language: 'iw',
                        }}
                        onLoad={(autocomplete) => {
                            autocomplete.addListener('place_changed', () =>
                            handlePlaceChanged(autocomplete.getPlace())
                          );}}
                      >
                      <input type="text" placeholder="מיקום"/>
                  </Autocomplete>

    <div className="row_label">

      
      <label
      className='search-item' htmlFor="distance" style={{direction: 'rtl', marginRight: 'auto'}}> 
      {selectedDistance} {'קמ'}
      </label>

      <label className="search-item">רדיוס מרחק</label>   
    </div>

      <input type="range" style={{direction: 'rtl', cursor: 'pointer',accentColor: '#647dee'}}
      min="1"
      max="10"
      id="distance"
      value={selectedDistance}
      onChange={handleDistanceChange}
      />
      

      <label className="search-item">סוג אימון</label>
      <select onChange={(e) => setSelectedFitnessType(e.target.value)} className="select_type_mobile">
        <option value="" hidden>בחר סוג אימון</option>
        {fitnessTypeOptions.map((type, index) => (
          <option key={index} value={type.value}>
            {type.label}
          </option>
        ))}
      </select>

<div className="row_label">

<div className="checkbox_div">
      <button
        lang="none"
        className={`filt_btn ${selectedTrainingTypeGroup ? 'checked' : 'not_checked'}`}
        onClick={(e) =>{
        e.preventDefault();
        setSelectedTrainingTypeGroup(!selectedTrainingTypeGroup)
        }}><img src={users}/>
        אימון קבוצתי
      </button>
    </div>

    <div className="checkbox_div">
    <button
      lang="none"
      className={`filt_btn ${selectedTrainingTypePersonal ? 'checked' : 'not_checked'}`}
      onClick={(e) => {
        e.preventDefault();
        setSelectedTrainingTypePersonal(!selectedTrainingTypePersonal);
      }}><img src={user}/>
      אימון אישי
    </button>
    </div>

</div>

<div className="row_label">
  <button
      className={`clearbtn ${clearFilters ? 'off' : ''}`}
      type="reset"
      lang="none"
      onClick={(event) => {
        event.preventDefault();
        setClearFilters(true);
        onFilterChange([]);
        setSelectedTrainingTypeGroup(false);
        setSelectedTrainingTypePersonal(false);
        handleReset(event); // Pass the event parameter to the handleReset function
        setSelectedTrainerId(null)
      }}
    > נקה חיפוש</button>

      <button type="submit" 
      className={`searchbtn ${clearFilters ? '' : 'on'}`}
      lang='none'>
      חיפוש
      </button>

</div>

</form>
            
    <button onClick={() => setShowFilter(!showFilter)} className="show_filter">
        <div className={showFilter? 'arrow_up':'arrow_down'}/>
      </button>
</>
  )
}