// import { createContext, useEffect, useReducer } from 'react'
// import AuthReducer from './AuthReducer'

// const INITAL_STATE = {
//     currentUser: JSON.parse(localStorage.getItem('user')) || null,
// };

// export const AuthContext = createContext(INITAL_STATE);

// export const AuthContextProvider = ({children}) => {
//     const [state, dispatch] = useReducer(AuthReducer, INITAL_STATE);

//     useEffect(() => {
//         localStorage.setItem('user', JSON.stringify(state.currentUser))
//     }, [state.currentUser])

//     return(
//         <AuthContext.Provider value={{currentUser: state.currentUser, dispatch}}>
//             {children}
//         </AuthContext.Provider>
//     );
// };


import { createContext, useEffect, useReducer } from 'react';
import AuthReducer from './AuthReducer';

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem('user')) || null,
  userRole: localStorage.getItem('userRole') || null, // Retrieve userRole from local storage
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state.currentUser));
    localStorage.setItem('userRole', state.userRole); // Store the userRole in local storage
  }, [state.currentUser, state.userRole]);

  const setUserRole = (role) => {
    dispatch({ type: 'SET_USER_ROLE', payload: role });
  };

  return (
    <AuthContext.Provider
      value={{ currentUser: state.currentUser, userRole: state.userRole, setUserRole, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};
