import React, { useState, useEffect, useRef } from 'react';

const generateTimeSlots = () =>
  Array.from({ length: 49 }, (_, index) => {
    const hour = Math.floor(index / 2).toString().padStart(2, '0');
    const minute = (index % 2 === 0 ? '00' : '30');
    return (
      <option key={index} value={`${hour}:${minute}`}>
        {`${hour}:${minute}`}
      </option>
    );
});

export function TrainerHoursFixed({ onDivsChange, dayName, parentDivs }) {
  const [divs, setDivs] = useState([]);
  const initializingRef = useRef(true);

  // useEffect(() => {
  //   if (parentDivs && parentDivs[dayName]) {
  //     if (initializingRef.current) {
  //       initializingRef.current = false;
  //       setDivs([...parentDivs[dayName]]);
  //     } else {
  //       onDivsChange(divs, dayName);
  //     }
  //   } else {
  //     setDivs([]);
  //   }
  // }, [dayName, parentDivs, divs, onDivsChange]);

  useEffect(() => {
    if (parentDivs && parentDivs[dayName]) {
      if (initializingRef.current) {
        initializingRef.current = false;
        setDivs([...parentDivs[dayName]]);
      }
    } else {
      setDivs([]);
    }
  }, [dayName, parentDivs]);
  

useEffect(() => {
  if (!initializingRef.current) {
    onDivsChange(divs, dayName);
  }
}, [divs, dayName, onDivsChange]);

  const handleAddDiv = () => {
    const lastDivEndTime = divs.length > 0 ? divs[divs.length - 1].endTime : '08:00';

    let startTime = lastDivEndTime;
    let endTime = addMinutesToTime(lastDivEndTime, 30);

    const newDiv = { startTime, endTime, key: divs.length };

    setDivs((prevDivs) => [...prevDivs, newDiv]);
  };

  const handleRemoveDiv = (key) => {
    setDivs((prevDivs) => {
      const updatedDivs = prevDivs.filter((div) => div.key !== key);
      onDivsChange(updatedDivs, dayName);
      return updatedDivs;
    });
  };
  

  const handleTimeChange = (key, field, value) => {
    setDivs((prevDivs) =>
      prevDivs.map((div, index) => {
        if (div.key === key) {
          let startTime = div.startTime;
          let endTime = div.endTime;

          if (field === 'startTime') {
            startTime = value;
            if (startTime === endTime) {
              endTime = addMinutesToTime(endTime, 30);
            }
          } else if (field === 'endTime') {
            endTime = value;
            if (startTime === endTime) {
              endTime = addMinutesToTime(endTime, 30);
            }
          }

          const startTimeMinutes = parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1]);
          let endTimeMinutes = parseInt(endTime.split(':')[0]) * 60 + parseInt(endTime.split(':')[1]);

          if (startTimeMinutes >= endTimeMinutes) {
            endTimeMinutes = startTimeMinutes + 30;
            const endHour = Math.floor(endTimeMinutes / 60).toString().padStart(2, '0');
            const endMinute = (endTimeMinutes % 60).toString().padStart(2, '0');
            endTime = `${endHour}:${endMinute}`;
          }

          if (index > 0) {
            const prevEndTimeMinutes = parseInt(prevDivs[index - 1].endTime.split(':')[0]) * 60 + parseInt(prevDivs[index - 1].endTime.split(':')[1]);
            if (startTimeMinutes <= prevEndTimeMinutes) {
              const startHour = Math.floor((prevEndTimeMinutes + 30) / 60).toString().padStart(2, '0');
              const startMinute = ((prevEndTimeMinutes + 30) % 60).toString().padStart(2, '0');
              startTime = `${startHour}:${startMinute}`;
            }
          }

          return { ...div, startTime, endTime };
        }

        return div;
      })
    );
  };

  const addMinutesToTime = (time, minutesToAdd) => {
    const [hour, minute] = time.split(':').map(Number);
    const totalMinutes = hour * 60 + minute + minutesToAdd;
    const newHour = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
    const newMinute = (totalMinutes % 60).toString().padStart(2, '0');
    return `${newHour}:${newMinute}`;
  };

  return (
    <div>
      {divs.map((div) => (
        <div key={div.key} className="hours_limit">
          <button onClick={() => handleRemoveDiv(div.key)} className="minus">
            -
          </button>
          <div className="hours">
            <select value={div.startTime} onChange={(e) => handleTimeChange(div.key, 'startTime', e.target.value)}>
              {generateTimeSlots()}
            </select>
            <h3>עד</h3>
            <select value={div.endTime} onChange={(e) => handleTimeChange(div.key, 'endTime', e.target.value)}>
              {generateTimeSlots()}
            </select>
          </div>
        </div>
      ))}
      <div className="pluss">
        <button onClick={handleAddDiv} className="plus">
          +
        </button>
        <h5>הוספת שעות פעילות</h5>
      </div>
    </div>
  );
}
