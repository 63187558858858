import React, { useState, useContext, useEffect } from "react";
import { auth } from '../config/Firebase'
import { signOut } from 'firebase/auth'
import "../css/Nav.css";
import image from '../imgs/Fititlogo.png'
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../Constext/AuthContext'

import TraineeSignIn from '../imgs/TraineeSignIn.png'

export function Nav() {
  // const Navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)
  const { currentUser, userRole } = useContext(AuthContext)
  const [isMobile, setIsMobile] = useState(false)

  const [hidden, setHidden] = useState(false);

  // Update the loggedIn state when the component mounts
  useEffect(() => {
    setLoggedIn(currentUser ? true : false)
  }, [currentUser])

  // const logOut = async () => {
  //   setIsMobile(!isMobile)
  //   try {
  //     await signOut(auth)
  //     setLoggedIn(false)
  //     localStorage.setItem('user', JSON.stringify(null)); // Update the currentUser value in local storage
  //     Navigate('/')
  //     console.log('signed Out!')
  //     console.log(setLoggedIn, currentUser)
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <nav lang="he" className={hidden ? 'hidden' : ''}>
      <Link to="/" className="logo active">
        <img src={image} alt="Fitit logo"/>
      </Link>
      <button onClick={() => setIsMobile(!isMobile)} className={isMobile ? 'xmenu' : "mobileMenu"}>
        <div className="fri1" />
        <div className="fri2" />
        <div className="fri3" />
      </button>
      <ul className={isMobile ? "x1080" : "noburger"} >
        {!isMobile &&
      <li><NavLink to="/Blog" onClick={() => setIsMobile(!isMobile)}>בלוג</NavLink></li>
      }
        <li><NavLink to="/About" onClick={() => setIsMobile(!isMobile)}>עלינו</NavLink></li>
        {!loggedIn ? (
          <li>
            <NavLink to="/signin" onClick={() => setIsMobile(!isMobile)} activeclassname ="active">כניסת מאמנים</NavLink>
          </li>
        ) : (
          <li>
          {userRole === 'Trainer' ? (
            <NavLink to="/profile" onClick={() => setIsMobile(!isMobile)} activeclassname ="active">פרופיל</NavLink>
          ) : (
            <NavLink to="/TraineeProfile" onClick={() => setIsMobile(!isMobile)} activeclassname ="active">פרופיל</NavLink>
          )}
        </li>
        )}
        <li><NavLink exact="true" to="/" onClick={() => setIsMobile(!isMobile)} activeclassname ="active">חיפוש מאמני כושר</NavLink></li>
        {/* {!loggedIn ? null : <li><button onClick={logOut} lang='he'>יציאה</button></li>} */}
      
      {!loggedIn &&
      <button  className='traineeSignInBtn'>
      <NavLink to="/signinTrainee" 
      onClick={() => setIsMobile(!isMobile)}
     style={{color:'#414447'}}
     className='active'
      >כניסת מתאמנים</NavLink>
        <img src={TraineeSignIn}/>
      </button>
      }
      </ul>

    </nav>
  )
}
