import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Article } from '../Componenets/Article';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/Firebase';

import '../css/Blog.css'

export const Blog = () => {
  const [articleId, setArticleId] = useState(0);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Blog'));
        const fetchedArticles = querySnapshot.docs.map((doc) => doc.data());
        setArticles(fetchedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  // console.log(articles);

  const handleArticleClick = (index) => {
    setArticleId(index);
  };

  const handleBlogHeadingClick = () => {
    setArticleId(0);
  };

  return (
    <div className='blog' lang='he' dir='rtl'>
      <div className='blog_cho'>
        <Link to="/Blog" onClick={handleBlogHeadingClick}>
          <h1>הבלוג שלנו</h1>
        </Link>
        <ul className='blog_list'>
          {articles.map((article) => (
            <li key={article.id}>
              <Link
                to={`/Blog/${article.id}`}
                onClick={() => handleArticleClick(article.id)}
                style={{ fontWeight: '600' }}
              >
                {article.title}
                <p>נכתב על ידי: {article.author}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Article articleId={articleId} articles={articles} />
    </div>
  );
};
