import React, { useState, useRef, useReducer, useEffect } from 'react';
import { db, auth } from "../config/Firebase";
import { addDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import '../css/Calendar.css';
import { useNavigate, useParams } from 'react-router-dom';

import { daysOfWeekHebrew, monthsHebrew } from '../Constext/MonthNDays';
import { fitnessTypeOptions } from '../Constext/filters';
import { Loading } from './Loading'

import arrowDown from '../imgs/arrowDown.png';

function reducer(state, action) {
  switch (action.type) {
    case 'SWIPE_START':
      return { ...state, initialTouchX: action.payload };
    case 'SWIPE_MOVE':
      const diffX = state.initialTouchX - action.payload;
      let direction = null;
      if (Math.abs(diffX) > 50) { // 50 is your threshold
        direction = diffX > 0 ? 'left' : 'right';
      }
      return { ...state, swipeDirection: direction };
    case 'SWIPE_END':
      return initialState; // reset to initial state on end
    default:
      throw new Error();
  }
}

const initialState = {
  initialTouchX: 0,
  swipeDirection: null,
};

export const Calendar = ({ scheduleData, trainerData }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [trainee, setTrainee] = useState(null)
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState(60);
  const [durationOptions, setDurationOptions] = useState(['--']);
  const navigate = useNavigate();
  const { url } = useParams();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in, call fetchTrainee
        fetchTrainee(user.uid);
      } else {
        // No user is signed in
      }
    });
  
    // Cleanup function to unsubscribe from the onAuthStateChanged listener
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (scheduleData && scheduleData.trainingDuration) {
      setDurationOptions(scheduleData.trainingDuration);
      setDuration(scheduleData.trainingDuration[0])
    }
  }, [scheduleData]);
  
  
  const fetchTrainee = async (uid) => {
    const q = query(collection(db, 'Trainees'), where('userId', '==', uid));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      // Do something with trainee details
      const traineeDetails = querySnapshot.docs[0].data();
      setTrainee(traineeDetails)
    }
  };
  

  const handleChangeMonth = (event) => {
    const month = event.target.value;
    const newDate = new Date(selectedDate.getFullYear(), month, 1);
    setSelectedDate(newDate);
  };

  const handleSwipeStart = (event) => {
    dispatch({ type: 'SWIPE_START', payload: event.touches[0].clientX });
  };

  const handleSwipeMove = (event) => {
    dispatch({ type: 'SWIPE_MOVE', payload: event.touches[0].clientX });
  };

  const handleSwipeEnd = () => {
    if (state.swipeDirection === 'right') {
      const newDate = new Date(selectedDate);
      newDate.setDate(selectedDate.getDate() + 7);
      setSelectedDate(newDate);
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.add('swipe-right'); // Add class to the week-dates div
      }
    } else if (state.swipeDirection === 'left') {
      const newDate = new Date(selectedDate);
      newDate.setDate(selectedDate.getDate() - 7);
      setSelectedDate(newDate);
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.add('swipe-left'); // Add class to the week-dates div
      }
    }

    // Clear the added class after the transition duration
    setTimeout(() => {
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.remove('swipe-right', 'swipe-left');
      }
    }, 300);
    dispatch({ type: 'SWIPE_END' });
  };

  const handleSelectDate = (date) => {
    setSelectedDate(date);
    setStartTime(null)
  };

  const renderWeekDates = () => {
    const startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - selectedDate.getDay());

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      const isSelected = date.toDateString() === selectedDate.toDateString();

      dates.push(
        <div
          key={i}
          className={`date ${isSelected ? 'selected' : ''}`}
          onClick={() => handleSelectDate(date)}
        >
          <div className="day-name">{daysOfWeekHebrew[date.getDay()]}</div>
          <div className="day-date">{date.getDate()}</div>
        </div>
      );
    }

    return dates;
  };

  function getEndMonth(date, scheduleInAdvance) {
    const endMonth = new Date(date);
    endMonth.setDate(endMonth.getDate() + scheduleInAdvance);
    return endMonth.getMonth();
  }

  const endMonth = scheduleData ? getEndMonth(new Date(), scheduleData.scheduleInAdvance) : new Date().getMonth();

  const handleCreateSession = async () => {
    if (!selectedDate || !trainee || !startTime || !endTime) {
      // Show an error message to the user
      alert('בדקתם שאתם מחוברים עם חשבון מתאמנים? ');
      // Redirect to the sign-in page and include the current URL as a query parameter
      const currentUrl = window.location.href;
      window.location.href = `/signinTrainee?redirect=${encodeURIComponent(currentUrl)}`;
      return;
    }
  
    // Convert date and time strings to Date objects as previously shown
    // Check if there are any previous sessions between the trainer and the trainee
    const previousSessionsQuery = query(
      collection(db, "sessions"),
      where("trainee.id", "==", trainee.userId),
      where("trainer.id", "==", trainerData.userId)
    );
    
    try {
      const previousSessionsSnapshot = await getDocs(previousSessionsQuery);
      let sessionStatus;
   
      if (!previousSessionsSnapshot.empty && scheduleData.approveOrNot == 'כן') {
        // There are previous sessions between the trainer and the trainee
        sessionStatus = "Upcoming";
      } else {
        // No previous sessions found, set the session status to "Pending"
        sessionStatus = "Pending";
      }
  
      const sessionData = {
        trainer: {
          id: trainerData.userId,
          fName: trainerData.fName,
          lName: trainerData.lName,
        },
        trainee: {
          id: trainee.userId, // Get the trainee's id
          fName: trainee.fName, // Get the trainee's first name
          lName: trainee.lName, // Get the trainee's last name
        },
        sessionDate: selectedDate.toISOString().split("T")[0],
        startTime: startTime,
        endTime: endTime,
        sessionDuration: duration,
        sessionStatus: sessionStatus,
        createdAt: new Date().toISOString(),
        approveOrNot: scheduleData.approveOrNot, 
        // Add the rest of your session data here (sessionType, location, additionalInfo, etc.)
      };
  
      const sessionRef = collection(db, "sessions");
      const docRef = await addDoc(sessionRef, sessionData);
  
      // Update the doc to set its id as a field
      await updateDoc(docRef, { id: docRef.id });
  
      // Navigate to the new route with sessionData as state
      navigate(`/${url}/Schedule/SessionConfirmation`, { state: { sessionData } });
  
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div
      className="calendar"
      lang="he"
      dir="rtl"
      ref={calendarRef}
    >
      <div className="header">
        <div className="select-wrapper">

        <select value={selectedDate.getMonth()} onChange={handleChangeMonth}>
        {Array.from({ length: 12 }, (_, month) => (
          <option key={month} value={month} disabled={month < new Date().getMonth() || month > endMonth}>
            {monthsHebrew[month]}
          </option>
        ))}
      </select>

          <img src={arrowDown} alt="arrow" />
        </div>
      </div>
      
      <div className="week-dates" ref={calendarRef}
           onTouchStart={handleSwipeStart}
           onTouchMove={handleSwipeMove}
           onTouchEnd={handleSwipeEnd}>
        {renderWeekDates()}
      </div>

    <div className='times_head'>
      <div className="select-wrapper">
        <h2>אורך האימון</h2>
        <select value={duration} onChange={(e) => {
            setDuration(Number(e.target.value))
        }}
        className='select-duration'>
            {durationOptions.map((durationOption) => (
                <option key={durationOption} value={durationOption}>
                    {durationOption}
                </option>
            ))}
        </select>
    </div>
    </div>

      {/* <div className='times_head'>
        <div className="select-wrapper">  
            <h2>סוג אימון</h2>  
        <select>
            {fitnessTypeOptions.map((option) => (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
            ))}
        </select>
        <img src={arrowDown} alt="arrow" />
        </div>  

        <h2 style={{ fontWeight: 'bold' }}>אישי</h2>
    </div> */}

      <div className="time-slots-container">
      {scheduleData ? 
      <TimeSlots
      date={selectedDate}
      scheduleData={scheduleData}
      trainerData={trainerData}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      duration={duration}
      />
      : <Loading/>}
      </div>
        {scheduleData && startTime &&
              <div className='btn_div'>
                <button className='new_training' onClick={handleCreateSession}>בואו נקבע!</button>
              </div>
        }
    </div>
  );
}

export const TimeSlots = ({ date, scheduleData, trainerData, setStartTime, setEndTime, duration }) => {
  const [slotDurationMinutes, setSlotDurationMinutes] = useState(60) ; // Slot duration in minutes
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [existingSessions, setExistingSessions] = useState([]);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    // Reset selectedTimeSlot when date changes
    setSelectedTimeSlot(null);
  }, [date]);

  useEffect(() => {
    const fetchExistingSessions = async () => {
      // Format date to YYYY-MM-DD
      setLoading(true); 
      const formattedDate = date.toISOString().split("T")[0];
  
      const q = query(
        collection(db, 'sessions'), 
        where('trainer.id', '==', trainerData.userId),
        where('sessionDate', '==', formattedDate),
        where('sessionStatus', 'in', ['Upcoming', 'Pending', 'Done'])
      );
  
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const sessions = querySnapshot.docs.map(doc => doc.data());
        setExistingSessions(sessions);
      }
      setLoading(false); 
    };
  
    fetchExistingSessions();
  }, [date, trainerData.userId]);

  useEffect(() => {
    setSlotDurationMinutes(duration);
    setSelectedTimeSlot(null);
  }, [duration]);
  
  const handleSelectTimeSlot = (startTime) => {
    setSelectedTimeSlot(startTime);
  
    // Calculate endTime based on selectedTimeSlot and slotDurationMinutes
    let endTimeHour = parseInt(startTime.split(':')[0], 10) + Math.floor(slotDurationMinutes / 60);
    let endTimeMinute = parseInt(startTime.split(':')[1], 10) + (slotDurationMinutes % 60);
    
    // Check if endTimeMinute exceeds 60
    if (endTimeMinute >= 60) {
      endTimeHour++;
      endTimeMinute -= 60;
    }
  
    const endTime = `${endTimeHour.toString().padStart(2, '0')}:${endTimeMinute.toString().padStart(2, '0')}`;
  
    setStartTime(startTime);
    setEndTime(endTime);
  };
  
  const isTimeSlotOverlapping = (startTime, endTime, existingSessions) => {
    return existingSessions.some(session => {
      const existingStartTime = session.startTime;
      const existingEndTime = session.endTime;
  
      // Convert times to minutes for easier comparison
      const [startHour, startMinute] = startTime.split(':').map(Number);
      const [endHour, endMinute] = endTime.split(':').map(Number);
      const [existingStartHour, existingStartMinute] = existingStartTime.split(':').map(Number);
      const [existingEndHour, existingEndMinute] = existingEndTime.split(':').map(Number);
  
      const startInMinutes = startHour * 60 + startMinute;
      const endInMinutes = endHour * 60 + endMinute;
      const existingStartInMinutes = existingStartHour * 60 + existingStartMinute;
      const existingEndInMinutes = existingEndHour * 60 + existingEndMinute;
  
      // Check if the new time slot overlaps with the existing time slot
      return (
        (startInMinutes >= existingStartInMinutes && startInMinutes < existingEndInMinutes) ||
        (endInMinutes > existingStartInMinutes && endInMinutes <= existingEndInMinutes) ||
        (startInMinutes <= existingStartInMinutes && endInMinutes >= existingEndInMinutes)
      );
    });
  };
  
  const renderTimeSlotItems = () => {
    const slots = [];
    const day = date.toISOString().split("T")[0]; // Format date to YYYY-MM-DD
    const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getDay()];

    const timesForDay = scheduleData && scheduleData.workingTime && (scheduleData.workingTime[day] || scheduleData.workingTime[dayOfWeek]) || [];
    const workingHours = timesForDay.length > 0 ? timesForDay[0] : null; // Assuming there's only one item per day

    // console.log(scheduleData.workingTime[day])
    
      // Check if the day is a holiday or not a working day
      if (scheduleData.workingTime[day] && scheduleData.workingTime[day][0].isHoliday) {
    return (
      <div className="no-training">
        <p>היום {trainerData.fName} ביום מנוחה🧘‍♂️, רוצים לחפש יום אחר?</p>
      </div>
    );
  }

  if (workingHours) {
    const startHour = parseInt(workingHours.startTime.split(':')[0], 10);
    const startMinute = parseInt(workingHours.startTime.split(':')[1], 10);
    const endHour = parseInt(workingHours.endTime.split(':')[0], 10);
    const endMinute = parseInt(workingHours.endTime.split(':')[1], 10);
  
    // Set the current time to the beginning of the working hours
    let currentTimeHour = startHour;
    let currentTimeMinute = startMinute;
  
    while (
      currentTimeHour < endHour ||
      (currentTimeHour === endHour && currentTimeMinute < endMinute)
    ) {
      const currentTime = `${currentTimeHour.toString().padStart(2, '0')}:${currentTimeMinute.toString().padStart(2, '0')}`;
      
      // Calculate end time for the current time slot
      let endTimeMinute = currentTimeMinute + slotDurationMinutes;
      let endTimeHour = currentTimeHour;
      if (endTimeMinute >= 60) {
        endTimeHour++;
        endTimeMinute -= 60;
      }
      const endTime = `${endTimeHour.toString().padStart(2, '0')}:${endTimeMinute.toString().padStart(2, '0')}`;
  
      // Check if this time slot overlaps with any existing sessions
      const isTaken = isTimeSlotOverlapping(currentTime, endTime, existingSessions);
  
      if (!isTaken) {
          // Calculate end time for the current time slot
          let endTimeMinute = currentTimeMinute + slotDurationMinutes;
          let endTimeHour = currentTimeHour;
          if (endTimeMinute >= 60) {
            endTimeHour++;
            endTimeMinute -= 60;
          }
          const endTime = `${endTimeHour.toString().padStart(2, '0')}:${endTimeMinute.toString().padStart(2, '0')}`;

          const isSelected = currentTime === selectedTimeSlot;

          if (loading) {
            return <Loading/>; // render a loading component or any other loading indicator
          }
          slots.push(
            <div
              key={currentTime}
              className={`time-slot-item ${isSelected ? 'selected' : ''}`}
              onClick={() => handleSelectTimeSlot(currentTime)}
            >
              <h2>אימון עם {trainerData.fName}</h2>
              <div className="hours">
                <h3>{currentTime}</h3>
                <h3>עד</h3>
                <h3>{endTime}</h3>
              </div>
            </div>
          );
        }

        // Increase current time by minimum slot duration (e.g., 30 minutes)
    currentTimeMinute += 30; // you can define this value as a constant
    if (currentTimeMinute >= 60) {
      currentTimeHour++;
      currentTimeMinute -= 60;
    }
  }
    } else {
      // if there are no working hours for the day, show a message
      return (
        <div className="no-training">
          <p> היום {trainerData.fName} ביום מנוחה🧘‍♂️, רוצים לחפש יום אחר?</p>
        </div>
      );
    }

    return slots;
  };

  useEffect(() => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + scheduleData.scheduleInAdvance);
    if (date > maxDate) {
      setErrorMessage(`אפשר לקבוע עם ${trainerData.fName} רק ${scheduleData.scheduleInAdvance} ימים מראש 🫣, אולי ננסה מועד מוקדם יותר?`);
    } else {
      setErrorMessage(null);
    }
  }, [date, scheduleData.scheduleInAdvance]);

  return (
    <div className="time-slots">
      {errorMessage ? <p className="error-message">{errorMessage}</p> : renderTimeSlotItems()}
    </div>
  );
};
