import '../css/About.css';
import fitit from '../imgs/Fititlogo.png'
import { Helmet } from 'react-helmet';

export const About = () =>{


return(
    <div className="mid" lang='none'>

    <Helmet>
        <title>על Fitit</title>
        <meta name='description'
        content='חפשו מאמני כושר בקלות בעזרת Fitit. בתור מאמני כושר תוכלו לבנות פרופיל אישי, לייצר תאר אישי ולמצוא מתאמנים חדשים. '/>
    </Helmet>

       <h1 className='about_h1'> <img src={fitit} alt="Fitit logo"/></h1>
        
        <h1>מתאמנים</h1>
        <h2 className='about_h2'>
        כאן תוכלו לחפש מאמני כושר שיענו על הצרכים שלכם -
        מיקום, אימונים אישיים או קבוצתיים וכמובן סוגי הכושר השונים המתאימים לכם.
        <br/>
        </h2>

        <h3 className='about_h3'>
        <u><a href='https://fitit.app'> מצאו את המאמן/ת הבאים שלכם</a></u>
        </h3>


        <h1>מאמנים</h1>

        <h2 className='about_h2'>
        כמאמני כושר תוכלו לעלות פרופיל <b> לגמרי בחינם </b>
        לאתר ולהופיע בתוצאות החיפוש למתאמנים פוטנציאליים. 
        כמו כן תהנו גם מאתר אישי שתוכלו לפרסם או לשלוח כקישור אשר יכיל את כל הפרטים החשובים לגביכם.
        </h2>

        <h3 className='about_h3'>
         <u><a href='https://fitit.app/signin'> צרו פרופיל בקלות</a></u>
        </h3>

        <h3 className='about_h3'>
        <a href='mailto:fitit.app.mail@gmail.com' target='blank'>
        לשאלות ויצירת קשר</a>
        </h3>
        
    </div>

)}