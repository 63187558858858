import { useState } from 'react';

import dash from '../imgs/Dash.png';
import adminEdit from '../imgs/adminEdit.png';
import bell from '../imgs/bell.png';
import sett from '../imgs/configuration.png';
import help from '../imgs/help.png';

export const AdminMenu = ({ handleMenuClick }) => {
  const [activeItem, setActiveItem] = useState('general');

  const handleItemClick = (item) => {
    setActiveItem(item);
    handleMenuClick(item);
  };

  return (
    <div className="admin_menu">
      <ul className="menu_options">
        <li
          className={activeItem === 'general' ? 'open' : ''}
          onClick={() => handleItemClick('general')}
        >
          <span>כללי</span>
          <img src={dash} alt="Dash" />
        </li>
        <li
          className={activeItem === 'users' ? 'open' : ''}
          onClick={() => handleItemClick('users')}
        >
          <span>משתמשים</span>
          <img src={adminEdit} />
        </li>
        <li
          className={activeItem === 'notifications' ? 'open' : ''}
          onClick={() => handleItemClick('notifications')}
        >
          <span>התראות</span>
          <img src={bell} />
        </li>
        <li
          className={activeItem === 'settings' ? 'open' : ''}
          onClick={() => handleItemClick('settings')}
        >
          <span>הגדרות</span>
          <img src={sett} />
        </li>
        <li
          className={activeItem === 'help' ? 'open' : ''}
          onClick={() => handleItemClick('help')}
        >
          <span>עזרה</span>
          <img src={help} />
        </li>
      </ul>
    </div>
  );
};
