import { useEffect, useState } from 'react'
//firebase
import { db } from '../config/Firebase'
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
//lottie animation
import { Loading } from './Loading';
//profile logos
import instagramLogo from '../imgs/instagram.png'
import location from '../imgs/location-pin.png'
import phone from '../imgs/call.png'
import strong from '../imgs/strong.png'
import calendar from '../imgs/calendar.png'
//profile defult img
import d_profile from '../imgs/Fitit_profile.png'

export function TrainerProfile({ onBackClick, trainerId }){

    const [trainer, setTrainer] = useState(null);
    const [imgUrl, setImgUrl] = useState("");
    const [personalUrl, setPersonalUrl] = useState(null); // State for personal website URL

    useEffect(() => {
      const fetchTrainer = async () => {
        const docRef = doc(db, "Trainers", trainerId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const trainerData = { id: docSnap.id, ...docSnap.data() };
          setTrainer(trainerData);
  
          // Query the personal website using the userId
          const q = query(collection(db, 'PersonalWebsites'), where('userId', '==', trainerData.userId));
          const querySnapshot = await getDocs(q);
  
          if (!querySnapshot.empty) {
            const websiteData = querySnapshot.docs[0].data();
            setPersonalUrl(websiteData.url); // Assuming the URL is stored under 'url'
          }
        } else {
          console.log("No such document!");
        }
      };
  
      fetchTrainer();
    }, [trainerId]);

    useEffect(() => {
        const getImageUrl = async () => {
            if (trainer && trainer.ImageUrl) {
                const storageRef = ref(getStorage(), trainer.ImageUrl);
                try {
                    const url = await getDownloadURL(storageRef);
                    setImgUrl(url);
                } catch (error) {
                    console.error(error);
                }
            } else {
                setImgUrl(""); // Clear the imgUrl state if trainer doesn't have an image
            }
        };

        getImageUrl();
    }, [trainer]);

    if (!trainer) {
        return <Loading/>;
    }

    const instagramUsername = trainer.Instagram.substring(trainer.Instagram.lastIndexOf('/') + 1);
    const formattedInstagram = `@${instagramUsername}`;

    const formatPhoneNumber = (phoneNumber) => {
      // Remove any non-digit characters from the phone number
      const cleaned = phoneNumber.replace(/\D/g, '');
  
      // Format the cleaned number into the desired template
      const template = '$1-$2-$3';
      return cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, template);
    };

    return (
        <div className="profile">
          <button onClick={onBackClick} className="backbtn">
            חזור &#8594;
          </button>

          <div className="namenphoto">
            <div>
            <div className="circle">
              {imgUrl ? (
                <img src={imgUrl} alt={`${trainer.fName} ${trainer.lName}`} />
              ) : (
                <img
                    src={d_profile}
                    alt={`${trainer.fName} ${trainer.lName}`}
                  />
              )}
            </div>
            <h1>{trainer.fName} {trainer.lName}</h1>
          </div>

           {trainer.canSced && 
           <button>
             <a href={personalUrl + "/schedule"} rel="noopener noreferrer"
              style={{color: '#647dee', fontWeight: '600', textDecoration:'none'}}>
              קביעת אימונים
              </a>
            <img src={calendar} alt='קביעת אימונים'/>
            </button>
            }

          </div>

          <p className="trainerdesc" dir='rtl'>{trainer.Description}</p>
          <h2>סוגי אימונים</h2>
          <p className="training" dir='rtl'>
            {trainer.Type && trainer.Type.length > 0 ? (
                trainer.Type.map((type, index) => (
                <span key={type}>
                    {type}
                    {index !== trainer.Type.length - 1 ? ', ' : ''}
                </span>
                ))
            ) : (
                <span> &#128549; אין סוגי אימונים זמינים</span>
            )}
            </p>


          <ul>
            <li>
            <img src={location} alt="Location" />
                {trainer.Address}</li>
            
            <li>
            <a href={`tel:${trainer.Phone}`} target='blank'>
                  <img src={phone} alt="Phone"/></a>
                <a href={`tel:${trainer.Phone}`} className='a_bold'> 
                {formatPhoneNumber(trainer.Phone)}</a>
            </li>

            <li>
          <img src={strong} alt="types" />
          {trainer.Personal && trainer.Group ? "אישי | קבוצתי" : (trainer.Personal ? "אישי" : (trainer.Group ? "קבוצתי" : ""))}
          </li>

              {trainer.Instagram && 
          <li lang="en">
          <a href={`https://${trainer.Instagram}`} target="_blank" rel="noopener noreferrer" >
            {trainer.Instagram && <img src={instagramLogo} alt="Instagram" />}
          </a>
          <a href={`https://${trainer.Instagram}`} target="_blank" rel="noopener noreferrer" className='a_bold' >
            {trainer.Instagram && formattedInstagram}
          </a>
        </li>}

          </ul>


          {personalUrl && (
        <h3 style={{ textAlign: 'right', marginBottom:'0px'}}>
          <a href={personalUrl} rel="noopener noreferrer"
           style={{color: '#647dee', fontWeight: '600', textDecoration:'none'}}>
            לאתר האישי של {trainer.fName}
          </a>
        </h3>
      )}
      
        </div>
      );
}      
