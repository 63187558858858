import { useState, useEffect } from 'react';

//comps
import { TrainerList } from "./TrainerList"
import { TrainerProfile } from "./TrainerProfile"
import { Filter } from './Filter';
import { Map } from './Map'

//google maps
import { useLoadScript } from '@react-google-maps/api';
import { libraries } from '../config/GoogleMaps'

export function Search() {
  const [originalTrainers, setOriginalTrainers] = useState([]); // Store the original list of trainers
  const [filteredTrainers, setFilteredTrainers] = useState([]); // Store the filtered list of trainers
  const [selectedTrainerId, setSelectedTrainerId] = useState(null);
  const [selectedTrainerPosition, setSelectedTrainerPosition] = useState(null); // Define selectedTrainerPosition
  const [filters, setFilters] = useState({}); // State to store the filters

  const [pressed, setPressed] = useState(
    localStorage.getItem('pressed') === 'true' || false
  );
  const [ismap, setIsMap] = useState(
    localStorage.getItem('ismap') === 'true' || false
  );

  // Restoring original trainers when pressed state changes
  useEffect(() => {
    if (!pressed) {
      setFilteredTrainers(originalTrainers);
    }
  }, [pressed, originalTrainers]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
    language: 'iw', // Specify the desired language code
    region: 'IL', // Specify the desired region code
  });


  const handleBackClick = () => {
    setPressed(false);
  };

  const handleClick = (trainerId) => {
    const selectedTrainer = filteredTrainers.find(trainer => trainer.id === trainerId);
    if (selectedTrainer && selectedTrainer.Geo && selectedTrainer.Geo.Lat && selectedTrainer.Geo.Lng)  {
      setSelectedTrainerPosition({ // Update selectedTrainerPosition
        lat: Number(selectedTrainer.Geo.Lat),
        lng: Number(selectedTrainer.Geo.Lng)
      });
    }
    setSelectedTrainerId(trainerId);
    if (window.innerWidth >= 768) {
      setPressed(true);
    }
  };

  const handleBoxClick = (trainerId) => {
    const selectedTrainer = filteredTrainers.find(trainer => trainer.id === trainerId);
    if (selectedTrainer && selectedTrainer.Geo && selectedTrainer.Geo.Lat && selectedTrainer.Geo.Lng)  {
      setSelectedTrainerPosition({ // Update selectedTrainerPosition
        lat: Number(selectedTrainer.Geo.Lat),
        lng: Number(selectedTrainer.Geo.Lng)
      });
    }
    setSelectedTrainerId(trainerId);
    setPressed(true);
    setIsMap(false);
  };

  const map = (mapActive) => {
    setTimeout(() => {
      setIsMap(mapActive);
    }, 100);
  };

  const handleTrainerLoad = (updateTrainers) => {
    setOriginalTrainers(updateTrainers); // Update the original trainers list
    setFilteredTrainers(updateTrainers); // Update the filtered trainers list
  };

  // Pass the filters to getTrainersList
const handleFilterChange = (filters) => {
  setFilters(filters);
};
  
  return (
    <>
      <div className={`col-1 ${ismap ? 'ActiveMap' : 'hidemap'}`}>
        <Map
          trainers={filteredTrainers}
          selectedTrainerId={selectedTrainerId}
          selectedTrainerPosition={selectedTrainerPosition} // Pass selectedTrainerPosition
          handleClick={handleClick}
          profileClick={handleBoxClick}
          isLoaded={isLoaded}
          filters={filters} />
      </div>

      <div className={`col-2 ${ismap ? 'Map' : ''}`}>
        {pressed ? (
          <TrainerProfile
            onBackClick={handleBackClick}
            trainerId={selectedTrainerId} />
        ) : (
          <div className='trainer_search'>
            <Filter
            onFilterChange={handleFilterChange}
             handleMapChange={map}
             isLoaded={isLoaded}
             setSelectedTrainerId={setSelectedTrainerId}
              />
            <TrainerList
              profileClick={handleBoxClick}
              trainerId={selectedTrainerId}
              onTrainerLoad={handleTrainerLoad}
              trainers={filteredTrainers}
              mapActive={ismap}
              filters={filters}
            />
          </div>
        )}
      </div>
    </>
  );
}
