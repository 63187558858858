import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../css/Tooltip.css';
import info from '../imgs/info.png'

const Tooltip = ({ message, className }) => {
  const [showMessage, setShowMessage] = useState(false);

  const handleTooltipClick = () => {
    setShowMessage(true);
  };

  const handleCloseClick = (e) => {
    e.stopPropagation(); // Prevents triggering the tooltip click when closing
    setShowMessage(false);
  };

  return (
    <div className={`tooltip-container ${className}`} onClick={handleTooltipClick}>
      <img src={info} alt='info' className="tooltip-text"/>
      {showMessage && (
        <div className="tooltip-message">
          <p>{message}</p>
          <button onClick={handleCloseClick} className="close-button">X</button>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Tooltip;

