import { useEffect, useState } from 'react';
//firebase
import { db } from '../config/Firebase';
import { getDocs, collection, query, where,
orderBy, startAt, endAt, limit } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

//geohash
import { geohashQueryBounds, distanceBetween } from 'geofire-common';

//lootie
import { Loading } from './Loading';

//profile defult img
import d_profile from '../imgs/Fitit_profile.png'

export function TrainerList({ profileClick, onTrainerLoad, trainers, mapActive, filters }) {
  const trainersCollectionRef = collection(db, 'Trainers');
  const [loading, setLoading] = useState(true);
  const storage = getStorage();

  const getTrainersList = async () => {
    try {
      let trainersQuery = query(trainersCollectionRef);

      // Apply filters if they exist
      if (filters && Object.keys(filters).length > 0) {

        if (filters.FitnessType) {
          trainersQuery = query(
            trainersQuery,
            where('Type', 'array-contains', filters.FitnessType)
          );
        }
        if (filters.trainingTypePersonal) {
          trainersQuery = query(
            trainersQuery,
            where('Personal', '==', filters.trainingTypePersonal)
          );
        }
        if (filters.trainingTypeGroup) {
          trainersQuery = query(
            trainersQuery,
            where('Group', '==', filters.trainingTypeGroup)
          );
        }
        

        // Apply location filter
        if (filters.hash && filters.distance) {
          const lat = filters.geo[0].value;
          const lng = filters.geo[1].value;
          const radiusInKm = filters.distance;
  
          const center = [lat, lng];
          const radiusInMeters = radiusInKm * 1000;
  
          const queryBounds = geohashQueryBounds(center, radiusInMeters);
          const queryPromises = [];
  
          for (const bound of queryBounds) {
            const q = query(
              trainersQuery,
              orderBy('GeoH'),
              startAt(bound[0]),
              endAt(bound[1])
            );
            queryPromises.push(getDocs(q));
          }
  
          const querySnapshots = await Promise.all(queryPromises);
          const matchingTrainers = [];
  
          for (const snapshot of querySnapshots) {
            for (const doc of snapshot.docs) {
              const trainer = doc.data();
              const trainerLat = trainer.Geo.Lat;
              const trainerLng = trainer.Geo.Lng;
              const distanceInKm = distanceBetween([trainerLat, trainerLng], center);
              const distanceInMeters = distanceInKm * 1000;
  
              if (distanceInMeters <= radiusInMeters) {
                matchingTrainers.push(trainer);
              }
            }
          }
  
          // Update the trainersQuery with the location filter
          if (matchingTrainers.length > 0) {
            trainersQuery = query(
              trainersQuery,
              where('Geo', 'in', matchingTrainers.map(trainer => trainer.Geo))
            );
          } else {
            // Handle the case when no trainers match the location filter
            trainersQuery = query(trainersQuery, where('Geo', '==', ''));
          }
        }
      } else {
        // Load up to 10 trainers when no filters are present
        trainersQuery = query(trainersQuery, where('Present', '==', true), limit(8));
    }
      
        const data = await getDocs(trainersQuery);
        const filteredData = await Promise.all(
          data.docs.map(async (doc) => {
            const trainer = { ...doc.data(), id: doc.id };
        
            if (trainer.ImageUrl) {
              const storageRef = ref(storage, trainer.ImageUrl);
              try {
                const imageUrl = await getDownloadURL(storageRef);
                trainer.imageUrl = imageUrl;
              } catch (err) {
                console.error(err);
              }
            } else {
              trainer.imageUrl = null;
            }
        
            // Check if the trainer has all required fields
            if (
              trainer.fName &&
              trainer.lName &&
              trainer.Phone &&
              // trainer.Address
              // &&
               trainer.Present
            ) {
              return trainer;
            } else {
              return null; // Return null for trainers missing required fields
            }
          })
        );
        
        const filteredTrainers = filteredData.filter(
          (trainer) => trainer !== null
        );
        
        onTrainerLoad(filteredTrainers);
        setLoading(false);
        
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getTrainersList();
  }, [filters]); // Trigger the effect when filters change

  const handleProfileClick = (trainerId) => {
    profileClick(trainerId);
  };

  return (
    <div className={mapActive ? 'map_is_open' : 'results'}>
      {loading ? (
        <Loading />
      ) : (
        trainers.map((trainer) => (
          <div
            className="trainer_box"
            onClick={() => handleProfileClick(trainer.id)}
            key={trainer.id}
          >
            <div className="img_t">
              <div className="circle">
                {trainer.imageUrl && (
                  <img
                    src={trainer.imageUrl }
                    alt={`${trainer.fName} ${trainer.lName}`}
                  />
                )}
                {!trainer.ImageUrl && <img
                    src={d_profile}
                    alt={`${trainer.fName} ${trainer.lName}`}
                  />}
              </div>
            </div>
            <h1>
              {trainer.fName} {trainer.lName}
            </h1>
            <ul>
              <li>{trainer.Address}</li>
            </ul>
          </div>
        ))
      )}
      {!loading && <span className='more'> לטעינת תוצאות נוספות, נסו להתשמש בסנן 💪 </span>}
    </div>
  );
}
