import {useEffect, useState } from 'react'
//firebase
import {db, auth, storage} from '../config/Firebase'
import {getDocs, collection, doc, addDoc, updateDoc, query, where, Timestamp } from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'
//dom
import { useNavigate } from 'react-router-dom';
//google maps and geolocation
import { libraries } from '../config/GoogleMaps'
import { geohashForLocation } from 'geofire-common';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
//file size
import Resizer from "react-image-file-resizer";
//react select
import Select from 'react-select'
//fitness type array
import { fitnessTypeOptions } from '../Constext/filters'
//img
import d_profile from '../imgs/Fitit_profile.png'
//css
import '../css/LoggedProfile.css';
//Loadding
import { Loading } from './Loading'

export const LoggedProfileDetalis = () => {
    
    const [trainers, setTrainers] = useState([]);
    const [existingTrainer, setExistingTrainer] = useState(null);

    const Navigate = useNavigate()

//new trainers state
        const [newTrainersfName, setNewTrainersfname] = useState('')
        const [newTrainerslName, setNewTrainerslname] = useState('')
        const [newTrainersDescription, setNewTrainersDescription] = useState('ספר על עצמך ועל הניסיון שלך למתאמנים שיצפו בפרופיל שלך')
        const [newTrainersType, setNewTrainersType] = useState([]);
        const [newTrainersPhone, setNewTrainersPhone] = useState(0)
        const [newTrainersInstagram, setNewTrainersInstagram] = useState('')
        const [newTrainersAddress, setNewTrainersAddress] = useState('')
        const [newTrainersPersonal, setNewTrainersPersonal] = useState('')
        const [newTrainersGroup, setNewTrainersGroup] = useState('')
        const [newTrainersGeo, setNewTrainersGeo] = useState([{ title: 'Lat', value: null }, { title: 'Lng', value: null }])
        const [newTrainersGeoHash, setNewTrainersGeoHash ] = useState('')

        const [trainerImgUrl, setTrainerImgUrl] = useState(null)

        const [selectTrainerTypeArray, setSelectTrainerTypeArray] = useState([])
        const [fetched, setFetched] = useState(false)
        const [trainerTypeEmpty, setTrainerTypeEmpty] = useState(['not'])

        const [present, setPresent] = useState(true)

        const [showMobileExplainTrainer, setShowMobileExplainTrainer] = useState();

        useEffect(() => {
          const unsubscribe = auth.onAuthStateChanged((user) => {
              if (user) {
                  const fetchTrainer = async () => {
                      const q = query(collection(db, 'Trainers'), where('userId', '==', user.uid));
                      const querySnapshot = await getDocs(q);
      
                      if (!querySnapshot.empty) {
                          const trainer = querySnapshot.docs[0].data();
                          setExistingTrainer(trainer);
                          setNewTrainersPersonal(trainer.Personal);
                          setNewTrainersGroup(trainer.Group);
      
                          // Parse and set the existing training types
                          const fetchedFitnessTypes = trainer.Type || [];
                          const parsedFitnessTypes = fetchedFitnessTypes.map((type) => ({
                              value: type,
                              label: type,
                          }));
                          setNewTrainersDescription(trainer.Description)
                          setSelectTrainerTypeArray(parsedFitnessTypes); // Set the parsed training types
                          if (trainer.ImageUrl) {
                              const storageRef = ref(storage, trainer.ImageUrl);
                              try {
                                  const imageUrl = await getDownloadURL(storageRef);
                                  setTrainerImgUrl(imageUrl)
                              } catch (err) {
                                  console.error(err);
                              }
                          } else {
                              setTrainerImgUrl(null);
                          }
                          setFetched(true)
                      }
                  };
      
                  fetchTrainer();
              }
          });
      
          // Clean up the subscription on unmount
          return () => {
              unsubscribe();
          }
      }, []);
      
  
  useEffect(() => {
    const showExplainTrainer = localStorage.getItem("showMobileExplainTrainer");
    if (showExplainTrainer === null || showExplainTrainer === "true") {
      setShowMobileExplainTrainer(true);
    } else {
      setShowMobileExplainTrainer(false);
    }
  }, []);
  
        //files
const [fileUpload, setFileUpload] = useState(null);
        
const onSubmitTrainer = async (e) => {
  e.preventDefault(); // Prevent the default form submission behavior
            try {
              await uploadFile(); // Upload the file first
          
                  // Check if the user has already added their details to the database
    const existingTrainer = trainers.find(trainer => trainer.userId === auth?.currentUser?.uid);

    if (existingTrainer) {
      // Update the existing document with the new information
      const trainerDoc = doc(db, 'Trainers', existingTrainer.id);

      // Create an object to hold the updated values
      const updatedValues = {};
      
      // Check if the input values have been changed by the user
      if (newTrainersfName && newTrainersfName.trim() !== '' && newTrainersfName !== existingTrainer.fName) {
        updatedValues.fName = newTrainersfName;
      }

      if (newTrainerslName && newTrainerslName.trim() !== '' && newTrainerslName !== existingTrainer.lName) {
        updatedValues.lName = newTrainerslName;
      }

      if (newTrainersDescription && newTrainersDescription.trim() !== '' && newTrainersDescription !== existingTrainer.Description) {
        updatedValues.Description = newTrainersDescription;
      }

      if (trainerTypeEmpty !== undefined && trainerTypeEmpty.length === 0) {
        updatedValues.Type = []; // Set Type to an empty array if newTrainersType is equal to zero
      }  

      if (newTrainersType && newTrainersType.length > 0) {
        updatedValues.Type = newTrainersType
      }

      if (newTrainersInstagram && newTrainersInstagram.trim() !== '' && newTrainersInstagram !== existingTrainer.Instagram) {
        updatedValues.Instagram = newTrainersInstagram;
      }

      if (newTrainersPhone && newTrainersPhone.trim() !== '' && newTrainersPhone !== existingTrainer.Phone) {
        updatedValues.Phone = newTrainersPhone;
      }

      if (newTrainersAddress && newTrainersAddress.trim() !== '' && newTrainersAddress !== existingTrainer.Address) {
        updatedValues.Address = newTrainersAddress;
      }
      if (newTrainersPersonal !== existingTrainer.Personal) {
        updatedValues.Personal = newTrainersPersonal;
      }
      if (newTrainersGroup !== existingTrainer.Group) {
        updatedValues.Group = newTrainersGroup;
      }
      if (newTrainersGeoHash !== null && newTrainersGeoHash !== '' && newTrainersGeoHash !== existingTrainer.GeoH) {
        updatedValues.GeoH = newTrainersGeoHash;
      }     
      if (
        newTrainersGeo &&
        newTrainersGeo.length > 0 &&
        newTrainersGeo[0].value !== null &&
        newTrainersGeo !== existingTrainer.Geo
      ) {
        updatedValues.Geo = {
          Lat: newTrainersGeo.find((geo) => geo.title === "Lat")?.value,
          Lng: newTrainersGeo.find((geo) => geo.title === "Lng")?.value,
        };

      }
      if (fileUpload) {
        updatedValues.ImageUrl = `TrainerFiles/${auth.currentUser.uid}`;
      } else if (existingTrainer.ImageUrl) {
        updatedValues.ImageUrl = existingTrainer.ImageUrl;
      }

      if (Object.keys(updatedValues).length > 0) {
        // Only update the document if there are changes
        await updateDoc(trainerDoc, updatedValues);
      }
              } else {
                // Add a new document with the new information
                await addDoc(trainersCollectionRef, {
                  fName: newTrainersfName,
                  lName: newTrainerslName,
                  Description: newTrainersDescription,
                  Type: newTrainersType, 
                  Instagram: newTrainersInstagram,
                  Phone: newTrainersPhone,
                  Address: newTrainersAddress,
                  Personal: newTrainersPersonal,
                  Group: newTrainersGroup,
                  Geo: {
                    Lat: newTrainersGeo.find((geo) => geo.title === "Lat")?.value,
                    Lng: newTrainersGeo.find((geo) => geo.title === "Lng")?.value,
                  },
                  GeoH: newTrainersGeoHash,
                  userId: auth?.currentUser?.uid,
                  userMail: auth?.currentUser?.email,
                  ImageUrl: fileUpload ? `TrainerFiles/${auth.currentUser.uid}` : null, // Add the file URL to the database only if it's a new file
                  Present: present,
                  createdAt: Timestamp.now(),
                  role:'Trainer',
                });
              }
          
              getTrainersList();
            } catch (err) {
              console.error(err);
            }
            Navigate('/')
          };
          
        
// get trainers
    const trainersCollectionRef = collection(db, 'Trainers')

    const getTrainersList = async () => {
        //read data
        try{
            const data = await getDocs(trainersCollectionRef);
            const filteredData = data.docs.map((doc) => ({
                ...doc.data(), 
                id: doc.id,
            }));
            setTrainers(filteredData)
            // console.log({filteredData});
        }catch(err){
                console.error(err);
            }
    };

 //set trainerlist
    useEffect(() => {
        getTrainersList();
    }, []);

   //upload fiels
   const uploadFile = async () => {
    if (!fileUpload) return;
    try {
      // Resize the image before uploading
      const resizedImage = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          fileUpload,
          800, // Specify the desired width
          600, // Specify the desired height
          'JPEG', // Output format (e.g., JPEG, PNG)
          100, // Quality (0 to 100)
          0, // Rotation (0, 90, 180, or 270)
          (resizedFile) => {
            resolve(resizedFile);
          },
          'blob' // Output type ('blob' or 'base64')
        );
      });
  
      // Upload the resized file to Firebase Storage
      const fileFolderRef = ref(storage, `TrainerFiles/${auth.currentUser.uid}`);
      await uploadBytes(fileFolderRef, resizedImage);
    } catch (err) {
      console.error(err);
    }
  };

  const updateImg = (pImg) =>{
    if (!pImg) return;
    try {
    const file = pImg
    const imageUrl = URL.createObjectURL(file);
    setTrainerImgUrl(imageUrl);
    }catch(err){
    console.error(err);
    }
  }

   const handlePlaceChanged = (place) => {
    if (place && place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      const hash = geohashForLocation([lat(), lng()]);
      console.log('Address:', place.formatted_address);
      console.log('Latitude:', lat());
      console.log('Longitude:', lng());
      setNewTrainersGeo([
        {title: 'Lat', value:lat()},
        {title: 'Lng', value:lng()}
       ]); // Pass an array with lat and lng values
       setNewTrainersAddress(place.formatted_address)
       console.log(hash)
       setNewTrainersGeoHash(hash)
    }
  };

        const { isLoaded } = useLoadScript({
          googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: libraries,
          language: 'iw', // Specify the desired language code
          region: 'IL', // Specify the desired region code
          }); 
          if (!isLoaded) 
          return <Loading/>

          const closeMobileExplainTrainer = () => {
            setShowMobileExplainTrainer(false);
            localStorage.setItem("showMobileExplainTrainer", "false");
          };

          if (fetched == false) 
          return (
            <Loading/>
          )
    return (

            <form className='inputs' lang='he'>

                {showMobileExplainTrainer && 
                <div className="mobile_explain">
                  <h4>לחצו על השדות השונים על מנת לערוך אותם, זה המידע שיוצג למתאמנים שיחפשו אתכם בפלטפורמה וגם באתר האישי שלכם</h4>
                  <span className="close-button" onClick={closeMobileExplainTrainer}>x</span>
                </div>}

                <input placeholder={existingTrainer ?
                  (existingTrainer.fName 
                    && existingTrainer.fName !== undefined 
                    ? existingTrainer.fName : 'שם פרטי') : 'שם פרטי'}
                        type='text'
                        value={newTrainersfName}
                        onChange={(e) => setNewTrainersfname(e.target.value)}
                        required
                        />

                <input placeholder={existingTrainer ? 
                (existingTrainer.lName 
                  && existingTrainer.lName !== undefined 
                  ? existingTrainer.lName : 'שם משפחה') :'שם משפחה'}
                 type='text' 
                 value={newTrainerslName}
                 onChange={(e) => setNewTrainerslname(e.target.value)}/>

                <textarea 
                  placeholder={
                     'ספר על עצמך ועל הניסיון שלך למתאמנים שיצפו בפרופיל שלך'
                  }
                  value={newTrainersDescription}
                  onChange={(e) => setNewTrainersDescription(e.target.value)}
                  />

                      <Autocomplete
                      className='AUTOCOMPLETE'
                        options={{
                          types: ['address'],
                          componentRestrictions: { country: 'IL' },
                          language: 'iw' // Set the language to Hebrew
                        }}
                        onLoad={(autocomplete) => {
                          autocomplete.addListener('place_changed', () =>
                            handlePlaceChanged(autocomplete.getPlace())
                          );}}
                      >
                        <input 
                        placeholder={existingTrainer ?
                          (existingTrainer.Address 
                           && existingTrainer.Address !== undefined 
                           ? existingTrainer.Address : 'כתובת') : 'כתובת'}
                        type="text" 
                      />
                      </Autocomplete>

                <input placeholder={existingTrainer ?
                 (existingTrainer.Phone 
                    && existingTrainer.Phone !== undefined 
                    ? existingTrainer.Phone : 'טלפון') : 'טלפון'}
                    type="tel" 
                    maxLength='10' 
                    onChange={(e) => setNewTrainersPhone(e.target.value)}
                    />

                <div className='customSelect'>
                    {fetched == true && ( // Conditionally render the Select component
                      <Select
                        placeholder={'סוגי אימונים'}
                        options={fitnessTypeOptions}
                        defaultValue={selectTrainerTypeArray}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map((option) => option.value);
                          console.log('Selected Options:', selectedValues);
                          setNewTrainersType(selectedValues);
                          setTrainerTypeEmpty(selectedValues);
                        }}
                      />
                    )}
                  </div>

                <input placeholder={existingTrainer ?
                 (existingTrainer.Instagram
                  && existingTrainer.Instagram !== undefined 
                  ? existingTrainer.Instagram : 'Instagram.com/Your-Profile') : 'Instagram.com/Your-Profile'}
                    type='text' 
                    value={newTrainersInstagram}
                    onChange={(e) => setNewTrainersInstagram(e.target.value)}
                    style={{fontStyle: 'italic', fontSize: 'medium'}}/>

                  <div className="checkbox_div">
                        <button
                        lang='none'
                        onClick={(e) =>{
                        e.preventDefault();
                        setNewTrainersPersonal(!newTrainersPersonal)
                        }}
                        className={`filt_btn ${newTrainersPersonal ? 'checked': 'not_checked'}`}
                        >אימון אישי</button>

                  </div>

                  <div className="checkbox_div">
                    <button
                    lang='none'
                    onClick={(e) =>{
                    e.preventDefault();
                    setNewTrainersGroup(!newTrainersGroup)
                    }}
                    className={`filt_btn ${newTrainersGroup ? 'checked': 'not_checked'}`}
                    >אימון קבוצתי</button>

                  </div>

            <div className="img_t">
                <div className='upload_file'>

                <label htmlFor='fileupload'>לחץ כאן כדי לעלות תמונה</label>
                <input type='file' id='fileUpload' onChange={(e) => { setFileUpload(e.target.files[0]); updateImg(e.target.files[0]);}} className='hiddenbtn'/>

                </div>
                
              <div className="circle">
                {fetched == true && trainerImgUrl != null && (
                  <img
                    src={trainerImgUrl}
                    alt={`${existingTrainer.fName} ${existingTrainer.lName}`}
                  />
                )}
                {fetched == true && trainerImgUrl == null && <img
                    src={d_profile}
                    alt={`${existingTrainer.fName} ${existingTrainer.lName}`}
                  />}
              </div>
            </div> 

                <button type='submit' onClick={onSubmitTrainer} className='profilebtn'>עדכן פרטים</button>
            </form>
    )
}