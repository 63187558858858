import React from 'react';
import { useRef } from 'react';
import '../css/LoggedProfile.css';

import {db, auth, storage} from '../config/Firebase'
import {useEffect, useState } from 'react'
import { doc } from 'firebase/firestore';
import {getDocs, collection, addDoc, updateDoc, query, where} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'

//file size
import Resizer from "react-image-file-resizer";

//img
import d_profile from '../imgs/Fitit_profile.png'

export const LoggedProfileElse = () => {
  const [uniqueUrl, setUrl] = useState('');
  const [urlTaken, setUrlTaken] = useState(false); // State to track if the URL is taken
  const [showTrainerBtn, setShowTrainerBtn] = useState(false)
  const [existingTrainer, setExistingTrainer] = useState(null)
  const [existingWeb, setExistingWeb] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [letSced, setLetSced] = useState(false)
  const inputRef = useRef();

const [trainerImgBackUrl, setTrainerImgBackUrl] = useState(null)
          //files
const [fileUpload, setFileUpload] = useState(null);
const [fetched, setFetched] = useState(false)


  useEffect(() => {
    const fetchTrainer = async () => {
      if (auth.currentUser) {
        const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
        const p = query(collection(db, 'PersonalWebsites'), where('userId', '==', auth.currentUser.uid));
        const querySnapshot = await getDocs(q);
        const querySnapshot2 = await getDocs(p);
  
        if (!querySnapshot.empty) {
          const trainer = querySnapshot.docs[0].data();
          setExistingTrainer(trainer);
          if (trainer.ImageUrlBack) {
            const storageRef = ref(storage, trainer.ImageUrlBack);
            try {
              const imageUrl = await getDownloadURL(storageRef);
              setTrainerImgBackUrl(imageUrl) 
            } catch (err) {
              console.error(err);
            }
          } else {
            setTrainerImgBackUrl(null);
          }
        }
        if (!querySnapshot2.empty) {
          const web = querySnapshot2.docs[0].data();
          setExistingWeb(web);
        }
        setFetched(true)
        setIsLoading(false);
      }
    };
    fetchTrainer();
  }, []);  

  const checkUrlAvailability = async () => {
    const url = inputRef.current.value;
    
    if (url !== '') {
      const querySnapshot = await getDocs(
        query(collection(db, 'PersonalWebsites'), where('lowerUrl', '==', url.toLowerCase()))
      );
  
      if (querySnapshot.empty) {
        setUrl(url);
        setUrlTaken(false); // Reset the URL taken state
        setShowTrainerBtn(true);
      } else {
        setUrlTaken(true); // URL is already taken
        setShowTrainerBtn(false);
      }
    }
  };

  const copyTrainer = async () => {
    if (!uniqueUrl) {
      console.log('URL is not set');
      // Handle the case when the URL is not set
      return;
    }

    if (auth.currentUser) {
      const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);

      // Copy each trainer to the new collection
      querySnapshot.forEach(async (docSnapshot) => {
        const trainer = docSnapshot.data();

        // Fill in the desired fields
        const { userId, fName, lName } = trainer;

        const newData = {
          userId: userId || '',
          url: uniqueUrl || '',
          lowerUrl: uniqueUrl.toLowerCase() || '',
          fName: fName || '',
          lName: lName || '',
          camSced: letSced,
        };

        // Check if the trainer exists in the new collection
        const existingTrainer = await getDocs(
          query(collection(db, 'PersonalWebsites'), where('userId', '==', userId))
        );

        if (existingTrainer.empty) {
          // Trainer does not exist, add it to the new collection
          await addDoc(collection(db, 'PersonalWebsites'), newData);
        } else {
          // Trainer already exists, update it with new data
          existingTrainer.forEach(async (existingDoc) => {
            const existingTrainerData = existingDoc.data();
            const { userId: existingUserId } = existingTrainerData;

            if (existingUserId === userId) {
              const trainerPersonalWeb = doc(db, 'PersonalWebsites', existingDoc.id);
              // Update the existing trainer with new data
              await updateDoc(trainerPersonalWeb, newData);
            }
          });
        }
      });

      console.log('Trainers copied successfully');
    }
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    const isEnglishLetter = /^[A-Za-z]+$/.test(key);
    const isNumber = /^[0-9]+$/.test(key);
  
    if (!isEnglishLetter && !isNumber) {
      event.preventDefault();
    }
  };
 
  const updateImg = async (pImg) => {
    if (!pImg) return;
  
    try {
      const file = pImg;
      const imageUrl = URL.createObjectURL(file);
      setTrainerImgBackUrl(imageUrl);
  
      const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
  
      // Copy each trainer to the new collection
      querySnapshot.forEach(async (docSnapshot) => {
        const trainer = docSnapshot.data();
  
        // Fill in the desired fields
        const { userId } = trainer;
  
        const newData = {
          ImageUrlBack: `TrainerBack/${auth.currentUser.uid}` // Update the field with the file URL
        };
  
        const trainerRef = doc(db, 'Trainers', docSnapshot.id);
        await updateDoc(trainerRef, newData);
  
        // Resize the image before uploading
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            800, // Specify the desired width
            600, // Specify the desired height
            'JPEG', // Output format (e.g., JPEG, PNG)
            100, // Quality (0 to 100)
            0, // Rotation (0, 90, 180, or 270)
            (resizedFile) => {
              resolve(resizedFile);
            },
            'blob' // Output type ('blob' or 'base64')
          );
        });
  
        // Upload the resized file to Firebase Storage
        const fileFolderRef = ref(storage, `TrainerBack/${auth.currentUser.uid}`);
        await uploadBytes(fileFolderRef, resizedImage);
  
        console.log('Image uploaded successfully');
      });
    } catch (err) {
      console.error(err);
    }
  };
  

  return (
   
    
    <div dir='rtl' className='p_web' lang='he'>

                {/* <div className="mobile_explain_web">
                  <h4>על מנת לבנות אתר אישי, כל מה שעליכם לעשות הוא להכניס את השם שברצונכם להציג למתאמנים (באנגלית בלבד) ולבדוק אם הכתובת פנויה.
                   <br/> במידה והכתובת פנויה, תוכלו לבנות את האתר שלכם בלחיצת כפתור!
                   <br/>מומלץ לאחר מכן לפרסם את הקישור במדיות החברתיות שלכם כך שכל הפרטים אודותיכם יהיו זמינים למתאמנים פוטנציאלים.
                  </h4>
                </div> */}

                {/* <div className="mobile_explain_web">
                  <h4>על מנת ליצור אתר אישי, כל מה שעליכם לעשות הוא להכניס את השם שברצונכם להציג למתאמנים (באנגלית בלבד) ולבדוק אם הכתובת פנויה.
                   <br/> 
                   <br/>מומלץ לאחר מכן לפרסם את הקישור במדיות החברתיות שלכם כך שכל הפרטים אודותיכם יהיו זמינים למתאמנים פוטנציאלים.
                  </h4>
                </div> */}

                <div className="mobile_explain_web">
                  <h4>
                    כל הכבוד! יש לכם פרופיל , עכשיו נעבור לחלק המעניין באמת וניצור לכם אתר אישי
                   <br/> 
                   <br/> 
                   כל מה שעליכם לעשות הוא להכניס את השם שברצונכם להציג למתאמנים (באנגלית בלבד) ולבדוק אם הכתובת פנויה
                  </h4>
                </div>

      {!isLoading && existingWeb && existingWeb.url != null && (
      <h2>
        כתובת האתר האישי שלך היא
        <br />
        <a href={`https://fitit.app/${existingWeb.url}`} target='blank'>
          {`https://fitit.app/${existingWeb.url}`}
        </a>
      </h2>)}

      {!isLoading && !existingWeb && (
        <div style={{display:'contents'}}>
            <div className='url_add'>
                  <h3 dir='ltr' lang='en'> Fitit.app/</h3>
                  <input
                    type='text'
                    placeholder='YourURL'
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                  ></input>
            </div> 

            <button onClick={checkUrlAvailability} className={!urlTaken && showTrainerBtn ? 'taken' : ''}>
              בדוק אם הכתובת פנויה
            </button>

                  {showTrainerBtn && (
                    <button onClick={copyTrainer}>
                      <a href={`https://fitit.app/${uniqueUrl}`} target='blank'>
                        לחץ כאן להפעלת האתר האישי
                      </a>
                    </button>
                  )}

                  {urlTaken && <p style={{ color: 'red' }}>מסתבר שיש כזה כבר, בואו ננסה שם אחר 😥</p>}
                  {!urlTaken && showTrainerBtn && <p style={{ color: 'blue' }}>הכתובת פנויה, היא שלכם אם תרצו אותה 😀</p>}
        </div>
 )}


      {!isLoading && existingWeb && existingWeb.url != null &&
      <div className="img_t">
                <div className='upload_file'>

                <label htmlFor='fileupload'>לחץ כאן כדי לעלות תמונת רקע</label>
                <input
                  type='file'
                  id='fileUpload'
                  onChange={(e) => { setFileUpload(e.target.files[0]); updateImg(e.target.files[0]); }}
                  className='hiddenbtn'/>

                </div>
          
                <div className="box">
                {fetched == true && trainerImgBackUrl != null && (
                  <img
                    src={trainerImgBackUrl}
                    alt={`${existingTrainer.fName} ${existingTrainer.lName}`}
                  />
                )}
                {fetched == true && trainerImgBackUrl == null && <img
                    src={d_profile}
                    alt={`${existingTrainer.fName} ${existingTrainer.lName}`}
                  />}
              </div>
        </div>
        }
              {!isLoading && existingWeb && existingWeb.url != null &&
              <h2>
                התמונה תשמר באופן אוטומטי ותופיע כתמונת רקע באתר האישי
              </h2>}
    </div>
  );
  
};
