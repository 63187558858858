import React, { useState, useEffect } from 'react';
import { db, storage, auth } from '../config/Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { useParams, useNavigate, NavLink } from 'react-router-dom';

import { WhatsappShareButton } from 'react-share';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Loading } from '../Componenets/Loading';

import location from '../imgs/location-pin.png'
import phone from '../imgs/call.png'
import strong from '../imgs/strong.png'
import instagramLogo from '../imgs/instagram.png'
import d_profile from '../imgs/Fitit_profile.png'
import fitit from '../imgs/Fititlogo.png'
import WhatsappIcon from '../imgs/WhatsappIcon.png'
import copy from '../imgs/copy.png'
import edit from '../imgs/edit.png'
import calendar from '../imgs/calendar.png'

import '../css/personalWebsite.css'
import { Helmet } from 'react-helmet';

export function PersonalWebsite({toggleNav, setIsPersonal}) {
  const Navigate = useNavigate()
  const { url } = useParams();
  const [webTrainer, setWebTrainer] = useState(null)
  const [trainerData, setTrainerData] = useState(null);
  const [imgUrl, setImgUrl] = useState('');
  const [imgBackUrl, setImgBackUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState(null)
  const [sced, setSced] = useState(null)
  const [fetched, setFetched] = useState(false)

  const [canEdit, setCanEdit] = useState(false)

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    toggleNav(false); // Set shouldRenderNav to false
    return () => {
      toggleNav(true); // Reset shouldRenderNav to true when component unmounts
    };
  }, []);

  useEffect(() => {
    setIsPersonal(true);
  }, []);

  useEffect(() => {
    const fetchTrainer = async () => {
      // Fetch data from 'PersonalWebsites' collection
      const q = query(collection(db, 'PersonalWebsites'), where('lowerUrl', '==', url.toLowerCase()));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const trainer = querySnapshot.docs[0].data();

        // Fetch data from 'Trainers' collection using the retrieved userId
        const trainerQuerySnapshot = await getDocs(
          query(collection(db, 'Trainers'), where('userId', '==', trainer.userId))
        );

        if (!trainerQuerySnapshot.empty) {
          const trainerData = trainerQuerySnapshot.docs[0].data();
          setWebTrainer(trainer)
          setSced(trainer.canSced)
          setTrainerData(trainerData);
          if (trainerData.Instagram) {
            setInstagramUrl(trainerData.Instagram.substring(trainerData.Instagram.lastIndexOf('/') + 1));
          }
          await getImageUrl(trainerData);
          if (auth.currentUser && auth.currentUser.email == trainerData.userMail){
            setCanEdit(true);
          }
        }
        setFetched(true)
      } else {
        // Trainer not found, redirect to 404 page
        Navigate('/404');
      }
    };

    fetchTrainer().catch((error) => {
      console.error('Error fetching trainer:', error);
      // Redirect to 404 page in case of an error
      Navigate('/404');
    });
  }, [url, Navigate]);

  const getImageUrl = async (trainer) => {
    if (trainer && trainer.ImageUrl) {
      const storageRef = ref(storage, trainer.ImageUrl);
      try {
        const url = await getDownloadURL(storageRef);
        setImgUrl(url);
      } catch (error) {
        console.error(error);
      }
    } else {
      setImgUrl(''); // Clear the imgUrl state if trainer doesn't have an image
    }
  
    if (trainer && trainer.ImageUrlBack) {
      const storageRef = ref(storage, trainer.ImageUrlBack);
      try {
        const url = await getDownloadURL(storageRef);
        setImgBackUrl(url);
      } catch (error) {
        console.error(error);
      }
    } else {
      setImgBackUrl(''); // Clear the imgBackUrl state if trainer doesn't have an image
    }
  };

    const openMaps = () => {
      const encodedAddress = encodeURIComponent(trainerData.Address);
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
  
      window.open(mapsUrl, '_blank');
    };
  
  const formattedInstagram = `@${instagramUrl}`;
  
  const shareUrl = window.location.href; // Get the current page URL

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getGridTemplateColumns = () => {
    let itemCount = 3; // Calculate the number of items you have; I've assumed 3 here
  
    if (trainerData.Instagram) {
      itemCount += 1;
    }
  
    switch (itemCount) {
      case 3:
        return '1fr 1fr 1fr'; // Or any other styling you want for 3 items
      case 4:
        return '1fr 1fr 1fr 1fr';
      // You can add more cases if needed
      default:
        return '1fr'; // Default styling if the number of items doesn't match any known case
    }
  };

  return (
    <div className='back_color'>

      {fetched &&
      <Helmet>
        <title>{`${trainerData.fName} ${trainerData.lName}`}</title>
        <meta name='description'
        content={trainerData.Description}/>
        <meta name='keywords'
        content={trainerData.Type && trainerData.Type.length > 0 ? trainerData.Type.join(', ') : ''} />
      </Helmet>
        }

      {trainerData && fetched ? (
        <div  className='bod' dir='rtl'>  

              {canEdit && <NavLink to="/profile" className='edit_page' lang='he'>
                <img src={edit} alt='edit'/>
              עריכת פרופיל</NavLink>}

            <div className='img_container'>

            {imgBackUrl && <img src={imgBackUrl} className='back_img'/>}
                <div className="circle_p">
                    {imgUrl ? (
                      <img src={imgUrl} alt={`${trainerData.fName} ${trainerData.lName}`} />
                    ) : (
                      <img
                          src={d_profile}
                          alt={`${trainerData.fName} ${trainerData.lName}`}
                        />
                    )}
            </div>
        </div>
          <h1 className='Trainer_name' lang='he'>{trainerData.fName} {trainerData.lName}</h1>

          <p className="trainerdesc" lang='he'>{trainerData.Description}</p>
          <h2 lang='he'>סוגי אימונים</h2>
          <p className="training_types" lang='he'>
            {trainerData.Type && trainerData.Type.length > 0 ? (
                trainerData.Type.map((type, index) => (
                <span key={type}>
                    {type}
                    {index !== trainerData.Type.length - 1 ? ', ' : ''}
                </span>
                ))
            ) : (
                <span> &#128549; אין סוגי אימונים זמינים</span>
            )}
            </p>

              {sced && 
               <button className='sced_session' lang='he'>
                 <img src={calendar} alt='calendar'/> 
                <NavLink to={`/${url}/schedule`} style={{textDecoration:'none'}}>קביעת אימונים</NavLink>
                 </button>}

             <ul className='uls' lang='he' style={{ gridTemplateColumns: getGridTemplateColumns() }}>
              <li>
                <div className="image-container">
                  <img src={location} alt="Location" onClick={openMaps}/>
                </div>
                <div className="text-container"  onClick={openMaps}>נווט</div>
              </li>

              <li>
              <a href={`tel:${trainerData.Phone}`}>
                <div className="image-container">
                  <img src={phone} alt="Phone"/>
                </div>
              </a>
                <div className="text-container">
                  <a href={`tel:${trainerData.Phone}`}>חייג</a>
                </div>
              </li>

              <li>
                <div className="image-container"  style={{cursor:'default'}}>
                  <img src={strong} alt="סוגי אימונים" />
                </div>
                <div className="text-container">
                {trainerData.Personal && trainerData.Group ? "אישי | קבוצתי" : (trainerData.Personal ? "אישי" : (trainerData.Group ? "קבוצתי" : ""))}
                </div>
              </li>

              <li lang="en">
                {trainerData.Instagram && (
                 <a href={`https://${trainerData.Instagram}`} target="_blank" rel="noopener noreferrer" >
                  <div className="image-container">
                    <img src={instagramLogo} alt="Instagram" />
                  </div>
                  </a>
                )}
                {trainerData.Instagram && (
                  <div className="text-container">
                    <a href={`https://${trainerData.Instagram}`} target="_blank" rel="noopener noreferrer" dir='ltr'>
                      @Insta
                    </a>
                  </div>
                )}
              </li>
            </ul>


    <div className="share-buttons">
      <p lang='he'> שתפו בווצאפ או העתיקו לינק לעמוד</p>
    <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
    <img src={copy}/>
      {/* <button className="share-button">Copy URL</button> */}
    </CopyToClipboard>
      <WhatsappShareButton url={shareUrl}>
        <img src={WhatsappIcon}/>
        {/* <button className="share-button">Share on Whatsapp</button> */}
      </WhatsappShareButton>
      {copied && <p className="copy-success" lang='he'> הכתובת הועתקה! </p>}
    </div>

        <p className='powered' dir='ltr'>powered by
          <a href='https://fitit.app/About'>
            <img src={fitit} alt="Fitit logo"/>
          </a>
        </p>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}