import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { db, auth, storage } from '../config/Firebase'
import { getDocs, updateDoc, collection, doc, query, where, orderBy, limit } from 'firebase/firestore'
import {ref, getDownloadURL} from 'firebase/storage'
import { Loading } from './Loading'

import '../css/TraineeP.css'
export const TraineeCalendar = () => {

const [upcomingSessions, setUpcomingSessions] = useState([]);
const [pendingSessions, setPendingSessions] = useState([]);
const [declinedSessions, setDeclinedSessions] = useState([]);
const [doneSessions, setDoneSessions] = useState([]);
const [loading, setLoading] = useState(true);
const [fetchTrigger, setFetchTrigger] = useState(false);
// const [isUserLoaded, setIsUserLoaded] = useState(false);
const [myTrainers, setMyTrainers] = useState([])

const navigate = useNavigate();

useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(user => {
    if (user) {
      fetchSessionsByTrainee();
    } else {
      setLoading(false);
    }
  });

  return () => unsubscribe(); // Cleanup subscription on unmount
}, [fetchTrigger]);

        const fetchSessionsByTrainee = async () => {
          if (!auth.currentUser) {
            setLoading(false);
            return;
          }
          const q = query(
            collection(db, 'sessions'),
            where('trainee.id', '==', auth.currentUser.uid)
          );
      
          const querySnapshot = await getDocs(q);
      
          if (!querySnapshot.empty) {
            const traineeSessions = querySnapshot.docs.map(doc => doc.data());

                // Fetch trainer data for each session
                const fetchTrainers = async () => {
                  let trainerList = [];
                  let trainerSet = new Set(); // Create a Set to track trainer IDs
                
                  for (let session of traineeSessions) {
                    // If this trainer ID is already in the Set, skip this iteration
                    if (trainerSet.has(session.trainer.id)) {
                      continue;
                    }
                
                    const q = query(collection(db, 'Trainers'), where('userId', '==', session.trainer.id));
                    const querySnapshot = await getDocs(q);
                
                    if (!querySnapshot.empty) {
                      const trainerDoc = querySnapshot.docs[0];
                      let trainerData = { id: trainerDoc.id, ...trainerDoc.data() };
                
                      // Fetch trainer image if it exists
                      if (trainerData.ImageUrl) {
                        const storageRef = ref(storage, trainerData.ImageUrl);
                        try {
                          const imageUrl = await getDownloadURL(storageRef);
                          trainerData.image = imageUrl;
                        } catch (err) {
                          console.error(err);
                        }
                      }
                
                      trainerList.push(trainerData);
                      // Add the trainer ID to the Set
                      trainerSet.add(session.trainer.id);
                    }
                  }
                  return trainerList;
                };
                
                 const trainers = await fetchTrainers();
                setMyTrainers(trainers)

            const upcomingQuery = query(
              collection(db, 'sessions'),
              where('trainee.id', '==', auth.currentUser.uid),
              where('sessionStatus', '==', 'Upcoming'),
              orderBy('sessionDate', 'asc'),
              orderBy('startTime', 'asc')
            );
        
            const upcomingSnapshot = await getDocs(upcomingQuery);
            const upcomingSessions = upcomingSnapshot.docs.map(doc => doc.data());

            const doneQuery = query(
              collection(db, 'sessions'),
              where('trainee.id', '==', auth.currentUser.uid),
              where('sessionStatus', '==', 'Done'),
              orderBy('sessionDate', 'desc'), // Order by date in descending order to get latest
              orderBy('startTime', 'desc'), // Order by time in descending order to get latest
              limit(10) // Limit to 10 latest sessions
            );
          
            const doneSnapshot = await getDocs(doneQuery);
            const doneSessions = doneSnapshot.docs.map(doc => doc.data());
          
            const pendingSessions = traineeSessions.filter(session => session.sessionStatus === 'Pending');
            const declinedSessions = traineeSessions.filter(session => session.sessionStatus === 'Declined');
          
            setUpcomingSessions(upcomingSessions);
            setPendingSessions(pendingSessions);
            setDoneSessions(doneSessions);
            setDeclinedSessions(declinedSessions);
          } else {
            // Reset to default state if no data
            setUpcomingSessions([]);
            setPendingSessions([]);
            setDoneSessions([])
            setDeclinedSessions([]);
          }
          setLoading(false); // Moved outside the condition
        };

      const declineSession = async (sessionId) => {
        // Update the document to set session status to Declined
        const sessionRef = doc(db, 'sessions', sessionId);
        await updateDoc(sessionRef, { sessionStatus: 'Canceled' });
        
        // Trigger a fetch to update UI
        setFetchTrigger(!fetchTrigger);
      };

      const changeSession = async (sessionId, trainerId) => {
          // Update the document to set session status to Changed
          const sessionRef = doc(db, 'sessions', sessionId);
        await updateDoc(sessionRef, { sessionStatus: 'Changed' });
        
        // Fetch trainer's URL
        const trainerUrl = await fetchTrainerUrl(trainerId);
        
        if (trainerUrl) {
            // Redirect to trainer's calendar page
            navigate(`/${trainerUrl}/schedule`);
        } else {
            console.error('Trainer URL not found.');
            // Handle the error (e.g., show a message to the user or redirect to another page)
        }
    };

    const fetchTrainerUrl = async (trainerId) => {
      const q = query(collection(db, 'Trainers'), where('userId', '==', trainerId));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const trainerDoc = querySnapshot.docs[0];
        const personalWebsiteQuery = query(collection(db, 'PersonalWebsites'), where('userId', '==', trainerDoc.data().userId));
        const personalWebsiteSnapshot = await getDocs(personalWebsiteQuery);
  
        if (!personalWebsiteSnapshot.empty) {
          return personalWebsiteSnapshot.docs[0].data().lowerUrl;
        }
      }
  
      return null;
    };

return (
  <div className="calendar p_web" lang="he" dir="rtl">
  {loading ? (
    <Loading />
  ) : (
    <>
    <h3 className="trainee_p">המאמנים שלי</h3>
          <div className="my_trainers">
        {myTrainers.map(trainer => (
            <NavLink to={`/${trainer.websiteUrl}`} className='link_p'> 
          <div key={trainer.userId} className="one_trainer">
            <h3>{trainer.fName} {trainer.lName}</h3>
            {trainer.image &&
            <div className="circle">
            <img src={trainer.image} alt={`${trainer.fName} ${trainer.lName}`}
            /></div>}
          </div>
            </NavLink>
        ))}
      </div>


      <h3 className="trainee_p">האימון הבא</h3>
      
      {upcomingSessions.map(session => {
  // Parse the date and format it for this specific session
  const parsedDate = new Date(session.sessionDate);
  const formattedDate = new Intl.DateTimeFormat('he-IL', {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: '2-digit'
  }).format(parsedDate);

  return (
    <div className="daily trainee_p" key={session.id}>
      <h3>אימון עם {session.trainer.fName}</h3>
      <h3>{formattedDate}</h3> {/* Use the formatted date here */}
      <div className="hours">
        <h3>{session.startTime}</h3>
        <h3>עד</h3>
        <h3>{session.endTime}</h3>
      </div>
      <div className="hours">
        <button className="change" onClick={() => changeSession(session.id, session.trainer.id)}>שינוי מועד</button>
        <button className="cancel" onClick={() => declineSession(session.id)}>ביטול אימון</button>
      </div>
    </div>
  );
})}

{pendingSessions.map(session => {
  // Parse the date and format it for this specific session
  const parsedDate = new Date(session.sessionDate);
  const formattedDate = new Intl.DateTimeFormat('he-IL', {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: '2-digit'
  }).format(parsedDate);

  return (
    <div className="daily trainee_p" key={session.id}>
      <h3>אימון עם {session.trainer.fName}</h3>
      <h3>{formattedDate}</h3> {/* Use the formatted date here */}
      <div className="hours">
        <h3>{session.startTime}</h3>
        <h3>עד</h3>
        <h3>{session.endTime}</h3>
      </div>
      <div className="hours">
        <button className="change" onClick={() => changeSession(session.id, session.trainer.id)}>שינוי מועד</button>
        <button className="cancel" onClick={() => declineSession(session.id)}>ביטול אימון</button>
      </div>
    </div>
  );
})}

      <h3 className="trainee_p">היסטוריית אימונים</h3>
  
        {doneSessions.map(session => {
        // Parse the date and format it for this specific session
        const parsedDate = new Date(session.sessionDate);
        const formattedDate = new Intl.DateTimeFormat('he-IL', {
          weekday: 'long',
          day: 'numeric',
          month: 'numeric',
          year: '2-digit'
        }).format(parsedDate);
        return (
              <div className="daily" key={session.id}>
                  <h3>אימון עם {session.trainer.fName}</h3>
                  <h3>{formattedDate}</h3>
                  <div className="hours">
                      <h3>{session.startTime}</h3>
                      <h3>עד</h3>
                      <h3>{session.endTime}</h3>
                  </div>
              </div>)
              })}
            </>
            )}
</div>
  )
}  