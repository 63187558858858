export const fitnessTypeOptions = [
    { value: 'חדר כושר', label: 'חדר כושר' },
    { value: 'משקל גוף', label: 'משקל גוף' },
    { value: 'פילאטיס', label: 'פילאטיס' },
    { value: 'HIIT', label: 'HIIT' },
    { value: 'יוגה', label: 'יוגה' },
    { value: 'קרוספיט', label: 'קרוספיט' },
    { value: 'רכיבה על אופניים', label: 'רכיבה על אופניים' },
    { value: 'קיקבוקסינג', label: 'קיקבוקסינג' },
    { value: 'שחיה', label: 'שחיה' },
    { value: 'ריצה', label: 'ריצה' },
    { value: 'אומנויות לחימה', label: 'אומנויות לחימה'}
  ];

  export const fitnessTimes = [
    // { value: 15, label: '15' },
    { value: 30, label: '30' },
    { value: 45, label: '45' },
    { value: 60, label: '60' }
  ]