import { useEffect, useState } from "react";
import { getDocs, collection, query, doc, updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/Firebase'; // Update with your Firebase configuration
import d_profile from "../imgs/Fitit_profile.png"; // Import the path to the default image

export const Users = ({ trainers }) => {
  const pageSize = 5; // Number of items to load per page
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedTrainers, setDisplayedTrainers] = useState([]);

  const [updatedTrainers, setUpdatedTrainers] = useState([]);


  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const trainersQuery = query(collection(db, 'Trainers'));
    const fetchTrainers = async () => {
      try {
        const data = await getDocs(trainersQuery);
        const trainersData = await Promise.all(
          data.docs.map(async (doc) => {
            const trainer = { ...doc.data(), id: doc.id };
  
            if (trainer.ImageUrl) {
              const storageRef = ref(storage, trainer.ImageUrl);
              try {
                const imageUrl = await getDownloadURL(storageRef);
                trainer.imageUrl = imageUrl;
              } catch (err) {
                console.error(err);
                trainer.imageUrl = null;
              }
            } else {
              trainer.imageUrl = null;
            }
  
            return trainer;
          })
        );
  
        const sortedTrainersData = trainersData.sort((a, b) => {
          // Sort based on createdAt timestamps
          if (!a.createdAt && !b.createdAt) {
            return 0; // If both trainers have no createdAt value, preserve their order
          }
          if (!a.createdAt) {
            return 1; // If only a is missing createdAt, b comes first
          }
          if (!b.createdAt) {
            return -1; // If only b is missing createdAt, a comes first
          }
  
          // Sort by descending order of createdAt date
          const dateComparison = b.createdAt.toMillis() - a.createdAt.toMillis();
          
          // If present is false, move it to the end of the list
          if (!a.Present && b.Present) {
            return 1;
          }
          if (a.Present && !b.Present) {
            return -1;
          }
          
          // Sort by createdAt if the present values are the same
          if (dateComparison !== 0) {
            return dateComparison;
          }
  
          return 0;
        });
  
        setDisplayedTrainers(sortedTrainersData.slice(startIndex, endIndex));
        setUpdatedTrainers(sortedTrainersData);
      } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch trainers');
      }
    };
  
    fetchTrainers();
  }, [currentPage]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage * pageSize >= trainers.length;

  const formatCreatedAtDate = (createdAt) => {
    if (!createdAt) {
      return 'N/A'; // Provide a fallback value for missing or undefined dates
    }
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return createdAt.toDate().toLocaleDateString('en-US', options);
  };

  const updateTrainerStatus = async (trainerId, status) => {
    try {
      // Update the corresponding trainer's status in the updatedTrainers state
      const updatedData = updatedTrainers.map((trainer) => {
        if (trainer.id === trainerId) {
          return {
            ...trainer,
            Present: status,
          };
        }
        return trainer;
      });
  
      setUpdatedTrainers(updatedData);
  
      // Update the status in the database
      const trainerRef = doc(db, 'Trainers', trainerId);
      await updateDoc(trainerRef, { Present: status });
  
      console.log(`Trainer status updated: Trainer ID ${trainerId}, Status ${status}`);
    } catch (error) {
      console.error(error);
      throw new Error('Failed to update trainer status');
    }
  };
  

  return (
    <div className="users">
      <h3 dir="rtl">מאמנים</h3>
        <div className="trainer-count">סהכ מאמנים: {trainers.length}</div>
      <table className="users_table">
        <thead>
          <tr>
            <th>#</th>
            <th>תמונה</th>
            <th>שם</th>
            <th>תאריך הצטרפות</th>
            <th>מוצג</th>
          </tr>
        </thead>
        <tbody>
          {displayedTrainers.map((trainer, index) => (
            <tr key={trainer.id} className={trainer.Present ? '' : 'present_false'}>
              <td>{(currentPage - 1) * pageSize + index + 1}</td>
          
              <td>
                <div className="circle">
                  {trainer.imageUrl ? (
                    <img
                      src={trainer.imageUrl}
                      alt={`${trainer.fName} ${trainer.lName}`}
                    />
                  ) : (
                    <img
                      src={d_profile}
                      alt={`${trainer.fName} ${trainer.lName}`}
                    />
                  )}
                </div>
              </td>
              
              <td>{trainer.fName} {trainer.lName}</td>
              <td>{formatCreatedAtDate(trainer.createdAt)}</td>
              <td>
                {trainer.Present ? (
                  <button onClick={() => updateTrainerStatus(trainer.id, false)}>כן</button>
                ) : (
                  <button onClick={() => updateTrainerStatus(trainer.id, true)}>לא</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="btns">
        <button onClick={handleNextPage} disabled={isLastPage}>
          העמוד הבא
        </button>
        <div className="page-counter">עמוד {currentPage}</div>
        <button onClick={handlePrevPage} disabled={isFirstPage}>
          העמוד הקודם
        </button>
      </div>
    </div>
  );
};
