import { useState, useContext } from 'react';
import { auth, googleProvider } from '../config/Firebase';
import { signInWithPopup } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../Constext/AuthContext';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../config/Firebase';

import '../css/SignIn.css';
import googlogo from '../imgs/Googlelogo.png'

export const SignIn = () => {
  // const[email, setEmail] = useState('')
  // const[password, setPassword] = useState('')
  const[error, setError] = useState(false)

  const Navigate = useNavigate()

  const { dispatch, setUserRole } = useContext(AuthContext); // Add setUserRole from AuthContext

//   const signUp = async () => {
//     try{
//   const userCredentials =await createUserWithEmailAndPassword(auth, email, password)
//   console.log('signed up!')
//   setError(false)
//   Navigate('/')
//   const user = userCredentials.user;
//   dispatch({type:'LOGIN',  payload:user})
//   setUserRole('Trainer'); // Set the user role here
//     }catch(err){
//       console.error(err);
//     }
// };
  
//   const signIn = async () => {
//       try{
//     const userCredentials = await signInWithEmailAndPassword(auth, email, password)
//     console.log('signed in!')
//     setError(false)
//     const user = userCredentials.user;
//     dispatch({type:'LOGIN',  payload:user})
//     setUserRole('Trainer'); // Set the user role here
//     Navigate('/')
//       }catch(err){
//         console.error(err);
//         setError(true)
//       }
//   };

  // const signInWithGoogle = async () => {
  //   try {
  //     const userCredentials = await signInWithPopup(auth, googleProvider);
  //     console.log('signed in!');
  //     setError(false);
  //     const user = userCredentials.user;
  //     dispatch({ type: 'LOGIN', payload: user });
  //     console.log(user);
  //     Navigate('/profile');
  //     setUserRole('Trainer'); // Set the user role here
  //   } catch (err) {
  //     console.error(err);
  //     setError(true);
  //   }
  // };

  const signInWithGoogle = async () => {
    try {
      const userCredentials = await signInWithPopup(auth, googleProvider);
      console.log('signed in!');
      setError(false);
      const user = userCredentials.user;
      dispatch({ type: 'LOGIN', payload: user });

      // Check if trainer profile fields are filled
      const checkProfileFields = async () => {
        const trainersCollectionRef = collection(db, 'Trainers');
        const trainersQuerySnapshot = await getDocs(trainersCollectionRef);
      
        const trainerDoc = trainersQuerySnapshot.docs.find(
          (doc) => doc.data().userMail === auth.currentUser.email
        );
            
            if (trainerDoc) {
              const trainer = trainerDoc.data();
              const hasFilledFields =
              trainer.fName !== '' &&
              trainer.lName !== '' &&
              trainer.Phone !== '';
              if (hasFilledFields) {
                Navigate('/profile');
              } else {
                Navigate('/profileWizard');
              }
            } else {
              // No trainer document found for the current user, navigate to '/profileWizard'
              Navigate('/profileWizard');
            }
          };     

      checkProfileFields();

      setUserRole('Trainer'); // Set the user role here
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

// const signInWithFacebook = async () => {
//   try {
//     const userCredentials = await signInWithPopup(auth, faceProvider);
//     console.log('signed in with facebook!');
//     setError(false);
//     const user = userCredentials.user;
//     dispatch({ type: 'LOGIN', payload: user });
//     Navigate('/');
//   } catch (err) {
//     console.error(err);
//     setError(true);
//   }
// };

  // const logOut = async () => {
  //   try{
  // await signOut(auth)
  // console.log('signed Out!')
  //   }catch(err){
  //     console.error(err);
  //   }
  // };

  return (

    <div className='signinbox'>
      <h2 dir='rtl' lang='he'>כניסת מאמנים </h2>
      {/* <input placeholder="אימייל" onChange={(e) => setEmail(e.target.value)} lang='he'/>
      <input placeholder="סיסמא" type='password' onChange={(e) => setPassword(e.target.value)} lang='he'/>
      <button onClick={signIn} lang='he' className='btn1'>כניסה</button> */}

      <button onClick={signInWithGoogle}  className='googsign' lang='he'><img src= {googlogo}/> כניסה באמצעות גוגל</button>

      {/* <button onClick={signInWithFacebook}  className='facesign' lang='he'><img src= {facelogo}/> כניסה באמצעות פייסבוק</button> */}
      {/* <button onClick={logOut} lang='he'>התנתק</button> */}

      {/* <button onClick={signUp} lang='he' className='signup'>הרשמה</button> */}
        {error && <span lang='he'> 😕 אופס, יש פה תקלה </span>}
        
        <span lang='he' style={{ position: 'absolute', bottom: '10%' }}>
        <NavLink to='/signinTrainee' style={{ color: '#647dee', fontWeight: '600', textDecoration:'none'}}>
          לכניסת מתאמנים
        </NavLink>
        </span>

    </div>
  )
}