import Lottie from 'react-lottie';
import animationData from '../imgs/rWg9qPEKUF.json'


export function Loading(){

    const animationOptions = {
        loop: true, // Set loop to true for continuous playback
        autoplay: true, // Set autoplay to true to start the animation automatically
        animationData: animationData,
      };

return(
    <div className="loading-animation">
        <Lottie options={animationOptions}/>
    </div>
)} 