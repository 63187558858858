import { auth } from '../config/Firebase'
import {useEffect, useState, useContext } from 'react'
import { signOut } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom';
import { LoggedProfileDelete } from './LoggedProfileDelete';
import { AuthContext } from '../Constext/AuthContext'
import { TraineeCalendar } from './TraineeCalendar';
import { TraineeProfile } from './TraineeProfile';

import '../css/LoggedProfile.css';

export const TraineeLoggedProfile = () => {

  const [settings, setSettings] = useState('details')
  const Navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)
  const { currentUser } = useContext(AuthContext)

  const location = useLocation();
  
    // Update the loggedIn state when the component mounts
    useEffect(() => {
      setLoggedIn(currentUser ? true : false)
    }, [currentUser])

  const logOut = async () => {
    try {
      await signOut(auth)
      localStorage.setItem('user', JSON.stringify(null)); // Update the currentUser value in local storage
      Navigate('/')
      window.location.reload();
      console.log('signed Out!')
      console.log(setLoggedIn, currentUser)
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const displayComponent = location?.state?.displayComponent;
    if (displayComponent) {
      setSettings(displayComponent);
    }
  }, [location]);
  

    return (
      <div className='update_profile'>
        <div className='settings'>
              <ul lang='he'>

                  <li onClick={() => setSettings('details')}
                  className={`${settings === 'details' ? 'chosen': ''}`}> פרטים</li>
                  
                  <li onClick={() => setSettings('luzIshi')}
                  className={`${settings === 'luzIshi' ? 'chosen': ''}`}> לוז אישי</li>


                  <li onClick={() => setSettings('Delete')}
                  className={`${settings === 'Logout' ? 'chosen': ''}`}>פרופיל</li>

                  {/* <li onClick={() => logOut()}
                  // className={`${settings === 'Logout' ? 'chosen': ''}`}
                  >יציאה</li> */}
              </ul>
              <div className='v_line'/>
          </div>

          {settings === 'details' && <TraineeProfile />}

          {settings === 'luzIshi' && <TraineeCalendar/>}
          {settings === 'Delete' && <LoggedProfileDelete/>}
      </div>
    )
}