import React, { useState, useRef, useEffect, useReducer  } from 'react';

import { db, auth } from '../config/Firebase'
import { getDocs, getDoc, collection, doc, setDoc, query, where, updateDoc } from 'firebase/firestore'

import '../css/Calendar.css';
import '../css/TrainerCalendar.css';
import Select from 'react-select';
import { fitnessTimes } from '../Constext/filters'
import { daysOfWeekHebrew, monthsHebrew } from '../Constext/MonthNDays';
import { TrainerHours } from './TrainerHours'
import { TrainerHoursFixed } from './TrainerHoursFixed';
import { Loading } from './Loading'

import arrowDown from '../imgs/arrowDown.png';
import save from  '../imgs/save.png'
import Tooltip from './Tooltip';


function reducer(state, action) {
  switch (action.type) {
    case 'SWIPE_START':
      return { ...state, initialTouchX: action.payload };
    case 'SWIPE_MOVE':
      const diffX = state.initialTouchX - action.payload;
      let direction = null;
      if (Math.abs(diffX) > 50) { // 50 is your threshold
        direction = diffX > 0 ? 'left' : 'right';
      }
      return { ...state, swipeDirection: direction };
    case 'SWIPE_END':
      return initialState; // reset to initial state on end
    default:
      throw new Error();
  }
}

const initialState = {
  initialTouchX: 0,
  swipeDirection: null,
};

export const TrainerCalendar = () => {
  const [trainingDuration, setTrainingDuration] = useState([]); // default value
  const [scheduleInAdvance, setScheduleInAdvance] = useState(14); // default value
  const [approveOrNot, setApproveOrNot] = useState(true) //defult value
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(0);
  const calendarRef = useRef(null);
  const [displayFixedHours, setDisplayFixedHours] = useState(true);
  const [displaySpecialChanges, setDisplaySpecialChanges] = useState(false);
  const [selectedButton, setSelectedButton] = useState('fixedHours'); // Initially set to 'fixedHours'
  const [loading, setLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [letSced, setLetSced] = useState(true)
  const isSavedTimeout = useRef(null);
  const [holidays, setHolidays] = useState({});

  const [state, dispatch] = useReducer(reducer, initialState);
  
  const [parentDivs, setParentDivs] = useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday:[],
    Thursday:[],
    Friday:[],
    Saturday:[],
  });
  
  useEffect(() => {
    const fetchTrainerData = async () => {
      try {
        const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
  
        const querySnapshot = await getDocs(q);
        let trainerDocId;
  
        querySnapshot.forEach((doc) => {
          trainerDocId = doc.id;
        });
  
        const settingsRef = collection(db, 'Trainers', trainerDocId, 'settings');
        const docRef = doc(settingsRef, 'trainerSettings');
  
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTrainingDuration(data.trainingDuration.map(time => ({ value: time, label: time.toString() }))); // convert to format for react-select
          setScheduleInAdvance(data.scheduleInAdvance);
          setApproveOrNot(data.approveOrNot)
          if (data.workingTime && Object.keys(data.workingTime).length > 0) {
            setParentDivs(data.workingTime);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data from Firebase:', error);
      }
    };
  
    fetchTrainerData();
  }, []);

  //can sced
  useEffect(() => {
    const fetchTrainer = async () => {
      // Fetch data from 'PersonalWebsites' collection
      const q = query(collection(db, 'PersonalWebsites'), where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const trainer = querySnapshot.docs[0].data();
        setLetSced(trainer.canSced)
      }
    };

    fetchTrainer().catch((error) => {
      console.error('Error fetching trainer:', error);
    });
  }, []);

  const removeEmptyDivs = () => {
    const updatedParentDivs = {};
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
    Object.keys(parentDivs).forEach((key) => {
      const nonEmptyDivs = parentDivs[key].filter((div) => div && Object.keys(div).length > 0);
  
      // if the key is a day of the week or nonEmptyDivs is not empty, add it to updatedParentDivs
      if (daysOfWeek.includes(key) || nonEmptyDivs.length > 0) {
        updatedParentDivs[key] = nonEmptyDivs;
      }
    });
    return updatedParentDivs;
  };
  
  
  const handleSaveChanges = async () => {
    try {
      const trainerData = {
        trainingDuration: trainingDuration.map(option => option.value), // convert back to array of numbers,
        scheduleInAdvance,
        approveOrNot, 
        workingTime: removeEmptyDivs()
      };
  
      // Query the 'Trainers' collection where 'userId' equals the user ID of the currently logged-in trainer
      const q = query(collection(db, 'Trainers'), where('userId', '==', auth.currentUser.uid));
  
      const querySnapshot = await getDocs(q);
      let trainerDocId;
  
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        trainerDocId = doc.id;  // Save the document ID
      });
  
      // Reference to the 'settings' subcollection for the current trainer
      const settingsRef = collection(db, 'Trainers', trainerDocId, 'settings');
  
      // Assuming each trainer will only have one settings document, we use a static ID 'trainerSettings'
      const docRef = doc(settingsRef, 'trainerSettings');
  
      await setDoc(docRef, trainerData, { merge: false });
  
      // Query the 'PersonalWebsites' collection where 'userId' equals the user ID of the currently logged-in trainer
      const qPersonalWebsites = query(collection(db, 'PersonalWebsites'), where('userId', '==', auth.currentUser.uid));
  
      const personalWebsiteQuerySnapshot = await getDocs(qPersonalWebsites);
      let personalWebsiteDocId;
      
      personalWebsiteQuerySnapshot.forEach((doc) => {
        personalWebsiteDocId = doc.id;  // Save the document ID
      });
  
      // Reference to the document for the current trainer in 'PersonalWebsites' collection
      const personalWebsiteDocRef = doc(db, 'PersonalWebsites', personalWebsiteDocId);
      
      // Update 'canSced' field in the 'PersonalWebsites' collection
      await updateDoc(personalWebsiteDocRef, { canSced: letSced });
  
      console.log('Changes saved successfully!');
      setIsSaved(true);
      
      // Set the timeout to clear the message after 3 seconds
      isSavedTimeout.current = setTimeout(() => {
        setIsSaved(false);
      }, 3000);
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
    }
  };  
  
  useEffect(() => {
    setIsSaved(false);
    
    // Clear the timeout when the component is unmounted or when the dependencies change
    return () => {
      clearTimeout(isSavedTimeout.current);
    };
  }, [trainingDuration, scheduleInAdvance, parentDivs]);

  const handleDivsChange = (divs, dayName) => {
    setParentDivs((prevDivs) => {
      if (JSON.stringify(prevDivs[dayName]) !== JSON.stringify(divs)) {
        return {
          ...prevDivs,
          [dayName]: divs,
        };
      }
      return prevDivs;
    });
  };

  const handleChangeMonth = (event) => {
    const month = event.target.value;
    const newDate = new Date(selectedDate.getFullYear(), month, 1);
    setSelectedDate(newDate);
  };

  const handleSwipeStart = (event) => {
    dispatch({ type: 'SWIPE_START', payload: event.touches[0].clientX });
  };

  const handleSwipeMove = (event) => {
    dispatch({ type: 'SWIPE_MOVE', payload: event.touches[0].clientX });
  };

  const handleSwipeEnd = () => {
    if (state.swipeDirection === 'right') {
      const newDate = new Date(selectedDate);
      newDate.setDate(selectedDate.getDate() + 7);
      setSelectedDate(newDate);
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.add('swipe-right'); // Add class to the week-dates div
      }
    } else if (state.swipeDirection === 'left') {
      const newDate = new Date(selectedDate);
      newDate.setDate(selectedDate.getDate() - 7);
      setSelectedDate(newDate);
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.add('swipe-left'); // Add class to the week-dates div
      }
    }

    // Clear the added class after the transition duration
    setTimeout(() => {
      if (calendarRef.current) { // Add this check
        calendarRef.current.classList.remove('swipe-right', 'swipe-left');
      }
    }, 300);
    dispatch({ type: 'SWIPE_END' });
  };

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const handleSelectDay= (day) => {
    setSelectedDay(day);
  };

  const renderWeekDates = () => {
    const startDate = new Date(selectedDate);
    startDate.setDate(selectedDate.getDate() - selectedDate.getDay());

    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      const isSelected = date.toDateString() === selectedDate.toDateString();

      dates.push(
        <div
          key={i}
          className={`date ${isSelected ? 'selected' : ''}`}
          onClick={() => handleSelectDate(date)}
        >
          <div className="day-name">{daysOfWeekHebrew[date.getDay()]}</div>
          <div className="day-date">{date.getDate()}</div>
        </div>
      );
    }

    return dates;
  };

  
  const renderWeekDays = () => {

    return daysOfWeekHebrew.map((day, index) => (
      <div
        key={index}
        className={`day ${selectedDay === index ? 'selected' : ''}`}
        onClick={() => handleSelectDay(index)}
      >
        <div className="day-name">{day}</div>
      </div>
    ));
  };
  
  const toggleFixedHours = () => {
    setDisplayFixedHours(true);
    setDisplaySpecialChanges(false);
    setSelectedButton('fixedHours')
  };

  const toggleSpecialChanges = () => {
    setDisplayFixedHours(false);
    setDisplaySpecialChanges(true);
    setSelectedButton('specialChanges')
  };
  
  if (loading) {
    // You can replace this with a loading spinner or some other placeholder
    return <Loading/>
  }

  return (
    <div
      className="calendar p_web"
      style={{ paddingTop: '5px' }}
      lang="he"
      dir="rtl"
      ref={calendarRef}
      onTouchStart={handleSwipeStart}
      onTouchMove={handleSwipeMove}
      onTouchEnd={handleSwipeEnd}
    >
      <div className='btns_top'>
      <button onClick={toggleFixedHours} className={selectedButton === 'fixedHours' ? 'btn_top selected' : 'btn_top'}>
          שעות עבודה קבועות
        </button>
        <button
          onClick={toggleSpecialChanges}
          className={selectedButton === 'specialChanges' ? 'btn_top selected' : 'btn_top'}
        >
          שינויים מיוחדים
        </button>
      </div>
      <Tooltip message="אפשר לבחור חלונות זמנים שבהם המתאמנים יקבעו אימונים, למשל 10:00 עד 14:00 ו-16:00 עד 20:00" />
        <div className='time_q'>
                <h3>האם לאפשר קביעת אימונים ב-Fitit?</h3>
                <select 
                value={letSced.toString()}
                onChange={event => setLetSced(event.target.value === 'true')}
          >
                <option value={true}>כן</option>
                <option value={false}>לא</option>
          </select>
        </div>

{displayFixedHours && letSced && (
  <div className="fixed-hours">
            {isSaved && <span className='saved'>השינויים נשמרו!</span>}  

    <div className='time_q'>
      <h3>האם לאשר בקשות חדשות אוטומטית?</h3>
        <select 
              value={approveOrNot}
              onChange={event => setApproveOrNot(event.target.value)}
            >
              <option value="כן">כן</option>
              <option value="לא">לא</option>
          </select>
    </div>

    <div className='time_q'>
        <h3>כמה זמן אפשר לקבוע אימון מראש?</h3>
          <input 
              type="number" 
              pattern="\d*" 
              value={scheduleInAdvance}
              onChange={event => setScheduleInAdvance(event.target.value)}
          />
        <label>ימים</label>
    </div>

    <div className='time_q'>
    <h3>כמה זמן אורך האימון ?(דקות)</h3>
      <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={fitnessTimes}
                isMulti
                placeholder='אפשר לבחור כמה אפשרויות'
                value={trainingDuration}
                onChange={selectedOption => setTrainingDuration(selectedOption)}
                required
                lang='he'
                isSearchable={ false }
                isClearable={ false }  // Add this line to hide the clearable icon (X)
              />
    
    </div>

    <div className="week-dates">
      {renderWeekDays()}
    </div>

<div>
  {selectedDay === 0 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Sunday" parentDivs={parentDivs}/>}
  {selectedDay === 1 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Monday" parentDivs={parentDivs}/>}
  {selectedDay === 2 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Tuesday" parentDivs={parentDivs}/>}
  {selectedDay === 3 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Wednesday" parentDivs={parentDivs}/>}
  {selectedDay === 4 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Thursday" parentDivs={parentDivs}/>}
  {selectedDay === 5 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Friday" parentDivs={parentDivs}/>}
  {selectedDay === 6 && <TrainerHoursFixed onDivsChange={handleDivsChange} dayName="Saturday" parentDivs={parentDivs}/>}
</div>
</div>)}

      {displaySpecialChanges && letSced &&(
        <div className="special-changes">
              {isSaved && <span className='saved'>השינויים נשמרו!</span>}  
              <span>השינויים שיבוצעו פה ישנו את שעות העבודה רק בתאריך שבחרת</span>
      <div className="header">
        <div className="select-wrapper">
          <select value={selectedDate.getMonth()} onChange={handleChangeMonth}>
            {Array.from({ length: 12 }, (_, month) => (
              <option key={month} value={month}>
                {monthsHebrew[month]}
              </option>
            ))}
          </select>
          <img src={arrowDown} alt="arrow" />
        </div>
      </div>

      <div className="week-dates" ref={calendarRef}>
        {renderWeekDates()}
      </div>

      {selectedDate && (
                <div>  
                  <TrainerHours
                    key={selectedDate.toISOString()}
                    onDivsChange={handleDivsChange}
                    dayName={selectedDate.toISOString().split('T')[0]}
                    parentDivs={parentDivs}
                  />
          </div>)}
      </div>)}

   {letSced && <span onClick={handleSaveChanges}>
      <img src={save}/>
      שמירת שינויים
      </span>}


    </div>
  );
};
