import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationData from '../imgs/confetti.json';

export const Confetti = () => {
    const [conf, setConf] = useState(true);
    
    const animationOptions = {
      loop: false, // Set loop to true for continuous playback
      autoplay: true, // Set autoplay to true to start the animation automatically
      animationData: animationData,
    };

    useEffect(() => {
        // start the timer after the component mounts
        const timer = setTimeout(() => {
          setConf(false); // set conf to false after 3 seconds
        }, 3000);
    
        // clean up the timer when the component unmounts
        return () => {
          clearTimeout(timer);
        };
    }, []); 

    return (
        <>
            {conf && <div className='confetti'>
                <Lottie options={animationOptions} />
            </div>}
        </>
    );
}
