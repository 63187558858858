import { auth } from '../config/Firebase'
import {useEffect, useState, useContext } from 'react'
// import {getDocs, collection, doc, addDoc, deleteDoc, updateDoc, query, where} from 'firebase/firestore'
// import {ref, uploadBytes} from 'firebase/storage'
import { signOut } from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom';
import { LoggedProfileDetalis } from './LoggedProfileDetails';
import { LoggedProfileElse } from './LoggedProfileElse';
import { LoggedProfileDelete } from './LoggedProfileDelete';
import { AuthContext } from '../Constext/AuthContext'
import { TrainerCalendar } from './TrainerCalander';
import { TrainerCalendarUpdates } from './TrainerCalanderUpdates';

import '../css/LoggedProfile.css';

export const LoggedProfile = () => {

  const [settings, setSettings] = useState('details')
  const Navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)
  const { currentUser } = useContext(AuthContext)

  const location = useLocation();
  
    // Update the loggedIn state when the component mounts
    useEffect(() => {
      setLoggedIn(currentUser ? true : false)
    }, [currentUser])

  const logOut = async () => {
    try {
      await signOut(auth)
      localStorage.setItem('user', JSON.stringify(null)); // Update the currentUser value in local storage
      Navigate('/')
      window.location.reload();
      console.log('signed Out!')
      console.log(setLoggedIn, currentUser)
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const displayComponent = location?.state?.displayComponent;
    if (displayComponent) {
      setSettings(displayComponent);
    }
  }, [location]);

    return (
      <div className='update_profile'>
        <div className='settings'>
              <ul lang='he'>

                  <li onClick={() => setSettings('details')}
                  className={`${settings === 'details' ? 'chosen': ''}`}> פרטים</li>
                  
                  <li onClick={() => setSettings('luz')}
                  className={`${settings === 'luz' ? 'chosen': ''}`}> שעות </li>

                  <li onClick={() => setSettings('luzIshi')}
                  className={`${settings === 'luzIshi' ? 'chosen': ''}`}>לוז </li>

                  <li onClick={() => setSettings('else')}
                  className={`${settings === 'else' ? 'chosen': ''}`}>אתר </li>

                  <li onClick={() => setSettings('Delete')}
                  className={`${settings === 'Logout' ? 'chosen': ''}`}>פרופיל</li>

                  {/* <li onClick={() => logOut()}
                  // className={`${settings === 'Logout' ? 'chosen': ''}`}
                  >יציאה</li> */}
              </ul>
              <div className='v_line'/>
          </div>

          {settings === 'details' && <LoggedProfileDetalis />}
          {settings === 'else' && <LoggedProfileElse/>}
          {settings === 'luz' && <TrainerCalendar/>}
          {settings === 'luzIshi' && <TrainerCalendarUpdates/>}
          {settings === 'Delete' && <LoggedProfileDelete/>}
      </div>
    )
}