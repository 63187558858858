import { useEffect, useState, useContext} from "react";
import { AuthContext } from '../Constext/AuthContext'

import { db } from '../config/Firebase';
import { getDocs, collection, query } from 'firebase/firestore';

import { AdminMenu } from '../Componenets/Admin_Menu'
import { Overview } from './AdminOverview'
import { Users } from './AdminUsers'

import '../css/Admin.css'

export const Admin = ({toggleNav, setIsPersonal}) => {

  const[ofri, setOfri] = useState(false)

  const [selectedMenuItem, setSelectedMenuItem] = useState('general');

  const trainersCollectionRef = collection(db, 'Trainers');
  const traineesCollectionRef = collection(db, 'Trainees');
  const DeletedTrainersCollectionRef = collection(db, 'DeletedTrainers');
  const [trainers, setTrainers] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [deletedTrainers, setDeletedTrainers] = useState([]);

  const { currentUser } = useContext(AuthContext)

  // check if its me
  useEffect(() => {
    setOfri(currentUser && currentUser.email === "ofri.yehuda27@gmail.com");
  }, [currentUser]);

    useEffect(() => {
        toggleNav(false); // Set shouldRenderNav to false
        return () => {
          toggleNav(true); // Reset shouldRenderNav to true when component unmounts
        };
      }, []);
    
      useEffect(() => {
        setIsPersonal(true);
        getTrainers();
        getTrainees();
        getDeletedTrainers();
      }, []);
      
      const getTrainers = async () => {
        try {
          const trainersQuery = query(trainersCollectionRef);
          const querySnapshot = await getDocs(trainersQuery);
          const trainersData = querySnapshot.docs.map((doc) => doc.data());
      
          setTrainers(trainersData); // Update the trainers state with the fetched data
      
          return trainersData;
        } catch (error) {
          console.error(error);
          throw new Error('Failed to fetch trainers');
        }
      };  
      
      const getTrainees = async () => {
        try {
          const traineesQuery = query(traineesCollectionRef);
          const querySnapshot = await getDocs(traineesQuery);
          const traineesData = querySnapshot.docs.map((doc) => doc.data());
      
          setTrainees(traineesData); // Update the trainers state with the fetched data
      
          return traineesData;
        } catch (error) {
          console.error(error);
          throw new Error('Failed to fetch trainees');
        }
      };  

      const getDeletedTrainers = async () => {
        try{
          const deletedQuery = query(DeletedTrainersCollectionRef);
          const querySnapshot = await getDocs(deletedQuery);
          const deletedData = querySnapshot.docs.map((doc) => doc.data());
      
          setDeletedTrainers(deletedData); // Update the trainers state with the fetched data
      
          return deletedData;
        } catch (error) {
          console.error(error);
          throw new Error('Failed to fetch deleted');
        }
      }


      const handleMenuClick = (item) => {
        setSelectedMenuItem(item);
      };
    
      const renderSelectedComponent = () => {
        switch (selectedMenuItem) {
          case 'general':
            return <Overview trainers={trainers} trainees={trainees} deletedTrainers={deletedTrainers} />;
          case 'users':
            return <Users trainers={trainers}/>;
          // Add cases for other menu items if needed
          default:
            return null;
        }
      };
    
      return (
        <div className="admin_panel" lang="he">
          <AdminMenu handleMenuClick={handleMenuClick} />
    
          <div className="admin_main_view">
            {renderSelectedComponent()}
          </div>
        </div>
      );
    };
