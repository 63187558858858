import React from "react";
import {Search} from './Search'
import "../css/Home.css";
import '../css/Search.css';


export function Home(){

return(
  
    <div className="main" lang="he">
            <Search/>
    </div>
    )
}