import { useEffect, useState } from "react";

import trainer from '../imgs/adminTrainer.png'
import trainee from '../imgs/adminTrainee.png'
import deleted from '../imgs/adminDeleted.png'
import total from '../imgs/users.png'

export const Overview = ({ trainers, trainees, deletedTrainers }) => {
  const [numTrainers, setNumTrainer] = useState(0);
  const [numTrainees, setNumTrainees] = useState(0);
  const [numDeletedTrainers, setNumDeletedTrainers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("שנה");

  const getUsersCreatedAt = () => {
    const currentDate = new Date();
    const lastYearDate = new Date();
    lastYearDate.setFullYear(currentDate.getFullYear() - 1); // Subtract 1 year

    let startDate;
    switch (selectedTimeFrame) {
      case "שבוע":
        startDate = new Date();
        startDate.setDate(currentDate.getDate() - 7); // Subtract 7 days
        break;
      case "חודש":
        startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 month
        break;
      case "שנה":
      default:
        startDate = lastYearDate;
        break;
    }

    const trainersCreatedLastYear = trainers.filter((trainer) => {
      const createdAtTimestamp = trainer.createdAt;

      // Check if createdAtTimestamp exists and is a valid Timestamp object
      if (createdAtTimestamp && createdAtTimestamp.toDate) {
        const createdAtDate = createdAtTimestamp.toDate(); // Convert Firestore timestamp to Date

        return createdAtDate >= startDate && createdAtDate <= currentDate;
      }

      return false; // Skip trainers without a valid createdAtTimestamp
    });

    const traineesCreatedLastYear = trainees.filter((trainee) => {
      const createdAtTimestamp = trainee.createdAt;

      // Check if createdAtTimestamp exists and is a valid Timestamp object
      if (createdAtTimestamp && createdAtTimestamp.toDate) {
        const createdAtDate = createdAtTimestamp.toDate(); // Convert Firestore timestamp to Date

        return createdAtDate >= startDate && createdAtDate <= currentDate;
      }

      return false; // Skip trainees without a valid createdAtTimestamp
    });

    const deletedTrainersCreatedLastYear = deletedTrainers.filter((deletedTrainer) => {
      const createdAtTimestamp = deletedTrainer.createdAt;

      // Check if createdAtTimestamp exists and is a valid Timestamp object
      if (createdAtTimestamp && createdAtTimestamp.toDate) {
        const createdAtDate = createdAtTimestamp.toDate(); // Convert Firestore timestamp to Date

        return createdAtDate >= startDate && createdAtDate <= currentDate;
      }

      return false; // Skip deleted trainers without a valid createdAtTimestamp
    });

    setNumTrainer(trainersCreatedLastYear.length);
    setNumTrainees(traineesCreatedLastYear.length);
    setNumDeletedTrainers(deletedTrainersCreatedLastYear.length);
  };

  const handleTimeFrameChange = (timeFrame) => {
    setSelectedTimeFrame(timeFrame);
  };

  useEffect(() => {
    getUsersCreatedAt();
  }, [trainers, trainees, deletedTrainers, selectedTimeFrame]);

  useEffect(() => {
    setTotalUsers(numTrainees + numTrainers + numDeletedTrainers);
  }, [numTrainers, numTrainees, numDeletedTrainers]);

  return (
    <div className="view">
      <div className="times">
        <button
          className={`time_btn${selectedTimeFrame === "שבוע" ? " time_class" : ""}`}
          onClick={() => handleTimeFrameChange("שבוע")}
        >
          שבוע
        </button>
        <button
          className={`time_btn${selectedTimeFrame === "חודש" ? " time_class" : ""}`}
          onClick={() => handleTimeFrameChange("חודש")}
        >
          חודש
        </button>
        <button
          className={`time_btn${selectedTimeFrame === "שנה" ? " time_class" : ""}`}
          onClick={() => handleTimeFrameChange("שנה")}
        >
          שנה
        </button>
      </div>

      <div className="first_row">
        <div className="data">
          <img src={deleted}/>
          <h3 style={{color:'red'}}>{numDeletedTrainers}</h3>
          <span>מאמנים שנמחקו</span>  
        </div>


        <div className="data">
          <img src={trainee}/>
          <h3>{numTrainees}</h3>
          <span>מתאמנים</span>
         
        </div>

        <div className="data">
          <img src={trainer}/>
          <h3>{numTrainers}</h3>
          <span>מאמנים</span>
          
        </div>

        <div className="data">
          <img src={total}/>
          <h3>{totalUsers} </h3>
          <span>משתמשים חדשים</span>
          
        </div>
      </div>
    </div>
  );
};
