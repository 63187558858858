import './css/Footer.css'

export function Footer (){
    const currentYear = new Date().getFullYear();

    return (
        <div className='foot'>
            <p lang='none'>FITNESS DONE, YOUR WAY</p>
                <ul>
                    <li> <a href='https://www.linkedin.com/in/ofri-yehuda/' target='blank'>
                        &copy; Ofri Yehuda {currentYear} </a></li>
                    <li>Israel</li>
                    <li>Legal</li>
                    <li>Privacy Policy</li>
                    <li> <a href='mailto:fitit.app.mail@gmail.com' target='blank'>
                        Contact</a></li>
                </ul>
        </div>
    )
}