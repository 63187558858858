import { initializeApp } from "firebase/app";
import { getAnalytics} from "firebase/analytics";

import {getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "fitit-v5.firebaseapp.com",
  projectId: "fitit-v5",
  storageBucket: "fitit-v5.appspot.com",
  messagingSenderId: "655092220888",
  appId: "1:655092220888:web:927562abf793d02cd3a8ad",
  measurementId: "G-BXNRNQS5NG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider;
export const faceProvider = new FacebookAuthProvider;

export const db = getFirestore(app);
export const storage = getStorage(app);