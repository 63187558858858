import { useEffect, useState } from 'react';
import { db, auth, storage } from '../config/Firebase';
import { getDocs, collection, addDoc,query, where, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { Loading } from './Loading';

//file size
import Resizer from "react-image-file-resizer";

//img
import d_profile from '../imgs/Fitit_profile.png'
//css
import '../css/Wizard.css'

 export const TraineeFirstSignIn = () => {

    //steps
    const [currentStep, setCurrentStep] = useState(1);
    //trainee data
    const [newTraineesfName, setNewTraineesfName] = useState('');
    const [newTraineeslName, setNewTraineeslName] = useState('');
    const [newTraineesPhone, setNewTraineesPhone] = useState('');
    //img
    const [traineeImgUrl, setTraineeImgUrl] = useState(null)
    const [fileUpload, setFileUpload] = useState(null);
    //error
    const [error, setError] = useState(false);
    //Navigate
    const Navigate = useNavigate();
    //trainee db
    const traineesCollectionRef = collection(db, 'Trainees');

    const [fetchingAuth, setFetchingAuth] = useState(true);

    useEffect(() => {
        const checkTraineeExistence = async () => {
          if (!auth.currentUser || !auth.currentUser.email) {
            setFetchingAuth(false);
            return;
          }
          const traineesQuery = query(
            traineesCollectionRef,
            where('userMail', '==', auth.currentUser.email)
          );
          const traineesSnapshot = await getDocs(traineesQuery);
          if (!traineesSnapshot.empty) {
            Navigate('/profile');
          }
          setFetchingAuth(false);
        };
      
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            checkTraineeExistence();
          } else {
            setFetchingAuth(false);
          }
        });
      
        return () => {
          // Unsubscribe the listener when the component unmounts
          unsubscribe();
        };
      }, [traineesCollectionRef, auth, Navigate]);
      
      if (fetchingAuth) {
        // Render a loading indicator or skeleton screen while fetchingAuth is true
        return <Loading/>;
      }

const WizardButton = ({ text, className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );
};

  // Constant for button text and class names
const BUTTON_TEXT = {
  BACK: 'חזור',
  NEXT: 'הבא',
  SUBMIT:' בנו לי פרופיל 💪'
};

  // Class names for buttons
  const BUTTON_CLASSES = {
    BACK: 'btn_back',
    NEXT: 'btn_next',
    SUBMIT: 'submit_button'
  };
  
  const handleNext = () => {
    if (
        newTraineesfName !== '' &&
        newTraineeslName !== '' &&
        newTraineesPhone !== ''
    ) {
      setCurrentStep(currentStep + 1);
      setError(false);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const onSubmitTrainee  = async (e) => {
    e.preventDefault();

      // Validation check
  if (
    newTraineesfName === '' ||
    newTraineeslName === '' ||
    newTraineesPhone === ''
  ) {
    setError(true);
    return; // Exit the function if any required fields are empty
  }

    try {
      await uploadFile(); // Upload the file first
        // Create a new document with the provided information
        const traineeData = {
            fName: newTraineesfName,
            lName: newTraineeslName,
            Phone: newTraineesPhone,
            userId: auth?.currentUser?.uid,
            userMail: auth?.currentUser?.email,
            createdAt: Timestamp.now(),
            role:'Trainee',
        };

        await addDoc(traineesCollectionRef, traineeData);

      Navigate('/TraineeProfile',
    //    { state: { displayComponent: 'else' } }
       ); // Navigate to the profile page after successful submission
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };
  
     //upload fiels
     const uploadFile = async () => {
      if (!fileUpload) return;
      try {
        // Resize the image before uploading
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            fileUpload,
            800, // Specify the desired width
            600, // Specify the desired height
            'JPEG', // Output format (e.g., JPEG, PNG)
            100, // Quality (0 to 100)
            0, // Rotation (0, 90, 180, or 270)
            (resizedFile) => {
              resolve(resizedFile);
            },
            'blob' // Output type ('blob' or 'base64')
          );
        });
    
        // Upload the resized file to Firebase Storage
        const fileFolderRef = ref(storage, `TraineeFiles/${auth.currentUser.uid}`);
        await uploadBytes(fileFolderRef, resizedImage);
      } catch (err) {
        console.error(err);
      }
    };

  const updateImg = (pImg) =>{
    if (!pImg) return;
    try {
    const file = pImg
    const imageUrl = URL.createObjectURL(file);
    setTraineeImgUrl(imageUrl);
    }catch(err){
    console.error(err);
    }
  }


  return (
  <div className='mid'>
    <div className='SignUp_wizard' dir='rtl' lang='he'>
      {currentStep === 1 &&<h1 dir='rtl' lang='he'>ברוכים הבאים ל-Fitit</h1>}

<div className='steps'>

<div className='steps_div'>
      {currentStep === 1 &&
      <span>חימום קל</span>}
    <div className={`sign_step ${currentStep === 1 ? 'current_step' : ''}`}>
      <span>1</span>
    </div>
</div>

<div className='step_line'/>

<div className='steps_div'>
    {currentStep === 2 &&
    <span>עכשיו מזיעים</span>}
    <div className={`sign_step ${currentStep === 2 ? 'current_step' : ''}`}>
      <span>2</span>
    </div>
</div>

</div>


<form onSubmit={onSubmitTrainee}>
          {currentStep === 1 && (
            <div>

              <h2> כמה פרטים בסיסיים </h2>

              {error &&<span className='required'> אבל איך נקרא לך...</span>}

              <input
                type='text'
                placeholder='שם פרטי'
                value={newTraineesfName}
                onChange={(e) => setNewTraineesfName(e.target.value)}
              />

                {error &&<span className='required'> זה שבחרו בשבילך</span>}     
              <input
                type='text'
                placeholder='שם משפחה'
                value={newTraineeslName}
                onChange={(e) => setNewTraineeslName(e.target.value)}
              />

            {error &&<span className='required'> שמי שצריך יוכל להשיג אותך</span>}
              <input
                type='tel'
                placeholder='מספר טלפון'
                value={newTraineesPhone}
                onChange={(e) => setNewTraineesPhone(e.target.value)}
                maxLength='10' 
              />

              <WizardButton
                text={BUTTON_TEXT.NEXT}
                className={BUTTON_CLASSES.NEXT}
                onClick={handleNext}
              />
            </div>
          )}
  
  
        {currentStep === 2 && (
          <div>
            <h2>רק לעלות תמונה!</h2>

            <div className='upload_file'>
              <label htmlFor='fileupload'>לחץ כאן כדי לעלות תמונה</label>
              <input type='file' id='fileUpload' onChange={(e) => { setFileUpload(e.target.files[0]); updateImg(e.target.files[0]);}} className='hiddenbtn'/>
            </div>

            <div className="circle">
                {traineeImgUrl == null && <img
                    src={d_profile}
                    alt='תמונת פרופיל'
                  />}
                  {traineeImgUrl != null && (
                  <img
                    src={traineeImgUrl}
                    alt={'תמונת פרופיל'}
                  />
                )}
              </div>

<div className='btns'>

          <WizardButton
          text={BUTTON_TEXT.BACK}
          className={BUTTON_CLASSES.BACK}
          onClick={handleBack}
        />


         <WizardButton
                  type='submit'
                  text={BUTTON_TEXT.SUBMIT}
                  className={BUTTON_CLASSES.SUBMIT}
                  onClick={onSubmitTrainee}
                />   
</div>
        </div>
      )}
      </form>
      {error && <span className='required' lang='he'>אופס, יש תקלה 😕</span>}
    </div>
  </div>
  );
};