import { useEffect, useState } from 'react';
import { db, auth, storage } from '../config/Firebase';
import { getDocs, collection, addDoc,query, where, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { fitnessTypeOptions } from '../Constext/filters';
import { Loading } from './Loading';

//file size
import Resizer from "react-image-file-resizer";

//google maps and geolocation
import { libraries } from '../config/GoogleMaps'
import { geohashForLocation } from 'geofire-common';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

//img
import d_profile from '../imgs/Fitit_profile.png'
//css
import '../css/Wizard.css'

 export const TrainerFirstSignIn = () => {

  //google maps script
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
    language: 'iw', // Specify the desired language code
    region: 'IL', // Specify the desired region code
    }); 
    //steps
    const [currentStep, setCurrentStep] = useState(1);
    const [includeExtraStep, setIncludeExtraStep] = useState(false);
    //trainer data
    const [newTrainersfName, setNewTrainersfName] = useState('');
    const [newTrainerslName, setNewTrainerslName] = useState('');
    const [newTrainersType, setNewTrainersType] = useState([]);
    const [newTrainersPhone, setNewTrainersPhone] = useState('');
    const [newTrainersInstagram, setNewTrainersInstagram] = useState('Instagram.com/');
    const [newTrainersDescription, setNewTrainersDescription] = useState('');
    const [newTrainersAddress, setNewTrainersAddress] = useState('')
    const [newTrainersPersonal, setNewTrainersPersonal] = useState('')
    const [newTrainersGroup, setNewTrainersGroup] = useState('')
    const [newTrainersGeo, setNewTrainersGeo] = useState([{ title: 'Lat', value: null }, { title: 'Lng', value: null }]);
    const [newTrainersGeoHash, setNewTrainersGeoHash ] = useState('')
    const[present, setPresent] =useState(true)
    //img
    const [trainerImgUrl, setTrainerImgUrl] = useState(null)
    const [fileUpload, setFileUpload] = useState(null);
    //error
    const [error, setError] = useState(false);
    //Navigate
    const Navigate = useNavigate();
    //trainer db
    const trainersCollectionRef = collection(db, 'Trainers');

    const [fetchingAuth, setFetchingAuth] = useState(true);

    useEffect(() => {
      const checkTrainerExistence = async () => {
        if (!auth.currentUser || !auth.currentUser.email) {
          setFetchingAuth(false);
          return;
        }
        const trainersQuery = query(
          trainersCollectionRef,
          where('userMail', '==', auth.currentUser.email)
        );
        const trainersSnapshot = await getDocs(trainersQuery);
        if (!trainersSnapshot.empty) {
          Navigate('/profile');
        }
        setFetchingAuth(false);
      };
    
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          checkTrainerExistence();
        } else {
          setFetchingAuth(false);
        }
      });
    
      return () => {
        // Unsubscribe the listener when the component unmounts
        unsubscribe();
      };
    }, [trainersCollectionRef, auth, Navigate]);
    
    if (fetchingAuth) {
      // Render a loading indicator or skeleton screen while fetchingAuth is true
      return <Loading/>;
    }


const WizardButton = ({ text, className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );
};

  // Constant for button text and class names
const BUTTON_TEXT = {
  BACK: 'חזור',
  NEXT: 'הבא',
  SUBMIT:' בנו לי פרופיל 💪'
};

  // Class names for buttons
  const BUTTON_CLASSES = {
    BACK: 'btn_back',
    NEXT: 'btn_next',
    SUBMIT: 'submit_button'
  };

  const handleExtraStep = () => {
    setIncludeExtraStep(!includeExtraStep);
  };
  
  const handleNext = () => {
    if (
      newTrainersfName !== '' &&
      newTrainerslName !== '' &&
      newTrainersPhone !== ''
    ) {
      setCurrentStep(currentStep + 1);
      setError(false);
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const onSubmitTrainer = async (e) => {
    e.preventDefault();

      // Validation check
  if (
    newTrainersfName === '' ||
    newTrainerslName === '' ||
    newTrainersPhone === ''
  ) {
    setError(true);
    return; // Exit the function if any required fields are empty
  }

    try {
      await uploadFile(); // Upload the file first
        // Create a new document with the provided information
        const trainerData = {
          fName: newTrainersfName,
          lName: newTrainerslName,
          Type: newTrainersType.map((type) => type.value),
          Phone: newTrainersPhone,
          Address: newTrainersAddress,
          Description: newTrainersDescription,
          Personal: newTrainersPersonal,
          Group: newTrainersGroup,
          Geo: {
            Lat: newTrainersGeo.find((geo) => geo.title === 'Lat')?.value,
            Lng: newTrainersGeo.find((geo) => geo.title === 'Lng')?.value,
          },
          GeoH: newTrainersGeoHash,
          userId: auth?.currentUser?.uid,
          userMail: auth?.currentUser?.email,
          ImageUrl: fileUpload ? `TrainerFiles/${auth.currentUser.uid}` : null, // Add the file URL to the database only if it's a new file
          Present: present,
          createdAt: Timestamp.now(),
          role:'Trainer',
        };
        if (newTrainersInstagram !== 'Instagram.com/') {
          trainerData.Instagram = newTrainersInstagram;
        }

        await addDoc(trainersCollectionRef, trainerData);

      Navigate('/profile', { state: { displayComponent: 'else' } }); // Navigate to the profile page after successful submission
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };
  
     //upload fiels
     const uploadFile = async () => {
      if (!fileUpload) return;
      try {
        // Resize the image before uploading
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            fileUpload,
            800, // Specify the desired width
            600, // Specify the desired height
            'JPEG', // Output format (e.g., JPEG, PNG)
            100, // Quality (0 to 100)
            0, // Rotation (0, 90, 180, or 270)
            (resizedFile) => {
              resolve(resizedFile);
            },
            'blob' // Output type ('blob' or 'base64')
          );
        });
    
        // Upload the resized file to Firebase Storage
        const fileFolderRef = ref(storage, `TrainerFiles/${auth.currentUser.uid}`);
        await uploadBytes(fileFolderRef, resizedImage);
      } catch (err) {
        console.error(err);
      }
    };

  const updateImg = (pImg) =>{
    if (!pImg) return;
    try {
    const file = pImg
    const imageUrl = URL.createObjectURL(file);
    setTrainerImgUrl(imageUrl);
    }catch(err){
    console.error(err);
    }
  }

  const handlePlaceChanged = (place) => {
    if (place && place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      const hash = geohashForLocation([lat(), lng()]);
      console.log('Address:', place.formatted_address);
      console.log('Latitude:', lat());
      console.log('Longitude:', lng());
      setNewTrainersGeo([
        {title: 'Lat', value:lat()},
        {title: 'Lng', value:lng()}
       ]); // Pass an array with lat and lng values
       setNewTrainersAddress(place.formatted_address)
       console.log(hash)
       setNewTrainersGeoHash(hash)
    }
  };

  return (
  <div className='mid'>
    <div className='SignUp_wizard' dir='rtl' lang='he'>
      {/* <h2 dir='rtl' lang='he'>גג
        כניסת מאמנים ל-Fitit
      </h2> */}
      {currentStep === 1 &&<h1 dir='rtl' lang='he'>ברוכים הבאים ל-Fitit</h1>}

<div className='steps'>

<div className='steps_div'>
      {currentStep === 1 &&
      <span>חימום קל</span>}
    <div className={`sign_step ${currentStep === 1 ? 'current_step' : ''}`}>
      <span>1</span>
    </div>
</div>

<div className='step_line'/>

<div className='steps_div'>
    {currentStep === 2 &&
    <span>עכשיו מזיעים</span>}
    <div className={`sign_step ${currentStep === 2 ? 'current_step' : ''}`}>
      <span>2</span>
    </div>
</div>

<div className='step_line'/>

<div className='steps_div'>
      {currentStep === 3 &&
      <span>עוד קצת</span>}
      <div className={`sign_step ${currentStep === 3 ? 'current_step' : ''}`}>
        <span>3</span>
      </div>
</div>

<div className='step_line'/>

<div className='steps_div'>
    {currentStep === 4 &&
    <span>כמעט סיימנו</span>}
    <div className={`sign_step ${currentStep === 4 ? 'current_step' : ''}`}>
      <span>4</span>
    </div>
</div>

</div>


      <form onSubmit={onSubmitTrainer}>
        {currentStep === 1 && (
          <div>

            <h2> כמה פרטים בסיסיים</h2>

            {error &&<span className='required'> אבל איך נקרא לך...</span>}

            <input
              type='text'
              placeholder='שם פרטי'
              value={newTrainersfName}
              onChange={(e) => setNewTrainersfName(e.target.value)}
              lang='he'
              className='required'
            />

            {error &&<span className='required'> זה שבחרו בשבילך</span>}   
            <input
              type='text'
              placeholder='שם משפחה'
              value={newTrainerslName}
              onChange={(e) => setNewTrainerslName(e.target.value)}
              lang='he'
            />

            {error &&<span className='required'> שמי שצריך יוכל להשיג אותך</span>}
            <input
              type='tel'
              placeholder='מספר טלפון'
              value={newTrainersPhone}
              onChange={(e) => setNewTrainersPhone(e.target.value)}
              lang='he'
              maxLength='10' 
            />

                <WizardButton
                text={BUTTON_TEXT.NEXT}
                className={BUTTON_CLASSES.NEXT}
                onClick={handleNext}
              />
          </div>
        )}
  
        {currentStep === 2 && (
          <div>
            <h2>כתובת</h2>
            
           <Autocomplete
                      className='AUTOCOMPLETE'
                        options={{
                          types: ['address'],
                          componentRestrictions: { country: 'IL' },
                          language: 'iw' // Set the language to Hebrew
                        }}
                        onLoad={(autocomplete) => {
                          autocomplete.addListener('place_changed', () =>
                            handlePlaceChanged(autocomplete.getPlace())
                          );}}
                      >
                        <input 
                        placeholder={'תתחילו לרשום וגוגל ימשיך'}
                        type="text" 
                      />
            </Autocomplete>

            <h2>סוגי אימון</h2>

            <Select
              options={fitnessTypeOptions}
              isMulti
              placeholder='רק לבחור מהרשימה'
              value={newTrainersType}
              onChange={(selected) => setNewTrainersType(selected)}
              required
              lang='he'
              isSearchable={ false }
            />
            
<h2>אל תשכחו לספר לנו אם אתם מאמנים אימונים אישיים או קבוצתיים, או את שניהם</h2>
<div className='train_kind'>

            <div className="checkbox_div">
                        <button
                        lang='none'
                        onClick={(e) =>{
                        e.preventDefault();
                        setNewTrainersPersonal(!newTrainersPersonal)
                        }}
                        className={`filt_btn ${newTrainersPersonal ? 'checked': 'not_checked'}`}
                        >אימון אישי</button>

                  </div>

                  <div className="checkbox_div">
                    <button
                    lang='none'
                    onClick={(e) =>{
                    e.preventDefault();
                    setNewTrainersGroup(!newTrainersGroup)
                    }}
                    className={`filt_btn ${newTrainersGroup ? 'checked': 'not_checked'}`}
                    >אימון קבוצתי</button>
</div>
                  </div>
<div className='btns'>
                  <WizardButton
                text={BUTTON_TEXT.BACK}
                className={BUTTON_CLASSES.BACK}
                onClick={handleBack}
              />
              <WizardButton
                text={BUTTON_TEXT.NEXT}
                className={BUTTON_CLASSES.NEXT}
                onClick={handleNext}
              />
          </div>
</div>
        )}
  
        {currentStep === 3 && (
          <div>
           
           <span>כתבו את הכתובת של פרופיל האינסטגרם שלכם בפורמט הבא Instagram.com/profileName</span>

            <input
            dir='ltr'
              type='text'
              placeholder='Instagram.com/profile'
              value={newTrainersInstagram}
              onChange={(e) => setNewTrainersInstagram(e.target.value)}
              lang='he'
              className='insta'
            />

            <h2>קישור לאינסטגרם יופיע בעמוד שלכם וכן התיאור שתוסיפו פה, כדאי לחשוב טוב מה לכתוב</h2>

            <textarea placeholder={ 'ספר על עצמך ועל הניסיון שלך למתאמנים שיצפו בפרופיל שלך'}
                  value={newTrainersDescription}
                  onChange={(e) => setNewTrainersDescription(e.target.value)}
                  />
<div className='btns'>
                          <WizardButton
                text={BUTTON_TEXT.BACK}
                className={BUTTON_CLASSES.BACK}
                onClick={handleBack}
              />
              <WizardButton
                text={BUTTON_TEXT.NEXT}
                className={BUTTON_CLASSES.NEXT}
                onClick={handleNext}
              />
</div>
          </div>
        )}
  
        {currentStep === 4 && (
          <div>
            <h2>רק לעלות תמונה!</h2>

            <div className='upload_file'>
              <label htmlFor='fileupload'>לחץ כאן כדי לעלות תמונה</label>
              <input type='file' id='fileUpload' onChange={(e) => { setFileUpload(e.target.files[0]); updateImg(e.target.files[0]);}} className='hiddenbtn'/>
            </div>

            <div className="circle">
                {trainerImgUrl == null && <img
                    src={d_profile}
                    alt='תמונת פרופיל'
                  />}
                  {trainerImgUrl != null && (
                  <img
                    src={trainerImgUrl}
                    alt={'תמונת פרופיל'}
                  />
                )}
              </div>

        {/* <button type='submit' lang='he'>סיום</button> */}

        {/* <WizardButton 
        text={' אני רוצה אתר אישי'}
        onClick={handleExtraStep}/> */}
                

{includeExtraStep &&
 <div>
  132
  </div>}

<div className='btns'>

          <WizardButton
          text={BUTTON_TEXT.BACK}
          className={BUTTON_CLASSES.BACK}
          onClick={handleBack}
        />


         <WizardButton
                  type='submit'
                  text={BUTTON_TEXT.SUBMIT}
                  className={BUTTON_CLASSES.SUBMIT}
                  onClick={onSubmitTrainer}
                />   
</div>
        </div>
      )}
      </form>
      {error && <span className='required' lang='he'>אופס, יש תקלה 😕</span>}
    </div>
  </div>
  );
};