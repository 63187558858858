import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { db } from '../config/Firebase'; // I assume you're using Firebase based on your other components
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';

import { Calendar } from './Calendar'

export const Schedule = ({toggleNav, setIsPersonal}) =>{

const { url } = useParams();
const [scheduleData, setScheduleData] = useState(null);
const [trainerData, setTrainerData] = useState(null);

// useEffect(() => {
//   toggleNav(false); // Set shouldRenderNav to false
//   return () => {
//     toggleNav(true); // Reset shouldRenderNav to true when component unmounts
//   };
// }, []);

useEffect(() => {
  setIsPersonal(true);
}, []);

useEffect(() => {
    const fetchScheduleData = async () => {
        const q = query(collection(db, 'PersonalWebsites'), where('lowerUrl', '==', url.toLowerCase()));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const trainer = querySnapshot.docs[0].data();
          setTrainerData(trainer)
          // Now we find the document in the 'Trainers' collection with a matching 'userId'
          const trainersQuery = query(collection(db, 'Trainers'), where('userId', '==', trainer.userId));
          const trainersQuerySnapshot = await getDocs(trainersQuery);
  
          if (!trainersQuerySnapshot.empty) {
            const trainerDoc = trainersQuerySnapshot.docs[0];
            // Now we access the 'settings' sub-collection and the 'trainerSettings' document
            const trainerSettingsDocRef = doc(trainerDoc.ref, 'settings', 'trainerSettings');
            const trainerSettingsSnapshot = await getDoc(trainerSettingsDocRef);
  
            if (trainerSettingsSnapshot.exists()) {
              setScheduleData(trainerSettingsSnapshot.data());
            }
          }
        }
      };
  
      fetchScheduleData().catch((error) => {
        console.error('Error fetching schedule:', error);
      });
    }, [url]);
    
return (

    <div className='mid'>
        <Calendar scheduleData={scheduleData} trainerData={trainerData}/>
    </div>

    )
}