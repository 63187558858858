import { useContext, useState } from 'react';

//comps
//admin
import { Admin } from './Componenets/Admin'
//main
import { Nav } from './Componenets/Nav';
import { Trainers } from './Componenets/Trainers';
import { NotFound } from "./Componenets/NotFound";
import { Big } from './big';
import { About } from './Componenets/About'
import { Blog } from './Componenets/Blog'
import { Footer } from './Footer';
//trainers
import { SignIn } from './Componenets/SignIn';
import { TrainerFirstSignIn } from './Componenets/TrainerFirstSignIn';
import { LoggedProfile } from './Componenets/LoggedProfile';
import { PersonalWebsite } from './Componenets/PersonalWebsite';

import { Schedule } from './Componenets/Schedule'
//trainees
import { SignInTrainee } from './Componenets/SignInTrainee';
import { TraineeLoggedProfile } from './Componenets/TraineeLoggedProfile'
import { SessionConfirmation } from './Componenets/SessionConfirmation';
import { TraineeFirstSignIn } from './Componenets/TraineeFirstSignIn';

//auth user
import { AuthContext } from './Constext/AuthContext';
//router
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
//css
import './App.css';
import './css/Mobile.css';
import { Helmet } from 'react-helmet';
//hotjar
import Hotjar from '@hotjar/browser';

const siteId = 3546473;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);


function App(props) {
  const { currentUser, userRole } = useContext(AuthContext);
  const [shouldRenderNav, setShouldRenderNav] = useState(true);
  const [isPersonal, setIsPersonal] = useState(false);

  const toggleNav = (value) => {
    setShouldRenderNav(value);
  };

  const RequireAuthTrainer = ({ children }) => {
    return currentUser && userRole === 'Trainer' ? children : <Navigate to="/" />;
  };

  const RequireAuthTrainee = ({ children }) => {
    return currentUser && userRole === 'Trainee' ? children : <Navigate to="/" />;
  };

  const RequireOfri = ({ children }) => {
    return currentUser && currentUser.email === 'ofri.yehuda27@gmail.com' ? children : <Navigate to="/" />;
  };

  return (

    <BrowserRouter>
    
    <Helmet>
        <title>Fitit - Fitness Done, Your Way</title>
        <meta
        name="description"
        content="Fitit
         מציעה פלטפורמה לחיבור בין מאמנים למתאמנים בהתאמה אישית וסינון ייחודי. מצאו את המאמנת,
         המאמן או הסטודיו הבא שלכם והשיגו את מטרות הכושר שלכם בקלות.
         מאמנים, מאמנות ובעלי סטודיו ? הצטרפו אלינו וצרו פרופיל עוד היום!"
      />
       <meta
        property="og:title"
        content="Fitit - מצא את המאמן המתאים לך והשג את מטרות הכושר שלך"
      />
       <meta name='keywords'
        content='חיפוש מאמני כושר, למצוא מאמן כושר, מאמני כושר, אימון כושר אישי,
         אימון כושר קבוצתי, מאמן כושר קרוב, מפת מאמני כושר, כרטיס ביקור למאמני כושר' />
      </Helmet>


      {shouldRenderNav && <Nav />}
      {!isPersonal && <div className="background" />}
      <Routes>
        <Route path="/" element={<Big />} />
        <Route path="/trainers" element={<Trainers />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signinTrainee" element={<SignInTrainee />} />
        <Route path="/About" element={<About />} />
        <Route path="/Blog/*" element={<Blog />} />

        {/* <Route path="/Schedule" element={<Schedule />} /> */}

        <Route
          path="/profile"
          element={
           <RequireAuthTrainer>
            <LoggedProfile />
             </RequireAuthTrainer>} 
          />
        
        <Route
          path="/profileWizard"
          element={
          <RequireAuthTrainer>
            <TrainerFirstSignIn/>
          </RequireAuthTrainer>} 
          />

        <Route
          path="/profileWizardTrainee"
          element={
          <RequireAuthTrainee>
            <TraineeFirstSignIn/>
          </RequireAuthTrainee>} 
          />

        <Route
          path="/TraineeProfile"
          element={
           <RequireAuthTrainee>
            <TraineeLoggedProfile />
             </RequireAuthTrainee>} 
          />

        <Route
          path="/:url/Schedule"
          element={<Schedule toggleNav={toggleNav} setIsPersonal={setIsPersonal} />}
        />

        <Route
          path="/:url/Schedule/SessionConfirmation"
          element={<SessionConfirmation toggleNav={toggleNav} setIsPersonal={setIsPersonal} />}
        />

        <Route
          path="/:url"
          element={<PersonalWebsite toggleNav={toggleNav} setIsPersonal={setIsPersonal} />}
        />

        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFound />} />

        <Route
          path="/Admin"
          element={<RequireOfri><Admin toggleNav={toggleNav} setIsPersonal={setIsPersonal} /></RequireOfri>}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
